<div class="guest-detail-page width_100" fxLayoutGap="10px" fxLayout="column" fxLayoutAlign="start center">
  <div class="description" *ngIf="globalService.isMobile$.getValue()">
    <div>{{guestDetail?.lastName + ', ' + guestDetail?.firstName}}</div>
    <div>Booking ID: {{guestDetail?.bookingNumber}}</div>
  </div>
  <div class="width_100 position_relative" fxLayout="row" fxLayoutAlign="start center">
    <mat-tab-group [disableRipple]="true" fxFlex="100" (selectedIndexChange)="selectedTab = $event"
      [(selectedIndex)]=selectedIndex>
      <mat-tab label="Booking Details">
        <div *ngIf="guestDetail" class="booking_detail_container" fxLayoutGap="20px" fxLayout="column">
          <div fxFlex="100" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="start start">
            <div fxFlex="55" fxLayout="row" fxLayoutAlign="start center">
              <div class="container" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start center">
                <div class="container_title width_100 no_border extra_margin_top" fxLayout="row"
                  fxLayoutAlign="space-between center">
                  <div fxLayout="row">STATUS: &nbsp;<span class="highlight_blue">{{guestDetail.status}}</span></div>
                  <div fxLayout="row" fxLayoutGap="8px" class="cursor_pointer" fxLayoutAlign="start center"
                    (click)="editGuest()">
                    <mat-icon [svgIcon]="'edit-info'"></mat-icon>
                    <div class="highlight_blue">Edit info</div>
                  </div>
                </div>
                <div class="container_title width_100 extra_margin_top" fxLayout="row" fxLayoutAlign="start center">
                  PERSONAL INFO</div>
                <div class="container_section width_100" fxLayoutGap="20px" fxLayout="column"
                  fxLayoutAlign="start center">
                  <div class="container_body width_100" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Last Name</div>
                      <div class="guest_data">{{guestDetail.lastName}}</div>
                    </div>
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">First Name</div>
                      <div class="guest_data">{{guestDetail.firstName}}</div>
                    </div>
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Date of Birth</div>
                      <div class="guest_data">{{getFormattedDate(guestDetail.dob)}}</div>
                    </div>
                  </div>
                  <div class="container_body width_100" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Age</div>
                      <div class="guest_data">{{guestDetail.age ? guestDetail.age : 'NA' }}</div>
                    </div>
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Gender</div>
                      <div class="guest_data">{{guestDetail.gender}}</div>
                    </div>
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Nationality</div>
                      <div class="guest_data">{{guestDetail.nationality}}</div>
                    </div>
                  </div>
                </div>

                <div class="container_title width_100 extra_margin_top" fxLayout="row" fxLayoutAlign="start center">
                  ADDRESS</div>
                <div class="container_section width_100" fxLayoutGap="20px" fxLayout="column"
                  fxLayoutAlign="start center">
                  <div class="container_body width_100" fxLayout="row" fxLayoutAlign="start center">
                    <div fxLayoutGap="5px" class="width_100" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Company Name</div>
                      <div class="guest_data">{{guestDetail.companyName ? guestDetail.companyName : 'NA'}}</div>
                    </div>
                  </div>
                  <div class="container_body width_100" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Street Address</div>
                      <div class="guest_data">{{guestDetail.streetName}}</div>
                    </div>
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">City</div>
                      <div class="guest_data">{{guestDetail.town}}</div>
                    </div>
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">State/Province</div>
                      <div class="guest_data">{{guestDetail.state ? guestDetail.state : 'NA'}}</div>
                    </div>
             
                  </div>
                  <div class="container_body width_100" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Zip Code</div>
                      <div class="guest_data">{{guestDetail.zipCode}}</div>
                    </div>
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Country</div>
                      <div class="guest_data">{{guestDetail.country}}</div>
                    </div>
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start" style="opacity: 0;">
                      <div class="guest_label">State</div>
                      <div class="guest_data">{{guestDetail.state ? guestDetail.state : 'NA'}}</div>
                    </div>
                  </div>
                </div>

                <div class="container_title width_100 extra_margin_top" fxLayout="row" fxLayoutAlign="start center">
                  CONTACT</div>
                <div class="container_section width_100" fxLayoutGap="20px" fxLayout="column"
                  fxLayoutAlign="start center">
                  <div class="container_body width_100" fxLayout="row" fxLayoutAlign="start center">
                    <div fxLayoutGap="5px" class="width_30 margin_right_5" fxLayout="column"
                      fxLayoutAlign="start start">
                      <div class="guest_label">Contact No.</div>
                      <div class="guest_data">{{guestDetail.mobileNumber}}</div>
                    </div>
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Email</div>
                      <div class="guest_data">{{guestDetail.email ? guestDetail.email : 'NA'}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="45" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start center">
              <div class="container" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start center">
                <div class="container_title width_100 extra_margin_top" fxLayout="row" fxLayoutAlign="start center">
                  BOOKING INFO</div>
                <div class="container_section width_100" fxLayoutGap="20px" fxLayout="column"
                  fxLayoutAlign="start center">
                  <div class="container_body width_100" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Check-In Date</div>
                      <div class="guest_data">{{guestDetail?.checkInDate |
                        date: 'dd/MM/yyyy'}}</div>
                    </div>
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Check-Out Date</div>
                      <div class="guest_data">{{guestDetail?.checkOutDate | date: 'dd/MM/yyyy'}}</div>
                    </div>
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Nights</div>
                      <div class="guest_data">{{guestDetail.nights}}</div>
                    </div>
                  </div>
                </div>

                <div class="container_title width_100 extra_margin_top" fxLayout="row" fxLayoutAlign="start center">ROOM
                  INFO</div>
                <div class="container_section width_100" fxLayoutGap="20px" fxLayout="column"
                  fxLayoutAlign="start center">
                  <div class="container_body width_100" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">No. of Rooms</div>
                      <div class="guest_data">{{guestDetail.numberOfRooms}}</div>
                    </div>
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Room Type</div>
                      <div class="guest_data">{{getRoomType(guestDetail.roomId)}}</div>
                    </div>
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Room No.</div>
                      <div class="guest_data">{{guestDetail.roomNumber}}</div>
                    </div>
                  </div>
                  <div class="container_body width_100" fxLayout="row" fxLayoutAlign="start center">
                    <div fxLayoutGap="5px" class="width_30 margin_right_5" fxLayout="column"
                      fxLayoutAlign="start start">
                      <div class="guest_label">Board</div>
                      <div class="guest_data">{{guestDetail.board ? guestDetail.board : 'NA'}}</div>
                    </div>
                    <div fxLayoutGap="5px" class="width_60" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Cancellation Policy</div>
                      <div class="guest_data">{{guestDetail.cancellationPolicy ? guestDetail.cancellationPolicy : 'NA'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="container_title width_100 extra_margin_top" fxLayout="row" fxLayoutAlign="start center">
                  GUESTS</div>
                <div class="container_section width_100" fxLayoutGap="20px" fxLayout="column"
                  fxLayoutAlign="start center">
                  <div class="container_body width_100" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Adults</div>
                      <div class="guest_data">{{guestDetail.adults}}</div>
                    </div>
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Children (0 - 17)</div>
                      <div class="guest_data">{{guestDetail.children ? guestDetail.children : 'NA'}}</div>
                    </div>
                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                      <div class="guest_label">Extras</div>
                      <div class="guest_data">{{guestDetail.extras ? guestDetail.extras : 'NA'}}</div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="container" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
                <div class="container_sub_title_1 width_100" fxLayout="row" fxLayoutAlign="start center">
                  <span>Share Guest App’s link </span>
                  <mat-icon [svgIcon]="'mobile-phone'"></mat-icon>
                </div>
                <div class="container_sub_title_2 width_100" fxLayout="row" fxLayoutAlign="start center">The guest(s)
                  will receive an email with a link to the Guest App</div>
                <div class="width_100" fxLayout="column" fxLayoutAlign="center start">
                  <div class="container_button" fxLayout="row" fxLayoutAlign="start center"
                    *ngIf="guestDetail.appActivity === 'Send link'" (click)="openShareLink()">
                    Send link
                  </div>
                  <div class="container_button" [ngClass]="guestDetail.appActivity" fxLayout="row"
                    fxLayoutAlign="start center" *ngIf="guestDetail.appActivity !== 'Send link'">
                    {{guestDetail.appActivity}}
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center" (click)="openShareLink()">
                    Send again
                    <mat-icon [svgIcon]="'refresh'"></mat-icon>
                  </div>
                </div>
              </div> -->
              <div class="container" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
                <div class="container_sub_title_1 width_100" fxLayout="row" fxLayoutAlign="start center">
                  <span>Guest's Verification Documents</span>
                </div>
                <div class="container_sub_title_2 width_100" style="line-height: 14px;" fxLayout="row"
                  fxLayoutAlign="start center">The guest's identification details will be displayed when they are
                  available.</div>
                <div class="container_sub_title_2 width_100" style="line-height: 16px;" fxLayout="row"
                  fxLayoutAlign="start center">
                  <span>Documents:</span>
                </div>
                <div class="container_sub_title_2 width_100"
                  *ngIf="guestDetail && guestDetail?.isRegistrationFormFilled;else registration" fxLayout="row"
                  fxLayoutAlign="start center" fxLayoutGap="30px">
                  <div class="container_sub_title_2"
                    *ngIf="guestDetail && guestDetail?.isRegistrationFormFilled;else registration" fxLayout="row"
                    fxLayoutAlign="start center">
                    <div style="cursor: pointer" fxLayout="column" fxLayoutAlign="center start" #button
                      (click)="downloadPdf()">
                      <img style="cursor: pointer;" src="../assets/images/pdf.svg">
                    </div>
                    <div class="width_100" fxLayout="column" fxLayoutAlign="center start">
                      <div style="font-size: 10px;font-weight:600;color: #495057;">
                        {{guestDetail?.firstName+'_'+guestDetail?.lastName}}.pdf</div>
                      <div style="font-size: x-small;">{{pdfSize}}</div>
                    </div>
                  </div>
                  <div class="container_sub_title_2"
                    *ngIf="guestDetail && guestDetail?.isRegistrationFormFilled && id1Available ==true" fxLayout="row"
                    fxLayoutAlign="start center">
                    <div fxLayout="column" fxLayoutAlign="center start" #button (click)="downloadIdProof(1)">
                      <img style="cursor: pointer;" src="../assets/images/image.svg">
                    </div>
                    <div class="width_100" fxLayout="column" fxLayoutAlign="center start">
                      <div style="font-size: 10px;font-weight:600;color: #495057;">
                        id1.png</div>
                      <div style="font-size: x-small;">{{size1}}</div>
                    </div>
                  </div>
                  <div class="container_sub_title_2"
                    *ngIf="guestDetail && guestDetail?.isRegistrationFormFilled && id2Available==true" fxLayout="row"
                    fxLayoutAlign="start center">
                    <div fxLayout="column" fxLayoutAlign="center start" #button (click)="downloadIdProof(2)">
                      <img style="cursor: pointer;" src="../assets/images/image.svg">
                    </div>
                    <div class="width_100" fxLayout="column" fxLayoutAlign="center start">
                      <div style="font-size: 10px;font-weight:600;color: #495057;">
                        id2.png</div>
                      <div style="font-size: x-small;">{{size2}}</div>
                    </div>
                  </div>
                </div>
                <ng-template #registration>
                  <div class="container_sub_title_2 width_100">
                    <!-- <span>File:</span> -->
                    <div class="width_100" style="color: #ADB5BD;line-height: 16px;" fxLayout="row"
                      fxLayoutAlign="start center">
                      No file available
                    </div>
                  </div>
                </ng-template>
              </div>

            </div>
          </div>
          <div *ngIf="guestDetail" class="container" fxFlex="100" fxLayout="column" fxLayoutAlign="start start">
            <div class="container_table-title width_100 no_border extra_margin_top" fxLayout="row"
              fxLayoutAlign="space-between center">
              Additional Guests ({{secondaryGuestDetail.data?.length}})
            </div>

            <div class="width_100 guest-table" fxLayout="column" fxLayoutGap="30px" fxLayoutAlign="center center">
              <div class="guest_list width_100">
                <mat-table *ngIf="secondaryGuestDetail.data.length > 0" [dataSource]="secondaryGuestDetail"
                  class="width_100">
                  <ng-container matColumnDef="lastName">
                    <mat-header-cell *matHeaderCellDef>
                      Last Name
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element"> {{element.lastName}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="firstName">
                    <mat-header-cell *matHeaderCellDef>
                      First Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.firstName}} </mat-cell>
                  </ng-container>

                  <!-- <ng-container matColumnDef="age">
                    <mat-header-cell *matHeaderCellDef>
                      Age
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.age}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="controlNo">
                    <mat-header-cell *matHeaderCellDef>
                      Control No.
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.controlNo || '-'}} </mat-cell>
                  </ng-container> -->

                  <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef>
                      Email
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.email || '-'}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="appActivity">
                    <mat-header-cell *matHeaderCellDef>
                      App Activity
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <div class="guest_status" fxLayout="row" fxLayoutAlign="center center"
                        [ngClass]="element.appActivity">
                        <div *ngIf="element.appActivity != 'checked_Out'">{{element.appActivity}}</div>
                        <div *ngIf="element.appActivity === 'checked_Out'">Checked-out</div>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="['lastName', 'firstName', 'email', 'appActivity']"></tr>
                  <tr mat-row *matRowDef="let row; columns: ['lastName', 'firstName', 'email', 'appActivity'];">
                  </tr>
                </mat-table>
              </div>
            </div>

          </div>
        </div>
      </mat-tab>
      <mat-tab label="User Management">
        <ng-template mat-tab-label>
          <span [ngClass]="guestDetail?.guests?.length > 1?'userManagement':''">User Management</span>
        </ng-template>
        <div *ngIf="!guestDataSource || guestDetail?.guests?.length == 0;else manage_" class="booking_detail_container"
          style="display: flex; justify-content: center; height: 630px;">
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
            <div class="button-section" (click)="openShareLink()"
              [class.disabledButton]="guestDetail?.checkInstatus==2">
              <div fxLayoutAlign="center center">Manage User</div>
            </div>
          </div>
        </div>
        <ng-template #manage_>
          <div class="booking_detail_container" style="display: flex; justify-content: right;">
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="end end">
              <div class="button-section" (click)="openShareLink()"
                [class.disabledButton]="guestDetail?.checkInstatus==2">
                <div fxLayoutAlign="center center">Manage User</div>
              </div>
            </div>
          </div>
          <div *ngIf="guestDataSource && guestDetail?.guests?.length != 0" class="container" fxFlex="100"
            fxLayout="column" fxLayoutAlign="start start">
            <div class="width_100 guest-table" fxLayout="column" fxLayoutGap="30px" fxLayoutAlign="center center">
              <div class="guest_list width_100">
                <mat-table [dataSource]="guestDataSource" class="width_100">
                  <ng-container matColumnDef="userId">
                    <mat-header-cell *matHeaderCellDef>
                      User ID
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.userId}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="guestName">
                    <mat-header-cell *matHeaderCellDef>
                      Guest Name
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element">{{element.firstName}} {{element.lastName}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="userRole">
                    <mat-header-cell *matHeaderCellDef>
                      User Role
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.userRole || '-'}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef>
                      Email
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.email || '-'}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="appActivity">
                    <mat-header-cell *matHeaderCellDef>
                      App Activity
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'mat-cell-disable':element.appActivity == 'disabled'}"
                      (click)="openShareLink()" *matCellDef="let element">
                      <div class="guest_status" fxLayout="row" fxLayoutAlign="center center"
                        [ngClass]="element.appActivity">
                        <div *ngIf="element.appActivity != 'checked_Out'">{{element.appActivity}}</div>
                        <div *ngIf="element.appActivity === 'checked_Out'">Checked-out</div>
                      </div>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef>

                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <button mat-button fxLayoutAlign="center center" [matMenuTriggerFor]="menu"
                        class="send_link_button">
                        <mat-icon>more_horiz</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu" yPosition="below">
                        <button mat-menu-item (click)="confirmDisableGuest(element)"
                          [disabled]="element.appActivity == 'active' || element.appActivity == 'disabled' || guestDetail?.checkInstatus==2">
                          Disable user
                        </button>
                      </mat-menu>
                    </mat-cell>
                  </ng-container>

                  <tr mat-header-row
                    *matHeaderRowDef="['userId','guestName', 'userRole', 'email', 'appActivity','action']"></tr>
                  <tr mat-row
                    *matRowDef="let row; columns: [ 'userId','guestName',  'userRole', 'email', 'appActivity','action'];">
                  </tr>
                </mat-table>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Appointments"></mat-tab>
      <mat-tab label="Invoice">
        <!--Added-->
        <div class="order-details" fxLayout="column" fxFlex="100" fxLayoutAlign="start start">
          <div class="order-details__header width_100" fxLayout="column" fxLayoutAlign="space-between end">
            <div class="order-details__header-download downloadDisabled" fxLayoutAlign="center center">
              <!-- <mat-icon [svgIcon]="'download-outline'"></mat-icon> --><svg xmlns="http://www.w3.org/2000/svg"
                width="15" height="16" viewBox="0 0 15 16" style="margin-right: 5px;">
                <path id="download-outline"
                  d="M16.071,6.382v5.647h1.254L15,14.072l-2.325-2.042h1.254V6.382h2.143M18.214,4.5H11.786v5.647H7.5L15,16.735l7.5-6.588H18.214V4.5M22.5,18.618H7.5V20.5h15Z"
                  transform="translate(-7.5 -4.5)" fill="#868E96" />
              </svg>
              Download invoice
            </div>
          </div>
          <form [formGroup]="orderDetailsForm" class="order-details__main" fxLayout="column" fxLayoutGap="20px"
            fxLayoutAlign="center start">
            <div class="order-details__main-edit row" [class.desktop]="!globalService.isMobile">
              <div fxLayout="column" fxLayoutAlign="end end" fxLayoutGap="12px">
                <div class="amountDue">Amount Due ({{globalService.currency}})</div>
                <div class="order-details__main-info-select order-details__header-status-unpaid" fxLayout="column"
                  fxLayoutAlign="center center">
                  <div style="font-size: 20px;font-weight: 500;">{{paymentDetails?.amountDue | number:'1.2-2'}}
                    {{globalService.currency}}</div>
                </div>
                <div class="order-details__main-edit cursor_pointer"
                  [class.disabledText]="guestDetail?.checkInstatus==2 || paymentDetails?.amountDue==0"
                  (click)="addPaymentCheckOut()" fxLayout="row">
                  <div style="padding-right:2px;margin-top: 8px;">Add payment</div>
                  <svg xmlns="http://www.w3.org/2000/svg"
                    *ngIf="guestDetail?.checkInstatus<2 && paymentDetails?.amountDue>0" width="15" height="15"
                    style="margin-top: 8px;" viewBox="0 0 15 15">
                    <path id="arrow-right"
                      d="M6,12.673v1.894H17.364l-5.208,5.208L13.5,21.12l7.5-7.5-7.5-7.5L12.155,7.465l5.208,5.208Z"
                      transform="translate(-6 -6.12)" fill="#1864ab" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg"
                    *ngIf="guestDetail?.checkInstatus==2 || paymentDetails?.amountDue==0" style="margin-top: 8px;"
                    width="15" height="15" viewBox="0 0 15 15">
                    <path id="arrow-right"
                      d="M6,12.673v1.894H17.364l-5.208,5.208L13.5,21.12l7.5-7.5-7.5-7.5L12.155,7.465l5.208,5.208Z"
                      transform="translate(-6 -6.12)" fill="#ADB5BD" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="order-details__main-info width_100" fxLayout="column">
              <div class="order-details__main-info-row width_100" fxLayoutAlign="start start">
                <div class="order-details__main-info-label" fxFlex="{{globalService.isMobile$.getValue() ? 35 : 10}}">
                  Billed to:
                </div>
                <div fxLayout="column" fxLayoutAlign="start start">
                  <div class="order-details__main-info-value" style="font-weight: 500;">{{guestDetail?.lastName + ', '
                    + guestDetail?.firstName}}
                  </div>
                  <br>
                  <div class="order-details__main-info-value">
                    {{(guestDetail?.houseNumber!=""?guestDetail?.houseNumber+',':"")}}{{(guestDetail?.streetName!=""?guestDetail?.streetName+',':"")}}
                  </div>
                  <br>
                  <div class="order-details__main-info-value">
                    {{guestDetail?.state}} {{guestDetail?.country}} {{guestDetail?.zipCode}}</div>
                </div>
              </div>

              <div class="order-details__main-info-row width_100" fxLayoutAlign="start center">
                <div class="order-details__main-info-label" fxFlex="{{globalService.isMobile$.getValue() ? 35 : 10}}">
                  Invoice No.</div>

                <div class="order-details__main-info-value">73453367</div>
              </div>

              <div class="order-details__main-info-row width_100" fxLayoutAlign="start center">
                <div class="order-details__main-info-label" fxFlex="{{globalService.isMobile$.getValue() ? 35 : 10}}">
                  Check-In/Out Date</div>

                <div class="order-details__main-info-value">{{guestDetail?.checkInDate}} - {{guestDetail?.checkOutDate}}
                </div>
              </div>

              <div class="order-details__main-info-row width_100" fxLayoutAlign="start center">
                <div class="order-details__main-info-label" fxFlex="{{globalService.isMobile$.getValue() ? 35 : 10}}">
                  Due date</div>

                <div class="order-details__main-info-value">{{guestDetail?.checkOutDate}}</div>
              </div>
            </div>
            <div class="order-details__main-order width_100" fxLayout="column" fxLayoutAlign="center start">
              <div class="order-details__main-order-header width_100" fxLayoutAlign="start center">
                <div class="order-details__main-order-item title width_100">PRODUCT/SERVICES</div>
                <div class="order-details__main-order-item title width_100">USER ID</div>
                <div class="order-details__main-order-price title width_100">PRICE</div>
                <div class="order-details__main-order-qty title width_100">QTY</div>
                <div class="order-details__main-order-total title width_100">TOTAL</div>
              </div>
              <div class="order-details__main-order-orders width_100" fxLayoutAlign="start center"
                *ngFor="let item of orders">
                <div class="order-details__main-order-item width_100">{{item?.service}}</div>
                <div class="order-details__main-order-item width_100">{{guestDetail?.userId}}</div>
                <div class="order-details__main-order-price width_100">{{item?.total | number:'1.2-2'}}
                  {{globalService.currency}}</div>
                <div class="order-details__main-order-qty width_100">1</div>
                <div class="order-details__main-order-total width_100">
                  {{item?.total * 1 | number:'1.2-2'}} {{globalService.currency}}
                </div>
              </div>
              <div class="order-details__main-order-result pt-20 width_100" fxLayout="row-reverse">
                <div class="order-details__main-order-total width_100">
                  <!-- {{getTotalNumber(subTotal)}} € -->{{paymentDetails?.subTotal | number:'1.2-2'}}
                  {{globalService.currency}}
                </div>
                <div class="order-details__main-order-qty title width_100">Subtotal</div>
              </div>
              <div class="order-details__main-order-result width_100" fxLayout="row-reverse">
                <div class="order-details__main-order-total width_100">
                  <!-- {{getTotalNumber(tax)}} € --> 0.00 {{globalService.currency}}
                </div>
                <div class="order-details__main-order-qty title width_100">Tax (0%)</div>
              </div>
              <div class="order-details__main-order-result width_100" fxLayout="row-reverse">
                <div class="order-details__main-order-total width_100">
                  <!-- {{getTotalNumber(0)}} € --> 0.00 {{globalService.currency}}
                </div>
                <div class="order-details__main-order-qty title width_100">Voucher</div>
              </div>
              <div class="order-details__main-order-result total width_100" fxLayout="row-reverse">
                <div class="order-details__main-order-total width_100">
                  <!-- {{getTotalNumber(totalAmount)}} € --> {{paymentDetails?.total | number:'1.2-2'}}
                  {{globalService.currency}}
                </div>
                <div class="order-details__main-order-qty width_100">Total</div>
              </div>
              <div class="order-details__main-order-result total width_100" fxLayout="row-reverse">
                <div class="order-details__main-order-total width_100">
                  <!-- {{getTotalNumber(totalAmount)}} € --> {{paymentDetails?.amountPaid | number:'1.2-2'}}
                  {{globalService.currency}}
                </div>
                <div class="order-details__main-order-qty width_100">Amount Paid</div>
              </div>
              <div style="background: #495057;" fxLayout="row-reverse">
                <div class="order-details__main-order-qty width_100">
                  <hr>
                </div>
              </div>
              <div class="order-details__main-order-result total width_100" fxLayout="row-reverse">
                <div class="order-details__main-order-total width_100">
                  <!-- {{getTotalNumber(totalAmount)}} € --> {{paymentDetails?.amountDue | number:'1.2-2'}}
                  {{globalService.currency}}
                </div>
                <div class="order-details__main-order-qty width_100" style="color: #FF3333;">Amount Due</div>
              </div>
            </div>
          </form>
        </div>
        <!--End Added-->
      </mat-tab>
      <mat-tab label="Request"></mat-tab>
      <mat-tab label="Notes"></mat-tab>
      <mat-tab label="Chat">
        <app-messages-side *ngIf="guestList?.length && selectedTab === 6" [guestDetailChat]="true"
          [fxFlex]="isMobileDisplay ? 0 : 100" [data]="data" [selectedChat]="roomDetails.roomname"
          [guestList]="guestList">
        </app-messages-side>
      </mat-tab>
    </mat-tab-group>
    <div class="button_checkout" (click)="openCheckoutForm()" fxLayout="row" fxLayoutAlign="center center"
      *ngIf="guestDetail?.checkInstatus==1 || guestDetail?.checkInstatus==2"
      [class.disabled]="guestDetail?.checkInstatus==2">
      <mat-icon class="checkoutIcon" *ngIf="guestDetail?.checkInstatus<2"><svg xmlns="http://www.w3.org/2000/svg"
          width="15" height="15" viewBox="0 0 15 15">
          <path id="log-out-variant"
            d="M13.733,14.992l2.158-2.158H7.833V11.167h8.058L13.733,9.008l1.183-1.175L19.083,12l-4.167,4.167-1.183-1.175M17.833,4.5A1.667,1.667,0,0,1,19.5,6.167v3.892L17.833,8.392V6.167H6.167V17.833H17.833V15.608L19.5,13.942v3.892A1.667,1.667,0,0,1,17.833,19.5H6.167A1.667,1.667,0,0,1,4.5,17.833V6.167A1.661,1.661,0,0,1,6.167,4.5Z"
            transform="translate(-4.5 -4.5)" fill="#ffffff" />
        </svg></mat-icon>
      <mat-icon class="checkoutIcon" *ngIf="guestDetail?.checkInstatus==2"><svg xmlns="http://www.w3.org/2000/svg"
          width="15" height="15" viewBox="0 0 15 15">
          <path id="log-out-variant"
            d="M13.733,14.992l2.158-2.158H7.833V11.167h8.058L13.733,9.008l1.183-1.175L19.083,12l-4.167,4.167-1.183-1.175M17.833,4.5A1.667,1.667,0,0,1,19.5,6.167v3.892L17.833,8.392V6.167H6.167V17.833H17.833V15.608L19.5,13.942v3.892A1.667,1.667,0,0,1,17.833,19.5H6.167A1.667,1.667,0,0,1,4.5,17.833V6.167A1.661,1.661,0,0,1,6.167,4.5Z"
            transform="translate(-4.5 -4.5)" fill="#ADB5BD" />
        </svg></mat-icon>
      <div>Check Out Guest</div>
    </div>
    <div class="button_checkin" (click)="openCheckinForm()" fxLayout="row" fxLayoutAlign="center center"
      *ngIf="guestDetail?.checkInstatus==0"
      [class.disabled]="guestDetail.status=='No Show' || guestDetail.status=='Cancelled'">
      <mat-icon class="checkinIcon" *ngIf="guestDetail.status!='No Show' && guestDetail.status!='Cancelled'"><svg
          xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path id="log-out-variant"
            d="M11.42,17.09,8.83,14.5H18.5v-2H8.83l2.59-2.59L10,8.5l-5,5,5,5,1.42-1.41M6.5,4.5a2,2,0,0,0-2,2v4.67l2-2V6.5h14v14H6.5V17.83l-2-2V20.5a2,2,0,0,0,2,2h14a2,2,0,0,0,2-2V6.5a1.993,1.993,0,0,0-2-2Z"
            transform="translate(-4.5 -4.5)" fill="#0ca678" />
        </svg>
      </mat-icon>
      <mat-icon class="checkoutIcon" *ngIf="guestDetail.status=='No Show' || guestDetail.status=='Cancelled'"><svg
          xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
          <path id="log-out-variant"
            d="M13.733,14.992l2.158-2.158H7.833V11.167h8.058L13.733,9.008l1.183-1.175L19.083,12l-4.167,4.167-1.183-1.175M17.833,4.5A1.667,1.667,0,0,1,19.5,6.167v3.892L17.833,8.392V6.167H6.167V17.833H17.833V15.608L19.5,13.942v3.892A1.667,1.667,0,0,1,17.833,19.5H6.167A1.667,1.667,0,0,1,4.5,17.833V6.167A1.661,1.661,0,0,1,6.167,4.5Z"
            transform="translate(-4.5 -4.5)" fill="#ADB5BD" />
        </svg></mat-icon>
      <div>Check in Guest</div>
    </div>
    <!-- <div *ngIf="guestDetail && !(globalService.isMobile$.getValue())" fxLayout="row" fxLayoutGap="8px"
      fxLayoutAlign="center center"
      [ngClass]="guestDetail.appActivity == 'Active' || guestDetail.status == 'Confirmed' || guestDetail.appActivity == 'Manage'  ? 'disabled_custom' : ''"
      class="delete_guest cursor_pointer" (click)="confirmDeleteGuest()">
      <mat-icon [svgIcon]="'delete'" style="margin-right: 5px;
      height: 14px;
      width: 16px;"> -->
    <!-- [class.active]="guestDetail.appActivity !== 'Active' || guestDetail.status !== 'Confirmed'" -->
    <!-- </mat-icon>
      <div>Delete Guest</div>
    </div> -->
  </div>
  <!-- <div *ngIf="guestDetail && globalService.isMobile$.getValue()" fxLayout="row" fxLayoutGap="8px"
    fxLayoutAlign="center center"
    [ngClass]="guestDetail.appActivity == 'Active' || guestDetail.status == 'Confirmed' || guestDetail.appActivity == 'Manage' ? 'disabled_custom' : ''"
    class="delete_guest cursor_pointer width_100" (click)="confirmDeleteGuest()">
    <mat-icon [svgIcon]="'delete'"></mat-icon>
    <div>Delete Guest</div>
  </div> -->
</div>