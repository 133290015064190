import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { GlobalServices } from '../../services/global.services';

import { GuestStatusModel, SortFilterConfigModel } from '../../models/shared.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-sort-filter',
    templateUrl: './sort-filter.component.html',
    styleUrls: ['./sort-filter.component.scss']
})
export class SortFilterComponent implements OnInit {

    @Input() guestStatus: GuestStatusModel[];
    @Input() datepickers = false;
    @Input() options: SortFilterConfigModel[];

    @Output() filterSelected: EventEmitter<any> = new EventEmitter<any>();

    public datumState: string;

    public searchAmount = 2;

    public filterForm: UntypedFormGroup;

    public checkInState = false;
    public checkOutState = false;

    public get getIsAnyValueForm() {
        return !!this.options.filter(item => item.items.find(option => option.checked)).length;
    }

    constructor(public globalService: GlobalServices,
                public dialogRef: MatDialogRef<SortFilterComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        this.datepickers = this.data.datepickers;
        if (this.data.options) {
            this.options = this.data.options;
        }

        this.filterForm = new UntypedFormGroup({
            datum: new UntypedFormControl(),
        });
        if (this.datepickers) {
            this.filterForm.addControl('checkInDate', new UntypedFormControl());
            this.filterForm.addControl('checkOutDate', new UntypedFormControl());
        }
    }

    public closePopup() {
        this.dialogRef.close();
    }

    public clearFilters() {
        this.filterForm.reset();
    }

    public showResults() {
        this.dialogRef.close({
            options: this.options
        });
    }
}
