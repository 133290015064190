<div class="add-payment">
    <div class="add-payment__header" fxLayout="column" fxLayoutAlign="center flex-start">
        <div class="add-payment__header-title">
            CHECK OUT GUEST
        </div>
        <div class="add-payment__header-subtitle">
            Booking ID: {{this.bookingNumber}}
        </div>
        <div class="add-payment__header-close">
            <mat-icon class="close-icon" [svgIcon]="'close'" (click)="closePopup()"></mat-icon>
        </div>
    </div>
    <form [formGroup]="paymentForm" *ngIf="paymentForm" class="add-payment__main " [class.iphone-container]="isiOS"
        fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start center">
        <div class="width_100 add-payment__main-orders" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-label>Primary Guest</mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput formControlName="primaryGuest">
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-label>Check-In/Out Dates </mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput formControlName="checkIn">
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-label>Room</mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput formControlName="room">
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-label>Amount Balance</mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput name="balance" formControlName="amtBalance">
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="add-payment__settled" fxLayout="row" *ngIf="balance<=0">
        <div style="margin-right: 7px;"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                viewBox="0 0 20 20">
                <path id="bulb-on-outline"
                    d="M18.773,10.591H21.5v1.818H18.773V10.591m-17.273,0H4.227v1.818H1.5V10.591M12.409,1.5V4.227H10.591V1.5h1.818M5.064,3.773,7,5.718,5.709,7,3.773,5.073l1.291-1.3M16,5.709l1.927-1.936,1.3,1.3L17.291,7,16,5.709m-4.5.336a5.457,5.457,0,0,1,2.727,10.182v1.636a.909.909,0,0,1-.909.909H9.682a.909.909,0,0,1-.909-.909V16.227A5.457,5.457,0,0,1,11.5,6.045m1.818,13.636v.909a.909.909,0,0,1-.909.909H10.591a.909.909,0,0,1-.909-.909v-.909h3.636m-2.727-2.727h1.818V15.018a3.636,3.636,0,1,0-1.818,0Z"
                    transform="translate(-1.5 -1.5)" fill="#1864ab" />
            </svg></div>
        <div style="align-self: center;font-size: 13px;">The guest has settled all outstanding charges and is ready for check-out.</div>
    </div>
    <div class="add-payment__unsettled" fxLayout="row" *ngIf="balance>0">
        <div style="margin-right: 7px;"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="18"
                viewBox="0 0 20 18">
                <path id="warning" d="M12.409,14.368H10.591V10.579h1.818m0,7.579H10.591V16.263h1.818M1.5,21h20L11.5,3Z"
                    transform="translate(-1.5 -3)" fill="#d9480f" />
            </svg></div>
        <div style="font-size: 13px;">The guest has an unpaid balance on their account. To continue the check out process, all outstanding
            balance must be settled first.</div>
    </div>
    <div class="add-payment__summary" fxLayout="column" fxLayoutAlign="center center">
        <div class="add-payment__summary-actions" fxLayoutAlign="center center">
            <div mat-ripple class="add-payment__summary-clear" (click)="closePopup()">
                Cancel
            </div>
            <div mat-ripple [class.disabled]="balance>0" class="add-payment__summary-show" (click)="showDialog()">
                Check-out
            </div>
        </div>
    </div>
</div>