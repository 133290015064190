<div class="share-link">
    <div class="share-link__header" fxLayout="row" fxLayoutAlign="start flex-start" fxLayoutGap="50px">
        <div class="share-link__header-title" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
            <div>{{requestData.guestName}}</div>
            <div class="share-link__header-header_room">Booking No. {{requestData.bookingId}}</div>
        </div>
        <div class="filter_section dropdownCustom" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start end">
            <app-select class="filter_3" label="Status" [noEmptyValue]="true" [value]="requestStatus"
                (selectionChange)="requestStatus = $event" [selectOptions]="selectOptions"></app-select>
        </div>
    </div>
    <div class="share-link__main">
        <div class="event_description">
            <div class="event_description_left">
                <div class="event_description_details">
                    <div class="event_description_details_icon">
                        <img src="../../../assets/images/spaclipboard.svg" alt="">
                    </div>
                    <div class="event_description_details_icon_right">
                        <div class="event_description_details_title">Status</div>
                        <div class="event_description_details_value">{{requestData.status}}</div>
                    </div>
                </div>             
                <div class="event_description_details">
                    <div class="event_description_details_icon">
                        <img src="../../../assets/images/spacalendar.svg" alt="">
                    </div>
                    <div class="event_description_details_icon_right">
                        <div class="event_description_details_title">Date & Time</div>
                        <div class="event_description_details_value">{{requestData.date | date: 'dd/MM/yyyy'}}, {{requestData.time}}</div>
                    </div>
                </div>
                <div class="event_description_details" *ngIf="requestData.note && requestData.note!=undefined">
                    <div class="event_description_details_icon">
                        <img src="../../../assets/images/spanote.svg" alt="">
                    </div>
                    <div class="event_description_details_icon_right">
                        <div class="event_description_details_title">Note</div>
                        <div class="event_description_details_value">{{requestData.note}}</div>
                    </div>
                </div>
            </div>
            <div class="event_description_right">
                <div class="event_description_details">
                    <div class="event_description_details_icon">
                        <img src="../../../assets/images/spaperson.svg" alt="">
                    </div>
                    <div class="event_description_details_icon_right">
                        <div class="event_description_details_title">Guest Name</div>
                        <div class="event_description_details_value">{{requestData.guestName}}</div>
                    </div>
                </div>
                <div class="event_description_details">
                    <div class="event_description_details_icon">
                        <img src="../../../assets/images/door.svg" alt="">
                    </div>
                    <div class="event_description_details_icon_right">
                        <div class="event_description_details_title">Room No.</div>
                        <div class="event_description_details_value">{{requestData.room}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="event_buttons">
            <a (click)="onClose()" class="close_button">Close</a>
            <a (click)="onDeleteEvent()" class="delete_button">Delete Request</a>
            <a (click)="onEditEvent()" class="edit_button">Edit Info</a>
        </div>
    </div>
</div>