<div *ngIf="label">
    <mat-label>{{label}}</mat-label>
    <span class="color_red" *ngIf="customControl?.validator">*</span>
</div>
<mat-form-field class="{{styleClass}} selectBox"
    [class.required]="!val && customControl?.touched && customControl?.errors?.required" appearance="outline"
    fxLayoutAlign="start center">
    <mat-select [value]="val" [placeholder]="placeholder"
        (selectionChange)="selectionChange.emit($event.value);value = $event.value">
        <mat-option [value]="null" *ngIf="!noEmptyValue">Select</mat-option>
        <mat-option [value]="select[valueField]" *ngFor="let select of selectOptions">
            <mat-icon *ngIf="select?.svgIcon" [svgIcon]="select?.svgIcon"></mat-icon>
            {{select[labelField]}}
        </mat-option>
    </mat-select>
    <mat-icon [svgIcon]="'keyboard-arrow-down'"></mat-icon>
</mat-form-field>
<div *ngIf="!val && customControl?.touched && customControl?.errors?.required" class="alert">
    <mat-error *ngIf="customControl?.errors?.required">{{label}} is required</mat-error>
</div>