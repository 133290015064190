import {
  Injector,
  Pipe,
  PipeTransform
} from '@angular/core';
import { DatePipe } from '@angular/common';

const pipesMap = {
  date: DatePipe
};

@Pipe({
  name: 'dynamicPipe'
})
export class DynamicPipe implements PipeTransform {
  public constructor(
    private injector: Injector
  ) {}

  transform(value: string | number, pipes): any {
    if (!value || !pipes || !pipes?.length) {
      return value;
    } else {
      return pipes.reduce((acc, pipeObj) => {
        const pipe = this.injector.get(pipesMap[pipeObj.name]);
        return pipe.transform(acc, pipeObj.transformer);
      }, value);
    }
  }
}
