import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { RestfullServices } from 'src/app/shared/services/restfull.services';
import { GlobalServices } from 'src/app/shared/services/global.services';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GuestModelSpa } from 'src/app/shared/models/shared.model';
import { DatePipe } from '@angular/common';
import * as introJs from 'intro.js';

@Component({
  selector: 'app-add-event-handler',
  templateUrl: './add-event-handler.component.html',
  styleUrls: ['./add-event-handler.component.scss']
})
export class AddEventHandlerComponent implements OnInit, AfterViewInit {
  introJS = introJs.default();
  addEventForm: UntypedFormGroup;
  servicesGroup: any = [];
  serviceDuration: string = "";
  selectedService: any | null;// [{name:"",services:[{value:"",viewValue:"",spaId:0}]}];
  filteredUser: Observable<string[] | GuestModelSpa[]>;
  userGroup: GuestModelSpa[] = [];
  months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  hourList1 = new Array(23).fill(null).map((_, i) => i + 1);
  hourList = this.hourList1.map(x => x < 10 ? "0" + x : x);
  minList1 = new Array(59).fill(null).map((_, i) => i + 1);
  minList = this.minList1.map(x => x < 10 ? "0" + x : x);
  Assignee = [
    { value: 'Maina, Zoya', viewValue: 'Maina, Zoya' },
    { value: 'Kimani, Aysha', viewValue: 'Kimani, Aysha' },
    { value: 'Ouma, Shani', viewValue: 'Ouma, Shani' },
    { value: 'Muthoni, Chege', viewValue: 'Muthoni, Chege' },
  ];
  endTime = ["15 mins", "30 mins", "45 mins", "60 mins", "90 mins", "120 mins"]
  constructor(
    public dialogRef: MatDialogRef<AddEventHandlerComponent>, private restfullServices: RestfullServices, private globalService: GlobalServices, private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any, private cdr: ChangeDetectorRef
  ) { }


  isEdit: boolean = false;
  ngOnInit(): void {
    this.addEventForm = new UntypedFormGroup({
      dtb: new UntypedFormControl('', Validators.required),
      startTime: new UntypedFormControl('', Validators.required),
      // startHour: new FormControl('', Validators.required),
      // startMin: new FormControl('', Validators.required),
      endTime: new UntypedFormControl('', Validators.required),
      duration: new UntypedFormControl('', Validators.required),
      guestName: new UntypedFormControl('', Validators.required),
      serviceType: new UntypedFormControl('', Validators.required),
      assignee: new UntypedFormControl('', Validators.required),
      note: new UntypedFormControl('')
    });
    if (this.data?.eventType == "isEdit") {
      this.isEdit = true;
      let event = this.data?.event;
      let endDate = new Date(event?.event?._def?.extendedProps.dateAndTime);
      let durationSplit = event?.event?._def?.extendedProps.durationTime.split(' ');
      endDate.setMinutes(endDate.getMinutes() + (+durationSplit[0]));
      // this.addEventForm.controls['appointmentTitle'].setValue(event?.event?._def?.title);
      this.addEventForm.controls['guestName'].setValue(event?.event?._def?.extendedProps.guestName);
      this.addEventForm.controls['dtb'].setValue(event?.event?._def?.extendedProps.dateAndTime);
      this.addEventForm.controls['startTime'].setValue(this.convertTimeTo24(this.datePipe.transform(event?.event?._def?.extendedProps.dateAndTime, 'shortTime')));
      this.addEventForm.controls['endTime'].setValue(this.convertTimeTo24(this.datePipe.transform(endDate, 'shortTime')));
      this.addEventForm.controls['duration'].setValue(event?.event?._def?.extendedProps.durationTime);
      this.addEventForm.controls['serviceType'].setValue(event?.event?._def?.extendedProps.spaDetails.services.spaId);
      this.addEventForm.controls['assignee'].setValue(event?.event?._def?.extendedProps.assign);
      this.addEventForm.controls['note'].setValue(event?.event?._def?.extendedProps.note);
      //# --  Set remaining value from as per new props
    }
    else {
      //let date = this.days[this.data?.event?.date.getDay()] + ", " + this.data?.event?.date.getDate() + " " + this.months[this.data?.event?.date.getMonth()] + " " + this.data?.event?.date.getFullYear();
      this.addEventForm.controls['dtb'].setValue(this.data?.event?.date)
      // var startHour = new Date(this.data?.event?.date).getHours();
      // var startMin = new Date(this.data?.event?.date).getMinutes();
      // this.addEventForm.controls['startHour'].setValue(startHour < 10 ? "0" + startHour : startHour);
      // this.addEventForm.controls['startMin'].setValue(startMin < 10 ? "0" + startMin : startMin);
      //this.addEventForm.controls['endTime'].setValue(this.datePipe.transform(this.data?.event?.date.setTime(this.data?.event?.date.getTime() + 1 * 60 * 60 * 1000), 'shortTime'))
    }

    this.initializeData();
    this.filteredUser = this.addEventForm.controls['guestName'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.guestName;
        return name ? this._filter(name as string) : this.userGroup.slice();
      }),
    );
  }

  displayFn(guest: GuestModelSpa): string {
    return guest && guest.guestName ? guest.guestName : '';
  }

  private _filter(value: string): GuestModelSpa[] {
    const filterValue = value.toLowerCase();
    return this.userGroup.filter(option => option.guestName.toLowerCase().includes(filterValue));
  }

  initializeData() {
    this.restfullServices.getSpaServiceList()
      .subscribe(
        response => {
          if (response) {
            this.servicesGroup = response;
          }
        },
        () => this.globalService.showAlert(this.globalService.errorMsg));

    this.restfullServices.getGuestListWithId()
      .subscribe(
        response => {
          if (response) {
            this.userGroup = response;
          }
        },
        () => this.globalService.showAlert(this.globalService.errorMsg));
  }

  onSubmit() {
    if (this.addEventForm.valid) {
      let response = {
        isAdd: true,
        eventObj: this.addEventForm
      }
      //Save data to db api
      let payload = {
        bookingId: this.data?.event?.event?._def?.extendedProps.itemId,
        status: 1,
        spaId: +this.addEventForm.controls['serviceType'].value,
        guestId: +this.addEventForm.controls['guestName'].value.id,
        bookingDate: this.days[new Date(this.addEventForm.controls['dtb'].value).getDay()] + ", " + new Date(this.addEventForm.controls['dtb'].value).getDate() + " " + this.months[new Date(this.addEventForm.controls['dtb'].value).getMonth()] + " " + new Date(this.addEventForm.controls['dtb'].value).getFullYear(),
        bookingTime: this.convertTime(this.addEventForm.controls['startTime'].value),
        bookingNote: this.addEventForm.controls['note'].value,
        dateTime: new Date(),
        isBooked: this.data?.eventType == "isEdit" ? 1 : 0, // flag to add or update booking
        paymentStatus: "unpaid",
        transactionStatus: "open",
        bookedBy: this.addEventForm.controls['assignee'].value,
        duration: this.addEventForm.controls['duration'].value
      }
      this.restfullServices.bookSpa(payload)
        .subscribe(
          response => {
            if (response) {
              this.dialogRef.close({ data: response })
            }
          },
          () => this.globalService.showAlert(this.globalService.errorMsg));
    }
  }

  onCancel() {
    // let response = {
    //   isAdd: false,
    //   eventObj: this.addEventForm
    // }
    this.dialogRef.close({ data: new Array })
  }

  serviceChange(event: any) {
    let duration = "";
    this.servicesGroup.forEach(element => {
      return element.services.forEach(service => {
        if (service.spaId == event.value) {
          duration = service.duration;
        }
      });
    });
    this.addEventForm.controls['duration'].setValue(duration);
    this.serviceDuration = duration;
    if (this.addEventForm.controls['startTime'].value != null || this.addEventForm.controls['startTime'].value != undefined) {
      this.addEventForm.controls['endTime'].setValue(this.addtime(this.addEventForm.controls['startTime'].value, this.serviceDuration.split(" ")[0]));
    }
  }

  onSearchChange(event: any) {
    this.addEventForm.controls['endTime'].setValue(this.addtime(event, this.serviceDuration.split(" ")[0]));
  }

  durationChange(event: any) {
    this.serviceDuration = event.value;
    this.addEventForm.controls['endTime'].setValue(this.addtime(this.addEventForm.controls['startTime'].value, this.serviceDuration.split(" ")[0]));
  }

  addtime(time, min) {
    let times = time.split(":");
    min = min % (24 * 60);
    times[0] = (parseInt(times[0]) + parseInt((min / 60).toString()));
    times[1] = (parseInt(times[1]) + min % 60);

    if (times[1] >= 60) { times[1] = 0; times[0]++ };
    times[0] >= 24 ? times[0] -= 24 : null;

    times[0] < 10 ? times[0] = "0" + times[0] : null;
    times[1] < 10 ? times[1] = "0" + times[1] : null;

    return times.join(":");
  }

  convertTime(input: string) {
    let time = input.split(":");
    let hour = time[0];
    let min = time[1];
    let suffix = "";
    if (+hour > 12) {
      hour = (+hour - 12).toString();
      suffix = "pm";
    }
    else
      suffix = "am";
    return hour + ":" + min + " " + suffix;
  }

  convertTimeTo24(input: string) {
    let hours = Number(input.match(/^(\d+)/)[1]);
    let minutes = Number(input.match(/:(\d+)/)[1]);
    const AMPM = input.match(/\s(.*)$/)[1];
    if (AMPM.toLowerCase() === "pm" && hours < 12) hours = hours + 12;
    if (AMPM.toLowerCase() === "am" && hours == 12) hours = hours - 12;

    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;

    return `${sHours}:${sMinutes}`;
  }

  startTour() {
    this.introJS.setOptions({
      steps: [
        {
          //step 19
          tooltipClass: "intro-step-19",
          title: "NEW APPOINTMENT",
          element: document.getElementById('add_appointment'),
          intro: "Well done! This is where you can enter all necessary details of your customer/guest for a new appointment.",
          position: 'right'
        },


      ],
      disableInteraction: true,
      showBullets: false,
      showButtons: true,
      exitOnOverlayClick: false,
      keyboardNavigation: true,
      scrollToElement: true,
      scrollTo: 'tooltip',
    });
    this.introJS.start();
    this.introJS.onbeforechange((targetElement) => {
      this.introJS.refresh();
    });

    setTimeout(() => {
      if (document.querySelector(".intro-skip")) {
        let skipButton = document.querySelector(".intro-skip");
        skipButton.addEventListener('click', this.skipTour.bind(this));
      }

      if (document.querySelector(".introjs-skipbutton")) {
        let closeButton = document.querySelector(".introjs-skipbutton");
        closeButton.addEventListener('click', this.closeStep.bind(this));
      }
    }, 100);
  }

  skipTour(): void {
    this.introJS.exit();
    this.restfullServices.removeLocalStorage('isTourRunning');
    window.location.href = '#/pages/spa-wellness';
  }

  closeStep(): void {
    window.location.href = '#/pages/spa-wellness';
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.globalService.isMobile$.getValue()) {
        const isTourRunning = this.restfullServices.getLocalStorage('isTourRunning');
        const isFirstLogin = this.restfullServices.getLocalStorage('IsFirstLogin');
        const guidedTourAddEventFlag = this.restfullServices.getLocalStorage('guidedTourAddEventFlag');
        if (isTourRunning == 'true' && isFirstLogin=='false' && guidedTourAddEventFlag == "false") {
          this.restfullServices.setLocalStorage('guidedTourAddEventFlag','true');
          guidedTourAddEventFlag
          this.startTour();
          this.cdr.detectChanges();
        }
      }
    }, 500);
  }

}
