import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackBarComponent } from '../../../shared/components/snack-bar/snack-bar.component';

import { GlobalServices } from '../../../shared/services/global.services';

import { getTotalNumber, isiOS } from '../../../shared/functions/helpers';

import { OrderInstanceModel, OrderModel } from '../../../shared/models/shared.model';

@Component({
    selector: 'app-confirm-order',
    templateUrl: './confirm-order.component.html',
    styleUrls: ['./confirm-order.component.scss']
})
export class ConfirmOrderComponent implements OnInit {

    public itemsToOrder: OrderInstanceModel[] = [];
    public counterItems = 0;

    public subTotal = 0;
    public discount = 0;
    public totalAmount = 0;

    public orderDetails = false;

    public codeControl = new UntypedFormControl();

    public get isiOS(): boolean {
        return isiOS();
    }

    constructor(public globalService: GlobalServices,
                public router: Router,
                public activatedRoute: ActivatedRoute,
                private snackBar: MatSnackBar,
                public dialogRef: MatDialogRef<ConfirmOrderComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.orderDetails = this.router.url.includes('order-details');
    }

    getTotalNumber(value: number) {
        return getTotalNumber(Number(value));
    }

    ngOnInit(): void {
        this.itemsToOrder = this.data.itemsToOrder;
        this.calculatePrice();
    }

    public closePopup() {
        this.dialogRef.close();
    }

    public reduceAmount(item: OrderInstanceModel) {
        if (item.amount > 1) {
            item.amount--;
        } else {
            this.itemsToOrder = this.itemsToOrder.filter(order => order.order.title !== item.order.title);
        }
        this.calculatePrice();
    }

    public increaseAmount(item: {order: OrderModel, amount: number}) {
        if (item.amount < 100) {
            item.amount++;
            this.calculatePrice();
        }
    }

    public submit() {
        this.dialogRef.close(this.itemsToOrder);
    }

    public goToMenu() {
        this.closePopup();
        this.router.navigate(['/pages/food-beverages/add-order'], {
            queryParams: {
                orderNumber: this.activatedRoute.snapshot.queryParams.orderNumber
            }
        });
    }

    public applyCode() {
        if (this.codeControl.value === '111') {
            this.discount = 2.5;
            this.snackBar.openFromComponent(SnackBarComponent, {
                panelClass: ['custom-snackbar'],
                horizontalPosition: 'right',
                verticalPosition: 'top',
                data: {
                    svgIcon: 'check-circle',
                    message: 'Code accepted!'
                }
            });
        }
        this.codeControl.setValue('');
    }

    private calculatePrice() {
        this.subTotal = 0;
        this.totalAmount = 0;
        this.counterItems = 0;
        this.itemsToOrder.forEach(item => {
            this.subTotal += item.amount * item.order.price;
            this.totalAmount = this.subTotal - this.discount;
            this.counterItems += item.amount;
        });
    }
}
