import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material.module';

import { HeaderComponent } from './header/header.component';

@NgModule({
    declarations: [
        HeaderComponent
    ],
    imports: [
        SharedModule,
        MaterialModule,
        CommonModule,
        FlexLayoutModule
    ],
    exports: [
        HeaderComponent
    ]
})
export class LayoutModule { }
