import { Component, OnInit } from '@angular/core';

import { GlobalServices } from '../../services/global.services';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})

export class LoaderComponent implements OnInit {

   public show = false;

    constructor(private globalService: GlobalServices) {
    }

    ngOnInit() {
        this.globalService.showLoader$.subscribe(res => this.show = res);
    }
}
