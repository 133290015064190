<div class="login-page">
  <div class="overlay"></div>
  <div class="container" fxFlex="100" fxLayoutAlign="center center">
    <div class="content">
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="start start">
        <form [formGroup]="form" fxLayout="column" fxFlex="100" fxLayoutAlign="start center">
          <div class="logo" fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
            <mat-icon [svgIcon]="'sopago-logo-coloured'"></mat-icon>
          </div>
          <div fxLayout="row" class="width_100" fxLayoutAlign="start center">
            <div class="title" fxFlex="100" fxLayoutAlign="start center">SIGN IN</div>
          </div>
          <div class="login_section width_100" fxLayout="column" fxFlex="100" fxLayoutGap="20px" fxLayoutAlign="start center">
            <div class="width_100" fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="start start">
              <mat-label>Email Address</mat-label>
              <mat-form-field class="width_100" appearance="outline">
                <input matInput formControlName="emailAddress" placeholder="user1@outlook.com">
              </mat-form-field>
              <div *ngIf="form.controls['emailAddress'].invalid && (form.controls['emailAddress'].dirty || form.controls['emailAddress'].touched)" class="alert">
                <mat-error *ngIf="(form.get('emailAddress')).errors?.required">Email id is required</mat-error> 
                <mat-error *ngIf="(form.get('emailAddress')).errors?.pattern">Email id is incorrect</mat-error> 
              </div>
            </div>

            <div class="width_100" fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="start start">
              <mat-label>Password</mat-label>
              <mat-form-field class="width_100" appearance="outline">
                <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password" placeholder="at least 6 characters">
                <button matSuffix mat-icon-button (click)="togglePassword()">
                  <mat-icon [svgIcon]="showPassword ? 'eye-outline' : 'eye-off-outline'"></mat-icon>
                </button>
              </mat-form-field>
              <div *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)" class="alert">
                <mat-error *ngIf="(form.get('password')).errors?.required">Password is required</mat-error> 
              </div>
            </div>
            <div class="width_100" fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="end center">
              <mat-label class="custom_label">Forgot Password?</mat-label>
            </div>
            <div (click)="login()"
                 class="buttonSection width_100"
                 fxLayout="row"
                 fxLayoutAlign="start center"
                 [ngClass]="form.valid ? '' : 'disabled' ">
              <div fxFlex="100" fxLayoutAlign="center center">Login</div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
