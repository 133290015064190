import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

import { CreateRequestDialogModel, HouseKeepingTimeModel } from 'src/app/shared/models/shared.model';
import { GlobalServices } from 'src/app/shared/services/global.services';
import { RestfullServices } from 'src/app/shared/services/restfull.services';

@Component({
    selector: 'app-create-request',
    templateUrl: './create-request.component.html',
    styleUrls: ['./create-request.component.scss']
})
export class CreateRequestComponent implements OnInit {

    public personName: string;
    public requestObj = {
        requestType: '',
        addedNote: '',
        startDate: '',
        endDate: '',
        cleaningTime: '',
        requestAmenity: '',
        requestQuantity: '',
        requestCategory: ''
    };
    public houseKeepingTime: HouseKeepingTimeModel[] = [];

    public checkInState = false;
    public checkOutState = false;
    public cleaningState = false;

    public amenityList = [];
    public categoryAmenityList = [];

    constructor(
        public dialogRef: MatDialogRef<CreateRequestComponent>,
        public restfullServices: RestfullServices,
        public globalService: GlobalServices,
        @Inject(MAT_DIALOG_DATA) public data: CreateRequestDialogModel
    ) {
    }

    ngOnInit(): void {
        this.personName = 'Lopez, Amanda';
        this.requestObj.requestType = '1';
        this.requestObj.addedNote = '';
        this.requestObj.cleaningTime = 'anytime';
        this.houseKeepingTime = [
            { value: 'anytime', label: 'Anytime' },
            { value: '01:00 pm', label: '01:00 pm' },
            { value: '03:00 pm', label: '03:00 pm' },
            { value: '05:00 pm', label: '05:00 pm' },
            { value: '07:00 pm', label: '07:00 pm' },
            { value: '09:00 pm', label: '09:00 pm' },
        ];
        this.getHouseKeepingData();
    }
    getHouseKeepingData() {
        this.globalService.showLoader$.next(true);
        this.restfullServices.getHouseKeepingData()
            .toPromise()
            .then(response => {
                this.amenityList = response;
                // console.log(this.houseKeepingData);
                this.globalService.showLoader$.next(false);
            })
            .catch(err => {
                this.globalService.showLoader$.next(false);
                this.globalService.showAlert(this.globalService.errorMsg);
            });
    }
    categoryChange(value: any) {
        this.requestObj.requestCategory = value;
        if (value == 1) {
            this.categoryAmenityList=this.amenityList[0].amenityItems.map(element =>
                ({
                label: element.amenityName,
                value: element.amenityName
            }));
        }
        else if (value == 2) {
            this.categoryAmenityList=this.amenityList[1].amenityItems.map(element =>
                ({
                    label: element.amenityName,
                    value: element.amenityName
                }));
        }
    }
}
