import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import firebase from 'firebase/compat/app';

import { takeUntil } from 'rxjs/operators';

import { SearchUserComponent } from '../search-user/search-user.component';

import { GlobalServices } from '../../../shared/services/global.services';

import { ChatGuestListModel, ChatModel, HouseKeepingCategoryModel, HouseKeepingCleaningModel, HouseKeepingRequestModel, SortFilterConfigModel } from '../../../shared/models/shared.model';

import { bindUserListDate, fillFilterValues, snapshotToArray, transformString } from '../../../shared/functions/helpers';
import { AutoUnsubscribe } from '../../../shared/functions/autounsubscribe';
import { RestfullServices } from 'src/app/shared/services/restfull.services';
import * as introJs from 'intro.js';


@Component({
    selector: 'app-chat-side',
    templateUrl: './chats-side.component.html',
    styleUrls: ['./chats-side.component.scss']
})
@AutoUnsubscribe()
export class ChatUserChatSideComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() public guestList;
    @Input() public selectedChat: string;

    @Output() public selectRoom = new EventEmitter();
    @Output() public selectChat = new EventEmitter();

    public rooms = [];
    public closeChatCount = 0;
    public openChatCount = 0;
    public search = '';

    public currentUserList = [];

    private ref = firebase.database().ref('chatrooms/');
    constructor(
        public dialog: MatDialog,
        public globalService: GlobalServices,
        public restfullServices: RestfullServices
    ) {
    }

    introJS = introJs.default();
    ngOnInit(): void {
        this.globalService.headerData$.next({
            headerTitle: 'Chat',
            showBackButton: false
        });

        this.globalService.guestChatRequestCount = 0;
        //Filter out deleted guests
        this.restfullServices.getGuestListWithId()
            .subscribe(
                response => {
                    if (response) {
                        this.currentUserList = response;
                        this.currentUserList = this.currentUserList.filter(element => 
                            element.status !== 'noShow' && 
                            element.status !== 'checkedOut' && 
                            element.status !== 'cancelled'
                          );
                        this.guestList = this.guestList.filter(element => this.currentUserList.some(el => element.id == el.id));
                    }
                },
                () => this.globalService.showAlert(this.globalService.errorMsg));
        this.getRoomDetails();


    }


    startTour() {
        this.introJS.setOptions({
            steps: [
                {
                    //Step 8
                    tooltipClass: "intro-step-8",
                    title: "CHAT",
                    element: document.getElementById('step-2_Chat'),
                    intro: "Engage in real-time conversations with your guests by responding to their inquiries and providing them with assistance.",
                    position: 'right'
                },

                {
                    //Step 9

                    tooltipClass: "intro-step-9",
                    title: "OPEN",
                    element: document.getElementById('open_chat'),
                    intro: "All new and pending messages will be displayed here until their status is marked as ‘Closed.’ Once a message is marked as ‘Closed,’ it will be moved to the ‘Closed.’ section.",
                    //position: 'top'
                },

                {
                    //Step 10

                    tooltipClass: "intro-step-10",
                    title: "CLOSED",
                    element: document.getElementById('closed_chat'),
                    intro: "Messages marked as ‘Closed’ are moved to this section.",
                    //position: 'right'
                }

            ],
            disableInteraction: true,
            showBullets: false,
            showButtons: true,
            exitOnOverlayClick: false,
            keyboardNavigation: true,
            scrollToElement: true,
            scrollTo: 'tooltip',
        });

        this.introJS.start();
        this.introJS.onbeforechange((targetElement) => {
            this.introJS.refresh();
        });

        setTimeout(() => {
            if (document.querySelector(".intro-skip")) {
                let skipButton = document.querySelector(".intro-skip");
                skipButton.addEventListener('click', this.skipTour.bind(this));
            }

            if (document.querySelector(".introjs-skipbutton")) {
                let closeButton = document.querySelector(".introjs-skipbutton");
                closeButton.addEventListener('click', this.closeStep.bind(this));
            }
        }, 100);
    }

    skipTour(): void {
        this.introJS.exit();
        this.restfullServices.removeLocalStorage('isTourRunning');
        window.location.href = '#/pages/chat';
    }

    closeStep(): void {
        window.location.href = '#/pages/chat';
    }
    ngOnDestroy() { }

    public getDotFormat(value: string) {
        return value.replace(/,/g, '.');
    }

    public openChat(room: ChatModel) {
        this.selectRoom.emit(room);
        firebase.database().ref('chatrooms/' + room.key + '/unreadAdminMessages').set(0);
    }

    public showNewUsers() {
        const config = {
            panelClass: '',
            width: '550px',
            height: '90%',
            data: {
                title: 'START CHAT',
                guestList: this.guestList
            }
        };
        if (this.globalService.isMobile$.getValue()) {
            config.panelClass = 'full-modal-component';
        }
        this.dialog.open(SearchUserComponent, config)
            .afterClosed()
            .pipe(takeUntil(this['destroy$']))
            .subscribe((result: ChatGuestListModel) => result?.id && this.addRoom(
                result.id,
                `${result.lastName}, ${result.firstName}`
            ));
    }

    private addRoom(guestId: number, guestname: string) {
        const existingChat = this.rooms.find(item => item.roomname === `${this.globalService.HOTELID}_${guestId}`);
        if (existingChat) {
            this.openChat(existingChat);
        } else {
            const chat: ChatModel = {
                roomname: `${this.globalService.HOTELID}_${guestId}`,
                guestname,
                status: 'open',
                unreadAdminMessages: 0,
                unreadGuestMessages: 0
            };
            const newRef = this.ref.push();
            chat.key = newRef.key;
            newRef.set(chat);
            setTimeout(() => this.openChat(chat), 1000);
        }
    }

    private getRoomDetails() {
        this.ref.on('value', response => {
            this.rooms = [];
            this.closeChatCount = 0;
            this.openChatCount = 0;
            const roomsAll = snapshotToArray(response as unknown as firebase.database.DataSnapshot[]);
            const rooms = roomsAll.filter(item => {
                if (item.roomname)
                    return item.roomname.includes(`${this.globalService.HOTELID}`)
            })
                .filter(item => item?.chats)
                .map(element => {
                    if ('status' in element) {

                    }
                    else {
                        firebase.database().ref('chatrooms/' + element.key + '/status').set('open')
                    }
                    element.status === 'close' ? this.closeChatCount++ : this.openChatCount++;
                    element.chats = Object.keys(element.chats).map(key => element.chats[key]);
                    return element;
                }).sort((a, b) => {
                    if ((a.chats && a.chats.length > 0) && (b.chats && b.chats.length > 0)) {
                        const dateString1 = a.chats[a.chats.length - 1].time;
                        const dateParts1 = dateString1.split(':');
                        const dateString2 = b.chats[b.chats.length - 1].time;
                        const dateParts2 = dateString2.split(':');
                        return dateParts2[0] - dateParts1[0];
                    }
                })
                .sort((a, b) => {
                    if ((a.chats && a.chats.length > 0) && (b.chats && b.chats.length > 0)) {
                        const dateString1 = a.chats[a.chats.length - 1].date;
                        const dateParts1 = dateString1.split('-');
                        const dateString2 = b.chats[b.chats.length - 1].date;
                        const dateParts2 = dateString2.split('-');
                        const date1 = new Date(+dateParts1[2], dateParts1[1] - 1, +dateParts1[0]);
                        const date2 = new Date(+dateParts2[2], dateParts2[1] - 1, +dateParts2[0]);
                        return date2.getTime() - date1.getTime();
                    }
                })
            this.globalService.guestChatRequestCount = 0;
            rooms.forEach((element, index) => {
                if (element.chats && element.chats.length > 0) {
                    if (element.unreadAdminMessages > 0) {
                        this.globalService.guestChatRequestCount = this.globalService.guestChatRequestCount + 1;
                    }
                    bindUserListDate(element.chats[element.chats.length - 1]);
                }
            });
            this.restfullServices.getGuestListWithId()
                .subscribe(
                    response => {
                        if (response) {
                            this.currentUserList = response;
                            this.rooms = rooms.filter(element => this.currentUserList.some(el => element.roomname.split('_')[1] == el.id))
                            this.rooms = this.rooms.sort((a, b) => b.unreadAdminMessages - a.unreadAdminMessages)
                        }
                    },
                    () => this.globalService.showAlert(this.globalService.errorMsg));

            // this.openChat(this.rooms[0]);
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (!this.globalService.isMobile$.getValue()) {
                const isTourRunning = this.restfullServices.getLocalStorage('isTourRunning');
                const isFirstLogin = this.restfullServices.getLocalStorage('IsFirstLogin');
                const guidedTourChatFlag = this.restfullServices.getLocalStorage('guidedTourChatFlag');
                if (isTourRunning == 'true' && isFirstLogin == 'false' && guidedTourChatFlag=="false") {
                    this.restfullServices.setLocalStorage('guidedTourChatFlag','true');
                    this.startTour();
                }
            }
        }, 500);
    }
}
