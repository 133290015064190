<div id="search_user_dialog">
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="start center">
    <div class="width_100 dialog_header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
      <div class="header">{{data.title}}</div>
      <mat-icon class="clear" (click)="onNoClick()">clear</mat-icon>
    </div>
    <div class="width_100 dialog_search" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center">
      <div class="width_100" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field class="width_100" appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <input (input)="searchUser($event.target.value)" matInput placeholder="Search name, booking id...">
        </mat-form-field>
      </div>
      <div class="width_100" fxLayout="column" fxLayoutAlign="start center">
        <div [mat-dialog-close]="guest" *ngFor="let guest of guestList; let indexOfelement = index" class="width_100 guest_list" fxLayout="row" fxLayoutAlign="space-between center">
          <div fxFlex="50" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
            <mat-icon [svgIcon]="'user-circle-outline'"></mat-icon>
            <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start start">
              <div class="guest_name">{{guest.lastName}}, {{guest.firstName}}</div>
              <div class="guest_id">Booking Id : {{guest.bookingNumber}}</div>
            </div>
          </div>
          <div fxFlex="50" fxLayout="row" fxLayoutAlign="end center">
            <mat-icon class="guest_chat" [svgIcon]="'chat-typing'"></mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
