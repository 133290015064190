import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../../shared/material.module';
import { LayoutModule } from '../../layout-module/layout-module.module';
import { GuestRoutingModule } from './guest-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { PagesModule } from '../pages.module';

import { ShareLinkComponent } from './share-link/share-link.component';
import { GuestDetailComponent } from './guest-detail/guest-detail.component';
import { EditGuestComponent } from './edit-guest/edit-guest.component';
import { GuestComponent } from './guest.component';
import { SearchUserComponent } from '../chat/search-user/search-user.component';

@NgModule({
    declarations: [
        GuestComponent,
        EditGuestComponent,
        GuestDetailComponent,
        ShareLinkComponent,
        SearchUserComponent,
    ],
    imports: [
        SharedModule,
        GuestRoutingModule,
        MaterialModule,
        LayoutModule,
        CommonModule,
        FlexLayoutModule,
        PagesModule
    ]
})
export class GuestModule { }
