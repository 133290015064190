import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GlobalServices } from 'src/app/shared/services/global.services';
import { OrderService } from 'src/app/shared/services/order.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getTotalNumber, isiOS } from 'src/app/shared/functions/helpers';
import { takeUntil } from 'rxjs/operators';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { AutoUnsubscribe } from 'src/app/shared/functions/autounsubscribe';
import { RestfullServices } from 'src/app/shared/services/restfull.services';

@Component({
    selector: 'app-checkout-add-payment',
    templateUrl: './checkout-add-payment.component.html',
    styleUrls: ['./checkout-add-payment.component.scss']
})

@AutoUnsubscribe()
export class CheckoutAddPaymentComponent implements OnInit {

    public orderNumber: number;
    public bookingNumber:string = ""; 

    public paymentForm = new UntypedFormGroup({
        invoiceNumber: new UntypedFormControl(''),
        checkIn: new UntypedFormControl(''),
        billedTo: new UntypedFormControl(''),
        amount: new UntypedFormControl(''),
        paymentMethod: new UntypedFormControl('', [Validators.required]),
        amountReceived: new UntypedFormControl(''),
        change: new UntypedFormControl('')
    });

    public subTotal = 0;
    public discount = 0;
    public totalAmount = 0;
    public change = 0;

    public codeControl = new UntypedFormControl();

    constructor(public globalService: GlobalServices,
        private orderService: OrderService,
        private route: Router,
        private snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<CheckoutAddPaymentComponent>,
        private restfullServices: RestfullServices,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    public get isiOS(): boolean {
        return isiOS();
    }

    public getTotalNumber(value: number) {
        return getTotalNumber(Number(value));
    }

    ngOnInit(): void {

        this.paymentForm.get('invoiceNumber').setValue(this.data.data.invoice);
        this.paymentForm.get('invoiceNumber').disable();

        this.paymentForm.get('checkIn').setValue(this.data.data.checkInDate);
        this.paymentForm.get('checkIn').disable();

        this.paymentForm.get('billedTo').setValue(this.data.data.billedTo);
        this.paymentForm.get('billedTo').disable();

        this.paymentForm.get('amount').setValue(this.data.data.amountDue);
        this.paymentForm.get('amount').disable();

        this.bookingNumber = this.data.data.bookingNumber

        //this.calculateAmount();

        this.paymentForm.get('amountReceived').valueChanges
            .pipe(takeUntil(this['destroy$']))
            .subscribe(value => {
                this.paymentForm.get('change').setValue(+value - this.totalAmount > 0
                    ? (+value - this.totalAmount).toFixed(2)
                    : 0);
            });
    }

    ngOnDestroy() { }

    public closePopup() {
        this.dialogRef.close();
    }
    public calculateChange(value: number) {
        //this.change = value - this.totalAmount;
        this.paymentForm.get('change').setValue(+value - this.totalAmount > 0
            ? (+value - this.totalAmount).toFixed(2)
            : 0);
    }

    public addPayment() {
        this.restfullServices.addPaymentOnCheckout(this.globalService.selectedGuestId)
            .pipe(takeUntil(this['destroy$']))
            .subscribe((response) => {
                if (response) {
                    this.dialogRef.close(true);
                    this.snackBar.openFromComponent(SnackBarComponent, {
                        panelClass: ['custom-snackbar'],
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                        data: {
                            svgIcon: 'check-circle',
                            message: 'Payment successfully added'
                        }
                    });
                }
            }, () => {
                this.globalService.showLoader$.next(false);
                this.globalService.showAlert(this.globalService.errorMsg);
            });
    }

    public submit() {
        const order = this.data.order;
        order.paymentMethod = this.paymentForm.get('paymentMethod').value;
        this.dialogRef.close();
    }

    private calculateAmount() {
        this.data.order.orders.forEach(order => {
            this.totalAmount += order.order.price * order.amount;
        });
        this.paymentForm.get('amount').setValue(this.totalAmount);
        this.paymentForm.get('amount').disable();

        this.paymentForm.get('paymentMethod').setValue(this.data.order.paymentMethod);
    }

}
