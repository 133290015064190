import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';

import { SnackBarComponent } from '../../../shared/components/snack-bar/snack-bar.component';

import { GlobalServices } from '../../../shared/services/global.services';
import { OrderService } from '../../../shared/services/order.service';

import { getTotalNumber, isiOS } from '../../../shared/functions/helpers';
import { AutoUnsubscribe } from '../../../shared/functions/autounsubscribe';
import { DecimalPipe } from '@angular/common';
import { RestfullServices } from 'src/app/shared/services/restfull.services';

@Component({
    selector: 'app-add-payment',
    templateUrl: './add-payment.component.html',
    styleUrls: ['./add-payment.component.scss']
})
@AutoUnsubscribe()
export class AddPaymentComponent implements OnInit, OnDestroy {

    public orderNumber: number;

    public paymentForm = new UntypedFormGroup({
        orderNumber: new UntypedFormControl(''),
        amount: new UntypedFormControl(''),
        paymentMethod: new UntypedFormControl('', [Validators.required]),
        amountReceived: new UntypedFormControl(''),
        change: new UntypedFormControl('')
    });

    public subTotal = 0;
    public discount = 0;
    public totalAmount = 0;
    public change = 0;

    public codeControl = new UntypedFormControl();

    constructor(public globalService: GlobalServices,
        private restfullServices: RestfullServices,
        private orderService: OrderService,
        private route: Router,
        private snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<AddPaymentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private _decimalPipe: DecimalPipe) {
    }

    public get isiOS(): boolean {
        return isiOS();
    }

    public getTotalNumber(value: number) {
        return getTotalNumber(Number(value));
    }

    ngOnInit(): void {
        this.orderNumber = this.data.order.orderNumber;
        this.paymentForm.get('orderNumber').setValue(this.orderNumber);
        this.paymentForm.get('orderNumber').disable();
        this.paymentForm.get('change').disable();

        this.totalAmount = this.data.order.total;
        this.paymentForm.get('amount').setValue(this._decimalPipe.transform(this.totalAmount, '1.2-2'));
        this.paymentForm.get('amount').disable();

        //this.calculateAmount();

        this.paymentForm.get('amountReceived').valueChanges
            .pipe(takeUntil(this['destroy$']))
            .subscribe(value => {
                this.paymentForm.get('change').setValue(+value - this.totalAmount > 0
                    ? (+value - this.totalAmount).toFixed(2)
                    : 0);
            });
    }

    ngOnDestroy() { }

    public closePopup() {
        this.dialogRef.close();
    }
    public calculateChange(value: number) {
        //this.change = value - this.totalAmount;
        this.paymentForm.get('change').setValue(+value - this.totalAmount > 0
            ? (+value - this.totalAmount).toFixed(2)
            : 0);
    }

    public sendLink() {
        let payload = {
            orderNumber: this.data.order.orderNumber,
            orderStatus: "paid",
        }
        this.restfullServices.addOrderPayment(payload)
            .subscribe(response => {
                if (response) {
                    this.dialogRef.close(true);
                }
            })

        // this.snackBar.openFromComponent(SnackBarComponent, {
        //     panelClass: ['custom-snackbar'],
        //     horizontalPosition: 'right',
        //     verticalPosition: 'top',
        //     data: {
        //         svgIcon: 'check-circle',
        //         message: 'Guest App Link has been sent!'
        //     }
        // });
    }

    public submit() {
        const order = this.data.order;
        order.paymentMethod = this.paymentForm.get('paymentMethod').value;
        this.dialogRef.close();
    }

    private calculateAmount() {
        this.data.order.orders.forEach(order => {
            this.totalAmount += order.order.price * order.amount;
        });
        this.paymentForm.get('amount').setValue(this._decimalPipe.transform(this.totalAmount, '1.2-2'));
        this.paymentForm.get('amount').disable();

        this.paymentForm.get('paymentMethod').setValue(this.data.order.paymentMethod);
    }

}
