import { ChatModel } from '../models/shared.model';

export const transformString = (value: string) => value.toString().trim().toLowerCase();

export function fillFilterValues(formName: string, tableName: string, filterName: string) {
    this[formName].valueChanges.subscribe(data => {
        Object.keys(this[filterName])
            .forEach(key => {
                if (this[filterName][key] !== data[key]) {
                    this[filterName][key] = data[key];
                    this[tableName].filter = JSON.stringify(this[filterName]);
                }
            });
    });
}

export const snapshotToArray = (snapshot: any) => {
    const returnArr: any = [];

    snapshot.forEach((childSnapshot: any) => {
        if (typeof childSnapshot.val() !== 'string' && childSnapshot.val()) {
            const item = childSnapshot.val();
            item.key = childSnapshot.key;
            returnArr.push(item);
        }
    });

    return returnArr;
};

export const isEmptyObject = (myObject) => {
    for (const key in myObject) {
        if (myObject.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
};

export const getTotalNumber = (value: number) => {
    if (value.toString().split('.').length === 1) {
        return value.toLocaleString('DE') + '';
    } else if (value.toString().split('.')[1].length === 1) {
        return value.toLocaleString('DE') + '0';
    } else {
        return value.toLocaleString('DE');
    }
};

export const isiOS = () => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
};

export const bindUserListDate = (chat: ChatModel) => {
    const tDate = new Date();
    const tMonth = (tDate.getMonth() + 1) > 9 ? (tDate.getMonth() + 1) : (0 + '' + (tDate.getMonth() + 1));
    const toDate = (tDate.getDate()) > 9 ? (tDate.getDate()) : (0 + '' + (tDate.getDate()));
    const today = toDate + ',' + tMonth + ',' + tDate.getFullYear();
    const cDate = chat.date.split(' ')[0].split('-').toString();

    chat.date = cDate === today ? 'Today' : chat.date.split(' ')[0].split('-').toString();
    chat.time = chat.time.split(':')[0] + ':' + chat.time.split(':')[1];
    return chat;
};
