import { Subject } from 'rxjs';

export function AutoUnsubscribe() {

    return (constructor) => {
        const destroy$: Subject<void> = new Subject();
        constructor.prototype['destroy$'] = destroy$;
        constructor.prototype['ngOnDestroy'] = function() {
            destroy$.next();
            destroy$.complete();
        };
        return constructor;
    };
}
