<div class="food-bv">
    <div class="food-bv__title">
        Select Department
    </div>
    <div class="food-bv__tiles">
        <div class="food-bv__tiles-tile" fxLayoutAlign="center center" *ngFor="let item of departmentTiles"
            [class.disabled-tile]="!item.active">
            <img [src]="item.img" alt="sectionImage" >
            <div class="food-bv__tiles-title">
                {{item.label}}
            </div>
            <div class="food-bv__tiles-action" id="open_snack" *ngIf="item.active"
                (click)="navigateToOrders(item.id,item.route)">
                Open
            </div>
            <div class="food-bv__tiles-action" *ngIf="!item.active">
                <mat-icon [svgIcon]="'lock-outline'"></mat-icon>
            </div>
        </div>
    </div>
</div>