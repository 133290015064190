<div class="chat_user_content">
    <div *ngIf="!selectedChat || !selectedGuest" class="no_message width_100" style="height: 100%" fxLayout="row"
         fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutAlign="center center">
            <mat-icon [svgIcon]="'no-existing-chat'" style="height: 20vh;"></mat-icon>
            <div *ngIf="selectedChat && !selectedGuest">
                Something wrong with selectedGuest (please connect with developer)
            </div>
        </div>
    </div>
    <div *ngIf="selectedChat && selectedGuest" class="chat_container width_100">
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start">
            <div [fxFlex]="isMobileDisplay ? 100 : 60"
                 *ngIf="isMobileDisplay ? !activeUserInfo : true"
                 [class.chat-content]="guestDetailChat"
                 fxLayout="column"
                 fxLayoutAlign="space-between center">
                <div class="chat_section width_100 height_100" fxLayout="column" fxLayoutAlign="start center">
                    <div class="chat_header width_100" style="margin-top: 20px;" fxLayout="row" fxLayoutGap="20px"
                         fxLayoutAlign="space-between center">
                        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                            <div class="create_task">Action</div>
                            <div (click)="openRequest()" class="create_task_button" fxLayoutAlign="center center">
                                <div>Create task</div>
                            </div>
                        </div>
                        <div class="filter_Box" fxLayout="row" fxFlex="60" fxLayoutGap="10px" fxLayoutAlign="end center">
                            <div class="create_task">Status</div>
                            <div class="filter_section dropdownCustom" fxLayout="row" fxLayoutAlign="start center">
                                <app-select
                                            placeholder="Status"
                                            [noEmptyValue]="true"
                                            [value]="data.chatStatus"
                                            (selectionChange)="updateStatus($event)"
                                            [selectOptions]="selectOptions"
                                ></app-select>
                            </div>
                        </div>
                    </div>
                    <div class="chat_body width_100" style="height: 88%;" fxLayout="row" fxLayoutAlign="start center">
                        <div class="chat_body_container" [class.chat_body_container__empty]="chats && chats.length == 0">
                            <div fxLayout="column" class="chat-content" #chatcontent>
                                <div class="no_chat margin_bottom_20 height_100" *ngIf="chats && chats.length == 0"
                                     fxLayoutGap="20px" fxLayout="column"
                                     fxLayoutAlign="center center">
                                    <mat-icon [svgIcon]="'no-existing-chat'"></mat-icon>
                                    <div>No existing chats with this guest yet</div>
                                </div>
                                <div *ngFor="let chat of chats" class="margin_bottom_20" fxLayout="column"
                                     fxLayoutGap="10px">
                                    <!-- <div *ngIf="chat.type === 'message'"> -->
                                    <div *ngIf="chat.date" class="chat_date margin_bottom_20" fxFlex="100"
                                         fxLayout="row" fxLayoutAlign="center center">
                                        <div class="hr_line"></div>
                                        <div class="time_text">{{getDotFormat(chat?.date)}}</div>
                                    </div>
                                    <div class="chat-event" fxLayout="row" fxLayoutAlign="center center" *ngIf="chat?.type === 'taskEvent'">
                                        <mat-icon [svgIcon]="'time_icon'"></mat-icon>
                                        <div class="chat_time">{{getDotFormat(chat?.date)}}</div>
                                        <div class="inner_content">{{getContentEvent(chat.message)}}
                                            <span (click)="viewRequest(chat.message.split(' ').pop())">
                                                {{chat.message.split(' ').pop()}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="chat-message" text-right *ngIf="chat?.user == globalService.HOTELID && chat?.type !== 'taskEvent'">
                                        <div class="message right">

                                            <div class="message-content" fxLayout="row" fxLayoutAlign="end center">
                                                <!-- <span class="msg-date">{{chat.sendDate | date:'short'}}</span> -->
                                                <div class="inner_content" [innerHTML]="chat.message"></div>
                                                <mat-icon [svgIcon]="'user-circle'"></mat-icon>
                                                <div class="chat_time">{{chat.time}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chat-message chat-message_companion" text-left
                                         *ngIf="chat.user == selectedGuest?.id && chat?.type !== 'taskEvent'">
                                        <div class="message left">
                                            <div class="message-content" fxLayout="row" fxLayoutAlign="start center">
                                                <!-- <span class="msg-date">{{chat.sendDate | date:'short'}}</span> -->
                                                <mat-icon [svgIcon]="'user-circle'"
                                                          [matMenuTriggerFor]="menu"></mat-icon>
                                                <mat-menu #menu="matMenu" yPosition="below">
                                                    <button mat-menu-item (click)="activeUserInfo = !activeUserInfo">
                                                        Open guest details
                                                    </button>
                                                </mat-menu>
                                                <div class="chat_time">{{chat.time}}</div>
                                                <div class="inner_content" [innerHTML]="chat.message"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sticky-footer">
                    <div fxFlex="100" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="start center">
                        <mat-form-field appearance="outline">
                            <textarea matInput [(ngModel)]="data.message" (ngModelChange)="scrollChat()"
                                      placeholder="Write message" style="resize: none"></textarea>
                        </mat-form-field>
                        <mat-icon [svgIcon]="'send-message'" (click)="sendMessage()"></mat-icon>
                    </div>
                </div>
            </div>
            <app-chat-user-info
                    *ngIf="isMobileDisplay ? activeUserInfo : true"
                    [fxFlex]="isMobileDisplay ? 100 : 40"
                    [selectedGuest]="selectedGuest"
            ></app-chat-user-info>
        </div>
    </div>
</div>
