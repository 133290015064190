import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'FilterByArray' })
export class FilterByArrayPipe<T> implements PipeTransform {
  transform(items: T[], filed: string, value: string): T[] {
    value = value ? value.toString().toLowerCase() : '';

    return value
      ? items.filter((item) => item?.[filed]?.toString().toLowerCase()?.includes(value))
      : items;
  }
}
