<div class="modal-popup">
    <div fxLayout="row" fxLayoutAlign="start start" style="padding: 0px 15px;">
        <mat-icon class="checkoutIcon"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                viewBox="0 0 15 15">
                <path id="log-out-variant"
                    d="M13.733,14.992l2.158-2.158H7.833V11.167h8.058L13.733,9.008l1.183-1.175L19.083,12l-4.167,4.167-1.183-1.175M17.833,4.5A1.667,1.667,0,0,1,19.5,6.167v3.892L17.833,8.392V6.167H6.167V17.833H17.833V15.608L19.5,13.942v3.892A1.667,1.667,0,0,1,17.833,19.5H6.167A1.667,1.667,0,0,1,4.5,17.833V6.167A1.661,1.661,0,0,1,6.167,4.5Z"
                    transform="translate(-4.5 -4.5)" fill="#1864AB" />
            </svg></mat-icon>
        <h1 mat-dialog-title style="font-size: 18px;">Check-Out Guest</h1>
    </div>
    <div mat-dialog-content style="margin-bottom: 44px !important;font-size: 14px;padding: 15px;">
        Are you sure you want to proceed with checking out the guest? This action cannot be undone.
    </div>
    <div class="dialog_footer" fxLayout="row" fxLayoutAlign="end flex-end">
        <!-- <hr style="width: 100%;"> -->
        <div mat-dialog-actions style="align-self: flex-end;">
            <button class="modal-popup__clear" (click)="closePopup()" mat-button mat-dialog-close>Cancel</button>
            <button class="modal-popup__show" (click)="checkOutGuest()" mat-button mat-dialog-close
                cdkFocusInitial>Confirm</button>
        </div>
    </div>
</div>