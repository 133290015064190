import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { GlobalServices } from 'src/app/shared/services/global.services';
import { RestfullServices } from 'src/app/shared/services/restfull.services';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private restfullServices: RestfullServices,
    private snackBar: MatSnackBar,
    private globalService: GlobalServices, private route: Router) { }

  ngOnInit(): void {
  }
  public closePopup() {
    this.dialogRef.close();
  }

  public checkOutGuest() {
    this.restfullServices.checkOutGuest(this.data.payload)
      .toPromise()
      .then((response) => {
        if (response) {
          this.snackBar.openFromComponent(SnackBarComponent, {
            panelClass: ['custom-snackbar'],
            horizontalPosition: 'right',
            verticalPosition: 'top',
            data: {
              svgIcon: 'check-circle',
              message: 'Guest has been successfully checked out'
            }
          });
          const queryParams: any = {
            id: this.globalService.selectedGuestId,
          };
          //queryParams.shareLink = true;
          this.dialogRef.close();
          setTimeout(() => {
            this.route.navigate(['/pages/guests/guest'], { queryParams }).then(() => {
              window.location.reload();
            });
          }, 3000)
        }
      }, () => {
        this.globalService.showLoader$.next(false);
        this.globalService.showAlert(this.globalService.errorMsg);
      });
  }
}