import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { GuestComponent } from './guest.component';
import { GuestDetailComponent } from './guest-detail/guest-detail.component';
import { EditGuestComponent } from './edit-guest/edit-guest.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';

const routes: Routes = [
    {
        path: '',
        component: GuestComponent,
    },
    {
        path: 'guest',
        component: GuestDetailComponent
    },
    {
        path: 'add-guest',
        component: EditGuestComponent
    },
    {
        path: 'edit-guest',
        component: EditGuestComponent
    },
    {
        path: 'user-registration',
        component: UserRegistrationComponent
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class GuestRoutingModule {}
