
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ChatComponent } from './chat/chat.component';
import { HousekeepingComponent } from './housekeeping/housekeeping.component';
import { MyTeamComponent } from './my-team/my-team.component';
import { ReportsComponent } from './reports/reports.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { CalendarComponent } from './calendar/calendar.component';
import { HotelComponent } from './hotel/hotel.component';
import { WakeUpCallComponent } from './wake-up-call/wake-up-call.component';

const routes: Routes = [
    {
        path: '',
        component: HotelComponent,
        children: [
            {
                path: '',
                redirectTo: 'guests',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
            },
            {
                path: 'guests',
                loadChildren: () => import('./guest/guest.module').then( m => m.GuestModule),
            },
            {
                path: 'housekeeping',
                component: HousekeepingComponent
            },
            {
                path: 'chat',
                component: ChatComponent
            },
            {
                path: 'food-beverages',
                loadChildren: () => import('./food-beverages/food-beverages.module').then( m => m.FoodBeveragesModule),
            },
            {
                path: 'spa-wellness',
                loadChildren: () => import('./spa-wellness/spa-wellness.module').then( m => m.SpaWellnessModule),
                // component: SpaWellnessComponent
            },
            {
                path: 'transportation',
                loadChildren: () => import('./transportation/transportation.module').then( m => m.TransportationModule),
            },
            {
                path: 'kids-club',
                loadChildren: () => import('./kids-club/kids-club.module').then( m => m.KidsClubModule),
            },
            {
                path: 'wake-up-call',
                component: WakeUpCallComponent
            },
            {
                path: 'my-team',
                component: MyTeamComponent
            },
            {
                path: 'reports',
                component: ReportsComponent
            },
            {
                path: 'maintenance',
                component: MaintenanceComponent
            },
            {
                path: 'calender',
                component: CalendarComponent
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PagesRoutingModule {}
