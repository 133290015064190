<div class="share-link">
    <div class="share-link__header" fxLayout="column" fxLayoutAlign="center flex-start">
        <div class="share-link__header-title">
            Share guest app's link
        </div>
        <div class="share-link__header-subtitle">
            The guest(s) will receive an email with a link to the Guest App
        </div>
    </div>
    <div class="share-link__main">
        <div class="share-link__main-all" fxLayout="row" fxLayoutAlign="flex-end center">
            <mat-checkbox (change)="setAllState()">{{isActive ? 'Unselect' : 'Select'}} all</mat-checkbox>
        </div>
        <div class="width_100">
            <div class="share-link__main-section" *ngFor="let section of data.guests;let i = index">
                <div class="share-link__main-section-check">
                    <mat-checkbox [checked]="checkboxConfig[i].checked" [id]="'checkId'+i" [disabled]="section.appActivity=='active' || section.appActivity=='pending'" (change)="setCheckBoxState(i)">
                        {{guestsArr[i]}} guest
                    </mat-checkbox>
                </div>
                <hr>
                <div class="width_100" fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Last Name, First Name</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput [(ngModel)]="section.names" (input)="inputChange(i)">
                    </mat-form-field>
                </div>
                <div class="width_100" fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Email Address <span>*</span></mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput [(ngModel)]="section.email" (input)="inputChange(i)">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="share-link__summary" fxLayout="column" fxLayoutAlign="center center">
        <div class="share-link__summary-actions width_100" fxLayoutAlign="flex-end center">
            <div mat-ripple class="share-link__summary-clear cursor_pointer" (click)="closePopup()">
                Cancel
            </div>
            <div mat-ripple class="share-link__summary-show cursor_pointer" (click)="sendLink()">
                Send Link
            </div>
        </div>
    </div>
</div>
