<div class="housekeeping-page width_100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center">
    <form [formGroup]="cleaningFilterForm" class="width_100" *ngIf="globalService.isMobile$.getValue()">
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
            <div class="filter_section width_100" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field class="filter_1" appearance="outline" fxLayoutAlign="start center">
                    <mat-icon matPrefix>search</mat-icon>
                    <input formControlName="roomNumber" matInput placeholder="Room no." #input>
                    <button type="button" class="search_button" (click)="resetFilter()" *ngIf="searchValue">
                        <i class="material-icons">close</i>
                    </button>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="width_100 position_relative" fxLayout="row" fxLayoutAlign="start center">
        <mat-tab-group fxFlex="100" (selectedIndexChange)="setTableTools($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <div fxLayoutAlign="start center" fxLayoutGap="5px">
                        <div id="clean_request">Cleaning</div>
                        <div *ngIf="houseKeepingCleaningCount > 0" fxLayoutAlign="center center"
                            class="request_count">{{houseKeepingCleaningCount}}
                        </div>
                    </div>
                </ng-template>
                <div class="cleaning_detail_container">
                    <div fxFlex="100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start start">
                        <div class="cleaning_filter width_100" fxLayout="row" fxLayoutAlign="start center"
                            *ngIf="!globalService.isMobile$.getValue()">
                            <form fxLayout="row" fxLayoutAlign="space-between center" [formGroup]="cleaningFilterForm"
                                class="width_100">
                                <div class="filter_searchBox" fxFlex="40" fxLayout="row" fxLayoutAlign="start center">
                                    <div class="filter_section" fxLayout="row" fxLayoutAlign="start center">
                                        <mat-form-field class="filter_1" appearance="outline"
                                            fxLayoutAlign="start center">
                                            <mat-icon matPrefix>search</mat-icon>
                                            <input formControlName="roomNumber" matInput placeholder="Room no." #input>
                                            <button type="button" class="search_button" (click)="resetFilter()"
                                                *ngIf="searchValue">
                                                <i class="material-icons">close</i>
                                            </button>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="filter_Box" fxFlex="60" fxLayout="row" fxLayoutGap="15px"
                                    fxLayoutAlign="end center">
                                    <div (click)="resetCleaningFilter()" class="clear_filter_section cursor_pointer"
                                        fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="start center">
                                        <div>Clear filter</div>
                                        <mat-icon [svgIcon]="'refresh'"></mat-icon>
                                    </div>
                                    <div class="filter_section dropdownCustom" fxLayout="row"
                                        fxLayoutAlign="start center">
                                        <app-select placeholder="Housekeeping Status"
                                            [value]="cleaningFilterForm.get('cleaningStatus').value"
                                            (selectionChange)="cleaningFilterForm.get('cleaningStatus').setValue($event)"
                                            [selectOptions]="housekeepingStatusOpt">
                                        </app-select>
                                    </div>
                                    <div class="filter_section dropdownCustom" fxLayout="row"
                                        fxLayoutAlign="start center">
                                        <app-select placeholder="Sort by" [noEmptyValue]="true"
                                            [value]="cleaningFilterForm.get('sortBy').value"
                                            (selectionChange)="applyFilter($event.toString(), 'cleaningDataSource')"
                                            [selectOptions]="sortByOpt"></app-select>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="width_100" fxLayoutAlign="flex-end center">
                            <div class="sort-section" *ngIf="globalService.isMobile$.getValue()">
                                <div fxLayoutAlign="center center" (click)="openSortFilter()">
                                    <mat-icon [svgIcon]="'settings'"></mat-icon>
                                    Sort / Filter
                                </div>
                            </div>
                        </div>

                        <div *ngIf="cleaningList" class="cleaning_body_container width_100" fxLayout="column"
                            fxLayoutGap="30px" fxLayoutAlign="center center">
                            <div class="request_list width_100">
                                <mat-table [dataSource]="cleaningDataSource" matSort class="width_100">
                                    <ng-container matColumnDef="referenceId">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Reference ID
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="viewCleanRequest(element)">
                                            {{element.id}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="roomNumber">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Room
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="viewCleanRequest(element)">
                                            {{element.roomNumber}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="created">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Created
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="viewCleanRequest(element)">
                                            {{element.dateTime | date: 'dd/MM/yyyy'}} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="arrival">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Arrival
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="viewCleanRequest(element)">
                                            {{element.arrival | date: 'dd/MM/yyyy'}}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="departure">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Departure
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="viewCleanRequest(element)">
                                            {{element.departure | date: 'dd/MM/yyyy'}} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="cleaningStatus">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Request Type
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="viewCleanRequest(element)">
                                            <div *ngIf="element.cleaningStatus == 1" fxLayout="row"
                                                fxLayoutAlign="start center">
                                                <mat-icon><svg xmlns="http://www.w3.org/2000/svg" width="15px"
                                                        height="15px" viewBox="0 0 15 15"
                                                        style="margin-bottom: 4px !important;">
                                                        <rect id="Rectangle_481" data-name="Rectangle 481" width="15"
                                                            height="15" rx="2" fill="#38d9a9" />
                                                    </svg></mat-icon>
                                                <div>Clean my room</div>
                                            </div>
                                            <div *ngIf="element.cleaningStatus == 2" fxLayout="row"
                                                fxLayoutAlign="start center">
                                                <mat-icon><svg xmlns="http://www.w3.org/2000/svg" width="15px"
                                                        height="15px" viewBox="0 0 15 15"
                                                        style="margin-bottom: 4px !important;">
                                                        <path id="adblock"
                                                            d="M4.86,0a1.125,1.125,0,0,0-.8.329L.33,4.065A1.125,1.125,0,0,0,0,4.86v5.281a1.126,1.126,0,0,0,.33.8l3.734,3.734a1.125,1.125,0,0,0,.8.329h5.281a1.125,1.125,0,0,0,.8-.329l3.734-3.734a1.125,1.125,0,0,0,.33-.8V4.86a1.125,1.125,0,0,0-.329-.795L10.936.329a1.125,1.125,0,0,0-.8-.329ZM7.626,1.875c.637,0,.6.693.6.693v4.24s-.005.136.1.136.109-.141.109-.141V3.284a.6.6,0,1,1,1.205,0V8.045s.009.1.088.1a9.67,9.67,0,0,0,.59-1.326s.282-.936,1.61-.687a2.7,2.7,0,0,1-.1.43l-1.427,4.1a3.16,3.16,0,0,1-3.073,2.28c-3.153,0-3.005-3.38-3.009-3.378V4.726s-.01-.619.56-.619c.536,0,.53.619.53.619l0,2.24a.1.1,0,0,0,.1.107c.1,0,.088-.107.088-.107l.006-3.7s-.035-.726.6-.726.614.714.614.714V6.756s0,.128.1.128.1-.129.1-.129l.006-4.183S6.988,1.875,7.626,1.875Z"
                                                            fill="#e03131" />
                                                    </svg></mat-icon>
                                                <div>Do not Disturb</div>
                                            </div>
                                            <div *ngIf="element.cleaningStatus == 3" fxLayout="row"
                                                fxLayoutAlign="start center">
                                                <mat-icon><svg xmlns="http://www.w3.org/2000/svg" width="15px"
                                                        height="15px" viewBox="0 0 15 15"
                                                        style="margin-bottom: 4px !important;">
                                                        <path id="cancel"
                                                            d="M10.5,3A7.5,7.5,0,1,1,3,10.5,7.522,7.522,0,0,1,10.5,3m0,1.5A5.611,5.611,0,0,0,6.825,5.775l8.4,8.4A6.4,6.4,0,0,0,16.5,10.5a6.018,6.018,0,0,0-6-6m3.675,10.725-8.4-8.4A5.611,5.611,0,0,0,4.5,10.5a6.018,6.018,0,0,0,6,6,5.611,5.611,0,0,0,3.675-1.275Z"
                                                            transform="translate(-3 -3)" fill="#868e96" />
                                                    </svg></mat-icon>
                                                <div>Cancelled</div>
                                            </div>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="cleaningDateTime">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Housekeeping Date & Time
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <div
                                                fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
                                                {{element.cleaningDate | presentDateFormat}},
                                                {{element.cleaningTime}}
                                            </div>
                                            <!-- <div class="dnd_class" *ngIf="element.cleaningStatus == 2" fxLayout="row"
                                                fxLayoutGap="5px" fxLayoutAlign="start center">
                                                No details
                                            </div> -->
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="cleaningStatusSection">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header
                                            style="justify-content: flex-end;">
                                            Cleaning Status
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="viewCleanRequest(element)"
                                            style="justify-content: flex-end;">
                                            <div *ngIf="element.cleaningStatus == 1" class="request_status_open"
                                                fxLayout="row" fxLayoutAlign="center center">
                                                <div>Open</div>
                                            </div>
                                            <div *ngIf="element.cleaningStatus == 2" class="request_status_delivered"
                                                fxLayout="row" fxLayoutAlign="center center">
                                                <div>Cleaned</div>
                                            </div>
                                            <div *ngIf="element.cleaningStatus == 3" class="request_status_cancelled"
                                                fxLayout="row" fxLayoutAlign="center center">
                                                <div>Cancelled</div>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                    <!-- <ng-container matColumnDef="cleaningStatus">
                                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Status
                                                        </mat-header-cell>
                                                        <mat-cell *matCellDef="let element">
                                                            <div *ngIf="element.requestStatus == 1" fxLayout="row"
                                                                class="request_status_open" fxLayoutAlign="center center">
                                                                <div>Open</div>
                                                            </div>
                                                            <div *ngIf="element.requestStatus == 2" fxLayout="row"
                                                                class="request_status_delivered" fxLayoutAlign="center center">
                                                                <div>Cleaned</div>
                                                            </div>
                                                        </mat-cell>
                                                    </ng-container> -->

                                    <tr mat-header-row *matHeaderRowDef="cleaningDisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: cleaningDisplayedColumns;" 
                                    [ngClass]="{'highlightRow': row?.cleaningStatus == 1}"></tr>
                                </mat-table>
                                <mat-paginator #cleaningDataPaginator [pageSize]="10"
                                    [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <div fxLayoutAlign="start center" fxLayoutGap="5px">
                        <div id="amenities_request">Amenities Requests</div>
                        <div *ngIf="globalService.houseKeepingRequestCount > 0" fxLayoutAlign="center center"
                            class="request_count">{{globalService.houseKeepingRequestCount}}
                        </div>
                    </div>
                </ng-template>
                <div class="cleaning_detail_container">
                    <div fxFlex="100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start start">
                        <div class="cleaning_filter width_100" fxLayout="row" fxLayoutAlign="start center"
                            *ngIf="!globalService.isMobile$.getValue()">
                            <form fxLayout="row" fxLayoutAlign="space-between center" [formGroup]="requestFilterForm"
                                class="width_100">
                                <div class="filter_searchBox" fxFlex="40" fxLayout="row" fxLayoutAlign="start center">
                                    <div class="filter_section" fxLayout="row" fxLayoutAlign="start center">
                                        <mat-form-field class="filter_1" appearance="outline"
                                            fxLayoutAlign="start center">
                                            <mat-icon matPrefix>search</mat-icon>
                                            <input formControlName="requestRoomNumber" matInput
                                                placeholder="Request ID, room no." #input>
                                            <button type="button" class="search_button" (click)="resetFilter()"
                                                *ngIf="searchValue">
                                                <i class="material-icons">close</i>
                                            </button>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="filter_Box" fxFlex="60" fxLayout="row" fxLayoutGap="15px"
                                    fxLayoutAlign="end center">
                                    <div (click)="resetRequestFilter()" class="clear_filter_section cursor_pointer"
                                        fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="start center">
                                        <div>Clear filter</div>
                                        <mat-icon [svgIcon]="'refresh'"></mat-icon>
                                    </div>
                                    <div class="filter_section dropdownCustom" fxLayout="row"
                                        fxLayoutAlign="start center">
                                        <app-select placeholder="Status"
                                            [value]="requestFilterForm.get('requestStatus').value"
                                            (selectionChange)="requestFilterForm.get('requestStatus').setValue($event)"
                                            [selectOptions]="requestStatusOpt">
                                        </app-select>
                                    </div>
                                    <div class="filter_section dropdownCustom" fxLayout="row"
                                        fxLayoutAlign="start center">
                                        <app-select placeholder="Sort by" [noEmptyValue]="true"
                                            [value]="requestFilterForm.get('sortBy').value"
                                            (selectionChange)="applyFilter($event.toString(), 'requestDataSource')"
                                            [selectOptions]="sortByOpt"></app-select>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="width_100" fxLayoutAlign="flex-end center">
                            <div class="sort-section" *ngIf="globalService.isMobile$.getValue()">
                                <div fxLayoutAlign="center center" (click)="openSortFilter()">
                                    <mat-icon [svgIcon]="'settings'"></mat-icon>
                                    Sort / Filter
                                </div>
                            </div>
                        </div>

                        <div *ngIf="requestList" class="cleaning_body_container width_100" fxLayout="column"
                            fxLayoutGap="30px" fxLayoutAlign="center center">
                            <div class="request_list width_100">
                                <mat-table [dataSource]="requestDataSource" matSort class="width_100">

                                    <ng-container matColumnDef="requestId">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Request ID #
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="viewRequest(element)">
                                            {{element.id}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="referenceId">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Reference ID
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="viewRequest(element)">
                                            {{element.houseKeepingCallId}} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="roomNumber">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Room
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="viewRequest(element)">
                                            {{element.roomNumber}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="created">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Created
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="viewRequest(element)">
                                            {{element.dateTime | date: 'dd/MM/yyyy'}} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="arrival">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Arrival
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="viewRequest(element)">
                                            {{element.arrival | date: 'dd/MM/yyyy'}}</mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="departure">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Departure
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="viewRequest(element)">
                                            {{element.departure | date: 'dd/MM/yyyy'}} </mat-cell>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="requestType">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Requests
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <div (click)="viewRequest(element)" *ngIf="element.requestStatus == 1"
                                                fxLayout="row" class="request_status_new" fxLayoutAlign="center center">
                                                <div>New request</div>
                                            </div>
                                            <div *ngIf="element.requestStatus == 2" fxLayout="row"
                                                class="request_status_complete" fxLayoutAlign="center center">
                                                <div>New request</div>
                                            </div>
                                            <div *ngIf="element.requestStatus == 3" fxLayout="row"
                                                class="request_status_complete" fxLayoutAlign="center center">
                                                <div>New request</div>
                                            </div>
                                        </mat-cell>
                                    </ng-container> -->

                                    <ng-container matColumnDef="requestStatus">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Request Status
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <div *ngIf="element.requestStatus == 1" fxLayout="row"
                                                class="request_status_open" fxLayoutAlign="center center">
                                                <div>Open</div>
                                            </div>
                                            <div *ngIf="element.requestStatus == 2" fxLayout="row"
                                                class="request_status_delivered" fxLayoutAlign="center center">
                                                <div>Delivered</div>
                                            </div>
                                            <div *ngIf="element.requestStatus == 3" fxLayout="row"
                                                class="request_status_cancelled" fxLayoutAlign="center center">
                                                <div>Cancelled</div>
                                            </div>
                                        </mat-cell>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="requestDisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: requestDisplayedColumns;"
                                        [ngClass]="{'highlightRow': row?.requestStatus === 1}"></tr>
                                </mat-table>
                                <mat-paginator #requestDataPaginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"
                                    showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>