<div class="header-page" fxLayout="row" fxFlex="100" fxLayoutAlign="start center">
    <div class="container" fxLayout="row" fxFlex="100" fxLayoutGap="30px" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutGap="10px" fxFlex="60" fxLayoutAlign="start center">
            <div *ngIf="headerData && headerData.showBackButton" (click)="navigateDashboard()" class="back_container"
                fxLayoutAlign="center center">
                <mat-icon [svgIcon]="'arrow-back'"></mat-icon>
            </div>
            <div class="burger-icon" *ngIf="globalService.isMobile$ | async">
                <mat-icon [svgIcon]="'burger'" (click)="showSidebar()"></mat-icon>
            </div>
            <div *ngIf="headerData && headerData.headerTitle" class="dashboard_title">
                {{headerData.headerTitle}}
                <div *ngIf="headerData && headerData.info1 && globalService.isMobile$ | async">
                    <div class="dashboard_title_3">{{headerData.info1}}</div>
                </div>
            </div>
            <div *ngIf="headerData && headerData.info1" fxLayoutGap="5px"
                [class.chat-page]="router.url.includes('pages/chat')" fxLayout="column" fxFlex="85"
                fxLayoutAlign="start start">
                <div class="dashboard_title_1">{{headerData.info1}}</div>
                <div *ngIf="headerData && headerData.info2" class="dashboard_title_2">{{headerData.info2}}</div>
                <div *ngIf="headerData && headerData.info3" class="dashboard_title_3">{{headerData.info3}}</div>
            </div>
        </div>
        <div fxLayout="row" fxFlex="60" fxLayoutGap="20px" fxLayoutAlign="end center">
            <div class="notification_section cursor_pointer" fxLayoutAlign="center">
                <mat-icon (click)="isOrdersPage ? onCartClick() : ''"
                    [svgIcon]="isOrdersPage ? 'cart-add' : 'bell'"></mat-icon>
                <div class="notification_counter" [class.hidden_counter]="!isOrdersPage" #notificationCounter
                    [class.active]="orderService.orderCount$.getValue()">{{orderService.orderCount$.getValue()}}</div>
            </div>
            <div class="navigation_option cursor_pointer" [class.visibility_hidden]="isOrdersPage"
                fxLayoutAlign="center"> 
                <button class="userProfile" mat-button [matMenuTriggerFor]="menu" (menuOpened)="menuOpened()"  (menuClosed)="menuOpened()">
                    <mat-icon [svgIcon]="'user-circle-outline'"></mat-icon>
                    <!-- <mat-icon *ngIf="isMenuOpen==false" style="color: #228BE6;">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="isMenuOpen" color="#228BE6" style="color: #228BE6;">keyboard_arrow_up</mat-icon> -->
                    <mat-icon *ngIf="isMenuOpen==false" style="width: 16px;margin-left: 8px;" [svgIcon]="'keyboard-arrow-down'"></mat-icon>
                    <mat-icon *ngIf="isMenuOpen" style="width: 16px;margin-left: 8px; transform: rotate(180deg);" [svgIcon]="'keyboard-arrow-down'"></mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item><mat-icon><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                            viewBox="0 0 15 15">
                            <path id="user-outline"
                                d="M13.5,6A3.75,3.75,0,1,1,9.75,9.75,3.75,3.75,0,0,1,13.5,6m0,1.875A1.875,1.875,0,1,0,15.375,9.75,1.875,1.875,0,0,0,13.5,7.875m0,6.562c2.5,0,7.5,1.247,7.5,3.75V21H6V18.187c0-2.5,5-3.75,7.5-3.75m0,1.781c-2.784,0-5.719,1.369-5.719,1.969v1.031H19.219V18.187C19.219,17.587,16.284,16.219,13.5,16.219Z"
                                transform="translate(-6 -6)" fill="#495057" />
                        </svg></mat-icon>Profile</button>
                    <button mat-menu-item><mat-icon><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                        <path id="settings-outline" d="M10.906,7.5a3,3,0,1,1-3.084,3,3.043,3.043,0,0,1,3.084-3m0,1.5a1.5,1.5,0,1,0,1.542,1.5A1.521,1.521,0,0,0,10.906,9M9.365,18a.387.387,0,0,1-.385-.315L8.694,15.7a5.39,5.39,0,0,1-1.3-.743l-1.919.757A.393.393,0,0,1,5,15.547L3.46,12.953a.363.363,0,0,1,.093-.48l1.627-1.245L5.125,10.5l.054-.75L3.552,8.528a.363.363,0,0,1-.093-.48L5,5.453a.384.384,0,0,1,.47-.165l1.919.75a5.627,5.627,0,0,1,1.3-.735l.285-1.987A.387.387,0,0,1,9.365,3h3.084a.387.387,0,0,1,.385.315L13.119,5.3a5.627,5.627,0,0,1,1.3.735l1.919-.75a.384.384,0,0,1,.47.165l1.542,2.6a.363.363,0,0,1-.093.48L16.634,9.75l.054.75-.054.75,1.627,1.222a.363.363,0,0,1,.093.48l-1.542,2.595a.384.384,0,0,1-.47.165l-1.919-.75a5.627,5.627,0,0,1-1.3.735l-.285,1.987a.387.387,0,0,1-.385.315H9.365m.964-13.5-.285,1.957A4.259,4.259,0,0,0,7.707,7.793l-1.858-.78-.578.975L6.9,9.15a4.056,4.056,0,0,0,0,2.7L5.263,13.02,5.842,14l1.873-.78a4.222,4.222,0,0,0,2.32,1.32L10.32,16.5h1.172l.285-1.957a4.279,4.279,0,0,0,2.32-1.328l1.873.78.578-.975-1.634-1.163a4.078,4.078,0,0,0,0-2.707l1.627-1.163-.578-.975-1.858.78A4.2,4.2,0,0,0,11.77,6.465L11.484,4.5Z" transform="translate(-3.406 -3)" fill="#495057"/>
                      </svg></mat-icon>Settings</button>
                      <mat-divider></mat-divider>
                    <button mat-menu-item><mat-icon><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                viewBox="0 0 15 15">
                                <path id="question-mark-circle-outline"
                                    d="M9.75,15h1.5V13.5H9.75V15M10.5,3A7.5,7.5,0,1,0,18,10.5,7.5,7.5,0,0,0,10.5,3m0,13.5a6,6,0,1,1,6-6,6.008,6.008,0,0,1-6,6M10.5,6a3,3,0,0,0-3,3H9a1.5,1.5,0,0,1,3,0c0,1.5-2.25,1.313-2.25,3.75h1.5c0-1.687,2.25-1.875,2.25-3.75A3,3,0,0,0,10.5,6Z"
                                    transform="translate(-3 -3)" fill="#495057" />
                            </svg></mat-icon> Help Center</button>
                            <mat-divider></mat-divider>
                    <button mat-menu-item (click)="routeToLogin()"><mat-icon><svg xmlns="http://www.w3.org/2000/svg" width="15"
                            height="15" viewBox="0 0 15 15">
                            <path id="log-out-variant"
                                d="M13.733,14.992l2.158-2.158H7.833V11.167h8.058L13.733,9.008l1.183-1.175L19.083,12l-4.167,4.167-1.183-1.175M17.833,4.5A1.667,1.667,0,0,1,19.5,6.167v3.892L17.833,8.392V6.167H6.167V17.833H17.833V15.608L19.5,13.942v3.892A1.667,1.667,0,0,1,17.833,19.5H6.167A1.667,1.667,0,0,1,4.5,17.833V6.167A1.661,1.661,0,0,1,6.167,4.5Z"
                                transform="translate(-4.5 -4.5)" fill="#d9480f" />
                        </svg></mat-icon> Logout</button>
                </mat-menu>
            </div>
        </div>
    </div>
</div>