<div id="chatPage" class="width_100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center">
    <div class="chat_body width_100" fxLayout="row">
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start">
            <app-chat-side
                    *ngIf="isMobileDisplay ? !selectedChat : true"
                    [fxFlex]="isMobileDisplay? 100: 30"
                    (selectRoom)="openChat($event)"
                    [guestList]="guestList"
                    [selectedChat]="selectedChat">
            </app-chat-side>
            <app-messages-side
                    [fxFlex]="isMobileDisplay? selectedChat ? 100 : 0 : 70"
                    [data]="data"
                    *ngIf="isMobileDisplay ? selectedChat : true"
                    (closeMessaging)="openChat(null)"
                    [selectedChat]="selectedChat"
                    [guestList]="guestList">
            </app-messages-side>
        </div>
    </div>
</div>
