<div class="chat_user_section" fxLayout="column" fxLayoutAlign="start center">
  <div class="chat_user_list width_100" fxLayout="column" fxLayoutAlign="start center">
    <div class="chat_user_option width_100" fxLayoutGap="20px" fxFlex="100" fxLayout="column"
      fxLayoutAlign="start center">
      <div class="width_100" fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field class="width_100" appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <input matInput [(ngModel)]="search" placeholder="Search message">
        </mat-form-field>
      </div>
      <div (click)="showNewUsers()" class="new_message_button width_100" fxLayoutGap="5px" fxLayout="row"
        fxLayoutAlign="center center">
        <mat-icon>create</mat-icon>
        <div>New message</div>
      </div>
    </div>
  </div>
  <div class="chat_user_tabs width_100" fxLayoutGap="20px" fxFlex="100" fxLayout="column" fxLayoutAlign="start center">
    <div class="width_100" fxLayout="row" fxLayoutAlign="start center">
      <mat-tab-group fxFlex="100">
        <mat-tab>
          <ng-template mat-tab-label>
            <div fxLayoutAlign="start center" fxLayoutGap="5px">
              <div id="open_chat">Open</div>
            </div>
          </ng-template>
          <div [ngClass]="[selectedChat == room.roomname ? 'active' : '', room.status == 'open' ? '' : 'hide_chat']"
            (click)="openChat(room)" *ngFor="let room of rooms | FilterByArray:'guestname':search"
            class="chat_user_tab_list" fxLayoutGap="10px" fxLayout="column" fxLayoutAlign="center center">
            <div class="width_100" fxLayout="row" fxLayoutAlign="space-between center">
              <div fxFlex="78" class="chat_user_name" [ngClass]="room.unreadAdminMessages > 0 ? 'font_weight_500' : ''">
                {{room.guestname}}
              </div>
              <div *ngIf="room.chats && room.chats.length > 0" fxFlex="22" fxLayoutAlign="end center"
                class="chat_user_time">
                {{ room.chats[room.chats.length - 1].date == 'Today'
                ? room.chats[room.chats.length - 1].time
                : getDotFormat(room.chats[room.chats.length - 1].date) }}
              </div>
            </div>
            <div class="width_100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
              <div fxFlex="90" *ngIf="room.chats && room.chats.length > 0" class="chat_user_last_msg" fxLayout="row"
                fxLayoutAlign="start center">
                {{room.chats[room.chats.length - 1].message}}
              </div>
              <div fxFlex="10" *ngIf="room.unreadAdminMessages > 0" fxLayout="row" fxLayoutAlign="end center">
                <div [ngClass]="room.unreadAdminMessages > 9 ? 'unread_message_1' : 'unread_message_2'">
                  {{room.unreadAdminMessages}}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="openChatCount == 0" class="no_chats_found">No open chats found</div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <div fxLayoutAlign="start center" fxLayoutGap="5px">
              <div id="closed_chat">Closed</div>
            </div>
          </ng-template>
          <div [ngClass]="[selectedChat == room.roomname ? 'active' : '', room.status == 'close' ? '' : 'hide_chat']"
            (click)="openChat(room)" *ngFor="let room of rooms | FilterByArray:'guestname':search"
            class="chat_user_tab_list" fxLayoutGap="10px" fxLayout="column" fxLayoutAlign="center center">
            <div class="width_100" fxLayout="row" fxLayoutAlign="space-between center">
              <div fxFlex="78" class="chat_user_name" [ngClass]="room.unreadAdminMessages > 0 ? 'font_weight_500' : ''">
                {{room.guestname}}
              </div>
              <div *ngIf="room.chats && room.chats.length > 0" fxFlex="22" fxLayoutAlign="end center"
                class="chat_user_time">
                {{
                room.chats[room.chats.length - 1].date == 'Today'
                ? room.chats[room.chats.length - 1].time
                : getDotFormat(room.chats[room.chats.length - 1].date)
                }}
              </div>
            </div>
            <div class="width_100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
              <div fxFlex="90" *ngIf="room.chats && room.chats.length > 0" class="chat_user_last_msg" fxLayout="row"
                fxLayoutAlign="start center">
                {{room.chats[room.chats.length - 1].message}}
              </div>
              <div fxFlex="10" *ngIf="room.unreadAdminMessages > 0" fxLayout="row" fxLayoutAlign="end center">
                <div [ngClass]="room.unreadAdminMessages > 9 ? 'unread_message_1' : 'unread_message_2'">
                  {{room.unreadAdminMessages}}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="closeChatCount == 0" class="no_chats_found">No closed chats found</div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>