<div class="add-payment">
    <div class="add-payment__header" fxLayout="column" fxLayoutAlign="center flex-start">
        <div class="add-payment__header-title">
            ADD PAYMENT
        </div>
        <div class="add-payment__header-subtitle">
            Booking ID: {{this.bookingNumber}}
        </div>
        <div class="add-payment__header-close">
            <mat-icon class="close-icon" [svgIcon]="'close'" (click)="closePopup()"></mat-icon>
        </div>
    </div>
    <form [formGroup]="paymentForm" *ngIf="paymentForm" class="add-payment__main " [class.iphone-container]="isiOS"
        fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start center">
        <div class="width_100 add-payment__main-orders" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-label>Invoice</mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput formControlName="invoiceNumber">
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-label>Check-in/Out Dates</mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput formControlName="checkIn">
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-label>Billed To</mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput formControlName="billedTo">
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-label>Amount Due</mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput [suffix]="' '+ globalService.currency" mask="separator.2" thousandSeparator="" separatorLimit="99999"
                        formControlName="amount">
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <app-select class="filter_2 dropdownCustom" placeholder="Select" [label]="'Payment Method'"
                    [customControl]="paymentForm.get('paymentMethod')" [noEmptyValue]="true"
                    [value]="paymentForm.get('paymentMethod').value"
                    (selectionChange)="paymentForm.get('paymentMethod').setValue($event)"
                    [selectOptions]="[{label: 'Direct Billing', value: 'billing'}, {label: 'Cash', value: 'cash'}]"></app-select>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-label>Amount received</mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput [suffix]="' '+globalService.currency" mask="separator.2" thousandSeparator="" separatorLimit="99999"
                        formControlName="amountReceived" (ngModelChange)="calculateChange($event)">
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="add-payment__summary" fxLayout="column" fxLayoutAlign="center center">
        <div class="add-payment__summary-actions" fxLayoutAlign="center center">
            <div mat-ripple class="add-payment__summary-clear" (click)="closePopup()">
                Cancel
            </div>
            <div mat-ripple class="add-payment__summary-show" [class.disabled]="!paymentForm.get('paymentMethod').value"
                (click)="addPayment()">
                Add Payment
            </div>
        </div>
    </div>
</div>