import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

const createProxy = (context: ApplyQueryComponent): any => {
  return new Proxy({}, {
    set(target: any, prop, value) {
      target[prop] = value;
      context.setQueryParam();
      context.filterUpdated.next(target);
      return true;
    },
    get(target, key){
      return target[key];
    }
  });
};

export abstract class ApplyQueryComponent {
  public filterUpdated: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public filters = createProxy(this);

  protected constructor(public route: ActivatedRoute, public router: Router) {
    this.route.queryParams.subscribe(params => {
      Object.keys(params).forEach(item => this.filters[item] = params[item]);
    });
  }

  public setQueryParam(): void {
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: this.filters });
  }
}
