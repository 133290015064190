import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SpaWellnessComponent } from './spa-wellness.component';
import { SpaDetailsComponent } from './spa-details/spa-details.component';

const routes: Routes = [
    {
        path: '',
        component: SpaWellnessComponent,
    },
    {
        path: 'spa-details',
        component: SpaDetailsComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class SpaWellnessRoutingModule {}
