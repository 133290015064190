import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SnackBarComponent } from '../../../shared/components/snack-bar/snack-bar.component';

import { GlobalServices } from '../../../shared/services/global.services';
import { RestfullServices } from 'src/app/shared/services/restfull.services';
import { GuestListModel } from 'src/app/shared/models/shared.model';
import { element } from 'protractor';

@Component({
    selector: 'app-share-link',
    templateUrl: './share-link.component.html',
    styleUrls: ['./share-link.component.scss']
})
export class ShareLinkComponent implements OnInit {

    public guestsArr = [
        'Primary', '2nd', '3rd', '4th',
    ];
    public datumState: string;

    public checkboxConfig = [{checked:false,disabled:false}];

    constructor(public globalService: GlobalServices,
        private route: Router,
        private snackBar: MatSnackBar,
        private restfullServices: RestfullServices,
        public dialogRef: MatDialogRef<ShareLinkComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    public get isActive() {
        return this.checkboxConfig.filter(item => !!item.checked).length === this.checkboxConfig.length;
    }

    ngOnInit(): void {
        this.guestsArr.forEach(() => this.checkboxConfig.push({checked:false,disabled:false}));
        // if (this.data.guests.length != +this.data.guests[0].adults) {
        //     var count = +this.data.guests[0].adults - this.data.guests.length;
        //     for (var i = 0; i < count; i++) {
        //         this.data.guests.push({ names: 'Last Name, First Name', email: 'test@gmail.com' })
        //     }
        // }
        //console.log(this.data.guests.length, +this.data.guests[0].adults);
        this.data.guests.forEach((element, index) => {
            if (element.appActivity == "active" || element.appActivity == "pending") {
                this.checkboxConfig[index].checked = true;
                this.checkboxConfig[index].disabled = true;
            }
        })
    }

    public closePopup() {
        this.dialogRef.close();
    }

    public sendLink() {
        //this.dialogRef.close(true);
        // this.snackBar.openFromComponent(SnackBarComponent, {
        //     panelClass: ['custom-snackbar'],
        //     horizontalPosition: 'right',
        //     verticalPosition: 'top',
        //     data: {
        //         svgIcon: 'check-circle',
        //         message: 'Guest App Link has been sent!'
        //     }
        // });
        // console.log('this.data', this.data);
        // console.log('this.data.guests', this.data.guests);
        // console.log("Checkbox-config", this.checkboxConfig);
        var selectedGuestList = [];
        this.checkboxConfig.forEach((element, index) => {
            if (element.checked == true && !element.disabled) {
                this.data.guests[index].ind = index;
                selectedGuestList.push(this.data.guests[index]);
            }
        });
        console.log("selectedList", selectedGuestList);
        if (selectedGuestList.length != 0) {
            this.restfullServices.sendLink(selectedGuestList).subscribe(response => {
                if (response.status) {
                    console.log(response);
                    this.snackBar.openFromComponent(SnackBarComponent, {
                        panelClass: ['custom-snackbar'],
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                        data: {
                            svgIcon: 'check-circle',
                            message: 'Guest App Link has been sent!'
                        }
                    });
                    const queryParams: any = {
                        id: this.globalService.selectedGuestId,
                    };
                    queryParams.shareLink = true;
                    this.dialogRef.close();
                    this.route.navigate(['/pages/guests/guest'], { queryParams });
                    // .then(() => {
                    //     window.location.reload();
                    //   });
                }
            }, (err) => {
                this.globalService.showAlert("Error")
            });
        }
        else{
            const queryParams: any = {
                id: this.globalService.selectedGuestId,
            };
            queryParams.shareLink = true;
                    this.dialogRef.close();
                    this.route.navigate(['/pages/guests/guest'], { queryParams });
        }
    }
    public openGuestDetail(guestData: GuestListModel, shareLink?: boolean) {
        this.globalService.selectedGuestId = guestData.id;
        this.globalService.selectedGuestName = guestData.lastName + ', ' + guestData.firstName;

        const queryParams: any = {
            id: guestData.id,
        };
        if (shareLink) {
            queryParams.shareLink = true;
        }
        this.route.navigate(['/pages/guests/guest'], { queryParams });
    }
    setCheckBoxState(i: number) {
        this.checkboxConfig[i].checked = !this.checkboxConfig[i].checked;
    }

    public setAllState() {
        this.checkboxConfig = this.checkboxConfig.map(element =>{ const config = {checked:false,disabled:false};
        config.checked = !this.isActive;
        config.disabled=element.disabled;
        return config;
    });
    }
    public inputChange(i)
    {
        console.log(i);
        this.data.guests[i].appActivity = "manage"
        // var disableCheck = <HTMLInputElement>document.getElementById("checkId"+i);
        // disableCheck.disabled=false;
        this.checkboxConfig[i].disabled = false;
    }
}
