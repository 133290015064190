import { Component, OnInit } from '@angular/core';

import { GlobalServices } from '../../shared/services/global.services';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

    constructor(private globalService: GlobalServices) {
    }

    ngOnInit(): void {
        this.globalService.headerData$.next({
            headerTitle: 'Calendar',
            showBackButton: false
        });
    }

}
