import { SortFilterConfigModel } from '../../shared/models/shared.model';

export const sortByOpt = [
    {
        label: 'Sort by',
        value: 0
    },
    {
        label: 'Most Recent',
        value: 1
    },
    {
        label: 'Oldest',
        value: 2
    },
];

export const housekeepingStatusOpt = [
    {
        label: 'Clean my room',
        value: 1,
        svgIcon: 'green'
    },
    {
        label: 'Do not Disturb',
        value: 2,
        svgIcon: 'block'
    },
    {
        label: 'Cancel',
        value: 3,
        svgIcon: 'cancel'
    },
];

export const sortFilterConfig: SortFilterConfigModel[] = [
    {
        label: 'Datum',
        mode: 'radio',
        items: [
            {
                label: 'Most Recent',
                checked: false,
            },
            {
                label: 'Oldest',
                checked: false
            }
        ]
    },
    {
        label: 'Housekeeping Status',
        mode: 'checkbox',
        items: [
            {
                label: 'Clean my room',
                checked: false,
                icon: 'green'
            },
            {
                label: 'Do not disturb',
                checked: false,
                icon: 'block'
            },
        ]
    },
];

export const requestStatusOpt = [
    {
        label: 'Open',
        value: 1,
    },
    {
        label: 'Delivered',
        value: 2,
    },
];

export const cleaningDisplayedColumns = ['referenceId','roomNumber','created', 'arrival', 'departure', 'cleaningStatus', 'cleaningDateTime','cleaningStatusSection'];
export const requestDisplayedColumns = ['referenceId','requestId', 'roomNumber', 'created', 'arrival', 'departure', 'requestStatus'];
