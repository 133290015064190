import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SearchUserDialogModel, ChatGuestListModel } from '../../../shared/models/shared.model';

@Component({
    selector: 'app-search-user',
    templateUrl: './search-user.component.html',
    styleUrls: ['./search-user.component.scss']
})
export class SearchUserComponent implements OnInit {
    guestList: ChatGuestListModel[];

    constructor(
        public dialogRef: MatDialogRef<SearchUserComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SearchUserDialogModel
    ) {
    }

    ngOnInit(): void {
        this.guestList = this.data.guestList;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    searchUser(guestDetails: string) {
        this.guestList = this.data.guestList.filter(key => {
            return ((key.lastName.toLowerCase()).includes(guestDetails.toLowerCase())
                || (key.firstName.toLowerCase()).includes(guestDetails.toLowerCase())
                || (key.bookingNumber.toLowerCase()).includes(guestDetails.toLowerCase()));
        });
    }

}
