import { Component, Input, forwardRef, EventEmitter, Output } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { SelectOptionsModel } from '../../models/shared.model';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true
        }
    ]
})
export class SelectComponent implements ControlValueAccessor {

    constructor() {
    }


    @Input() set value(val) {
        this.val = val;
        this.onChange(val);
        this.onTouch(val);
    }

    @Input() customControl: AbstractControl;
    @Input() placeholder = 'Select';
    @Input() noEmptyValue = false;
    @Input() styleClass: string;
    @Input() label: string;
    @Input() selectOptions: SelectOptionsModel[];
    @Input() labelField = 'label';
    @Input() valueField = 'value';

    @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();

    public val = '';

    onChange: any = () => {
    }
    onTouch: any = () => {
    }



    writeValue(value: any) {
        this.value = value;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }
}


