import { Component, Input } from '@angular/core';

import { ChatGuestListModel, ChatLineModel } from '../../../shared/models/shared.model';

@Component({
    selector: 'app-chat-user-info',
    templateUrl: './chat-user-info.component.html',
    styleUrls: ['./chat-user-info.component.scss']
})
export class ChatUserInfoComponent  {

    @Input() public selectedGuest: ChatGuestListModel;

    public lines: ChatLineModel[] = [
        {
            name: 'Booking ID',
            method: 'bookingNumber',
        },
        {
            name: 'Check-In Date',
            method: 'checkInDate',
            pipes: [
                {
                    name: 'date',
                    transformer: 'dd/MM/yyyy'
                },
            ]
        },
        {
            name: 'Check-Out Date',
            method: 'checkOutDate',
            pipes: [
                {
                    name: 'date',
                    transformer: 'dd/MM/yyyy'
                },
            ]
        },
        {
            name: 'Room Type',
            method: 'roomType',
        },
        {
            name: 'No.',
            method: 'roomNumber',
        },
        {
            name: 'Cancellation Policy',
            method: 'cancellationPolicy',
        },
        {
            name: 'Board',
            method: 'board',
        },
        {
            name: 'Adults',
            method: 'adults',
        },
        {
            name: 'Children (0 - 17)',
            method: 'children',
        }
    ];

    constructor() {}
}
