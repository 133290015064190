
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removeWhiteSpace'
})
export class RemoveWhiteSpacePipe implements PipeTransform {

    transform(value: any): any {
        if (value === undefined)
            return 'undefined';
        else if (value === null)
            return null;
        else if (value === "")
            return "";
        return value.replace(/\s/g, "");
    }

}