import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material.module';

import { LoginComponent } from './login/login.component';

@NgModule({
    declarations: [
        LoginComponent,
    ],
    imports: [
        SharedModule,
        CommonModule,
        FormsModule,
        MaterialModule
    ],
    exports: [
        LoginComponent
    ],
})
export class AuthModule { }
