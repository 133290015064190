import { Component, OnInit } from '@angular/core';

import { GlobalServices } from '../../shared/services/global.services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  constructor(private globalService: GlobalServices) { }

  ngOnInit() {
    this.globalService?.headerData$.next({
      headerTitle: 'Dashboard',
      showBackButton: false
    });
  }

}
