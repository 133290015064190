<div class="add-payment">
    <div class="add-payment__header" fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="!isEdit" class="add-payment__header-title" style="margin-right: auto;" fxLayout="column" fxLayoutAlign="start center">
            <div>NEW WAKE-UP CALL</div>
        </div>
        <div *ngIf="isEdit" class="add-payment__header-title" style="margin-right: auto;" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
            <div>{{requestDetails.guestName}}</div>
            <div class="add-payment__header-header_room">Booking No. {{requestDetails.bookingId}}</div>
        </div>
        <div fxLayout="column">
            <div class="filter_section dropdownCustom" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
                <mat-label style="color: black;">Status</mat-label>
                <app-select class="filter_3 width_60" [noEmptyValue]="true" [value]="requestStatus"
                    (selectionChange)="requestStatus = $event" [selectOptions]="selectOptions"></app-select>
            </div>
        </div>
    </div>
    <form [formGroup]="wakeupCallForm" class="add-payment__main " fxLayout="column" fxLayoutGap="20px"
        fxLayoutAlign="start center">
        <div class="width_100 add-payment__main-orders" fxLayoutGap="20px" fxLayout="column"
            fxLayoutAlign="start center">
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" style="width: inherit;">
                <div fxLayout="column" fxFlex="75%" fxLayoutAlign="start start" id="create_wakeup_call">
                    <mat-label>Date</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <mat-datepicker-toggle matPrefix [for]="date"></mat-datepicker-toggle>
                        <input formControlName="date" matInput [matDatepicker]="date">
                        <mat-datepicker #date></mat-datepicker>
                    </mat-form-field>
                    <div *ngIf="wakeupCallForm.controls['date'].invalid && (wakeupCallForm.controls['date'].dirty || wakeupCallForm.controls['date'].touched)"
                        class="alert">
                        <mat-error *ngIf="(wakeupCallForm.get('date')).errors?.required">Date is
                            required
                        </mat-error>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="25%" style="margin-right: 20px;" fxLayoutAlign="start start">
                    <mat-label>Time</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <div matPrefix><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                viewBox="0 0 12 12" style="margin: 2px 5px 4px 2px;">
                                <path id="clock-outline"
                                    d="M9,13.8A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8M9,3A6,6,0,1,1,3,9,6,6,0,0,1,9,3m.3,3V9.15l2.7,1.6-.45.738L8.4,9.6V6Z"
                                    transform="translate(-3 -3)" fill="#868e96" />
                            </svg> </div>
                        <input matInput type="time" onfocus="this.showPicker()" formControlName="time">
                    </mat-form-field>
                    <div *ngIf="wakeupCallForm.controls['time'].invalid && (wakeupCallForm.controls['time'].dirty || wakeupCallForm.controls['time'].touched)"
                        class="alert">
                        <mat-error *ngIf="(wakeupCallForm.get('time')).errors?.required">Time is
                            required
                        </mat-error>
                    </div>
                </div>
            </div>
            <div *ngIf="!isEdit" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" style="width: inherit;">
                <div fxLayout="column" fxFlex="75%" fxLayoutAlign="start start">
                    <mat-label>Guest Name</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input type="text" aria-label="Number" matInput formControlName="guestName"
                            [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let guest of filteredUser | async" [value]="guest">
                                {{guest.guestName}}, {{guest.bookingNumber}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <div *ngIf="wakeupCallForm.controls['guestName'].invalid && (wakeupCallForm.controls['guestName'].dirty || wakeupCallForm.controls['guestName'].touched)"
                        class="alert">
                        <mat-error *ngIf="(wakeupCallForm.get('guestName')).errors?.required">Guest name is
                            required
                        </mat-error>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="25%" fxLayoutAlign="start start">
                    <mat-label>Room</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="room">
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" class="note width_100" fxLayoutAlign="start start">
                <div fxLayoutGap="5px" class="width_100 remarks" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Note</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <textarea matInput formControlName="note"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
    <div class="add-payment__summary" fxLayout="column" fxLayoutAlign="end end">
        <div class="add-payment__summary-actions" fxLayoutAlign="center center">
            <div mat-ripple class="add-payment__summary-clear" (click)="onNoClick()">
                Cancel
            </div>
            <div mat-ripple class="add-payment__summary-show" (click)="saveWakeUp()">
                Save
            </div>
        </div>
    </div>
</div>