import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'presentDateFormat'
})
export class PresentDateFormatPipe implements PipeTransform {
    transform(value) {

        const today = new Date()
        const tomorrow = new Date(today.setDate(today.getDate()));
        value = new Date(value);
        tomorrow.setDate(tomorrow.getDate() + 1)

        if (value.getFullYear() == today.getFullYear() && value.getMonth() == today.getMonth() && value.getDate() == today.getDate())
            return "Today";
        else if (value.getFullYear() == tomorrow.getFullYear() && value.getMonth() == tomorrow.getMonth() && value.getDate() == tomorrow.getDate())
            return "Tomorrow";
        else {
            return (new DatePipe("en-US")).transform(value, 'dd/MM/yyyy');
        }
    }
}