import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../../shared/material.module';
import { LayoutModule } from '../../layout-module/layout-module.module';
import { SharedModule } from '../../shared/shared.module';

import { SpaWellnessComponent } from './spa-wellness.component';
import { SpaWellnessRoutingModule } from './spa-wellness-routing.module';
import { PagesModule } from '../pages.module';
import { NgxMaskModule } from 'ngx-mask';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AddEventHandlerComponent } from './add-event-handler/add-event-handler.component';
import { SpaDetailsComponent } from './spa-details/spa-details.component';

@NgModule({
    declarations: [
        SpaWellnessComponent,
        SpaDetailsComponent,
        AddEventHandlerComponent
    ],
    imports: [
        SpaWellnessRoutingModule,
        SharedModule,
        MaterialModule,
        LayoutModule,
        CommonModule,
        FlexLayoutModule,
        PagesModule,
        FullCalendarModule,
        NgxMaskModule
    ]
})
export class SpaWellnessModule { }
