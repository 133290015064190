import { SelectOptionsModel } from '../../../shared/models/shared.model';

export const roomNumberOpt: SelectOptionsModel[] = [
    {
        label: '1001',
        value: '1001'
    },
    {
        label: '1002',
        value: '1002'
    },
    {
        label: '1003',
        value: '1003'
    },
    {
        label: '1004',
        value: '1004'
    },
    {
        label: '1005',
        value: '1005'
    },
];

export const boardOpt: SelectOptionsModel[] = [
    {
        label: 'Breakfast',
        value: 'Breakfast'
    },
    {
        label: 'Half board',
        value: 'Half board'
    },
    {
        label: 'Full board',
        value: 'Full board'
    },
    {
        label: 'All inclusive',
        value: 'All inclusive'
    },
    {
        label: 'Fully Serviced Apartment',
        value: 'Fully Serviced Apartment'
    }
];

export const genderOpt: SelectOptionsModel[] = [
    {
        label: 'Male',
        value: 'Male'
    },
    {
        label: 'Female',
        value: 'Female'
    }
];






export const cancellationPolicyOpt: SelectOptionsModel[] = [
    {
        label: 'Non-refundable',
        value: 'Non-refundable'
    },
    {
        label: 'Refundable',
        value: 'Refundable'
    }
];

export const roomTypeOpt: SelectOptionsModel[] = [
    {
        label: 'Deluxe King Room',
        value: 'Deluxe King Room'
    },
    {
        label: 'Junior Suite',
        value: 'Junior Suite'
    },
    {
        label: 'Family Room',
        value: 'Family Room'
    },
    {
        label: 'Executive Double Room',
        value: 'Executive Double Room'
    },
];
