import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../../shared/material.module';
import { LayoutModule } from '../../layout-module/layout-module.module';
import { SharedModule } from '../../shared/shared.module';

import { FoodBeveragesComponent } from './food-beverages.component';
import { FoodBeveragesRoutingModule } from './food-beverages-routing.module';
import { PagesModule } from '../pages.module';
import { OrdersComponent } from './orders/orders.component';
import { AddOrderComponent } from './add-order/add-order.component';
import { ConfirmOrderComponent } from './confirm-order/confirm-order.component';
import { AddPaymentComponent } from './add-payment/add-payment.component';
import { NgxMaskModule } from 'ngx-mask';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { AddReservationComponent } from './add-reservation/add-reservation.component';

@NgModule({
    declarations: [
        FoodBeveragesComponent,
        OrdersComponent,
        AddOrderComponent,
        ConfirmOrderComponent,
        AddPaymentComponent,
        OrderDetailsComponent,
        AddReservationComponent
    ],
    imports: [
        SharedModule,
        FoodBeveragesRoutingModule,
        MaterialModule,
        LayoutModule,
        CommonModule,
        FlexLayoutModule,
        PagesModule,
        NgxMaskModule
    ]
})
export class FoodBeveragesModule { }
