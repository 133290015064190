import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";

import { ActivatedRoute, Router } from "@angular/router";

import { filter, takeUntil } from "rxjs/operators";

import { ConfirmOrderComponent } from "../confirm-order/confirm-order.component";
import { ApplyQueryComponent } from "../../../shared/functions/apply-query.component";
import { AlertDialogComponent } from "../../../shared/components/alert-dialog/alert-dialog.component";
import { SnackBarComponent } from "../../../shared/components/snack-bar/snack-bar.component";

import { GlobalServices } from "../../../shared/services/global.services";
import { OrderService } from "../../../shared/services/order.service";

import {
  GlobalOrderModel,
  OrderInstanceModel,
  OrderModel,
  SelectOptionsModel,
} from "../../../shared/models/shared.model";

import { getTotalNumber } from "../../../shared/functions/helpers";
import { AutoUnsubscribe } from "../../../shared/functions/autounsubscribe";
import { RestfullServices } from "src/app/shared/services/restfull.services";
import * as introJs from "intro.js";
import { title } from "process";
import { Observable } from "rxjs";

@Component({
  selector: "app-add-order",
  templateUrl: "./add-order.component.html",
  styleUrls: ["./add-order.component.scss"],
})
@AutoUnsubscribe()
export class AddOrderComponent
  extends ApplyQueryComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  introJS = introJs.default();
  isMobileView: boolean;
  public addOrderGroup: UntypedFormGroup = new UntypedFormGroup({
    delivery: new UntypedFormControl("", Validators.required),
    paymentMethod: new UntypedFormControl("", Validators.required),
    note: new UntypedFormControl(),
  });

  public searchFilterForm: UntypedFormGroup = new UntypedFormGroup({
    search: new UntypedFormControl(),
    category: new UntypedFormControl(""),
  });

  public deliveryOpt: SelectOptionsModel[] = [
    {
      label: "Delivery",
      value: "Deliver",
    },
    {
      label: "Pick-up",
      value: "pickup",
    },
    {
      label: "Room-service",
      value: "roomService",
    },
  ];

  public paymentOpt: SelectOptionsModel[] = [
    {
      label: "Direct Billing",
      value: "direct",
    },
    {
      label: "Cash",
      value: "cash",
    },
  ];

  public categoryOpt: SelectOptionsModel[] = [{ label: "All", value: "all" }];

  public initialMenuItems: any;

  public menuItems: { label: string; items: any[] }[];

  public itemsToOrder: any[] = [];

  public order: any={};

  // DESKTOP
  public codeControl = new UntypedFormControl();

  public subTotal = 0;
  public discount = 0;
  public totalAmount = 0;
  public restaurantId: number;
  public orderNumber: number;
  public headerTitle: Observable<string>;
  status:string='processing'

  getTotalNumber(value: number) {
    return getTotalNumber(value);
  }

  constructor(
    public globalService: GlobalServices,
    public orderService: OrderService,
    public restfullServices: RestfullServices,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar
  ) {
    super(activatedRoute, router);
    this.searchFilterForm
      .get("category")
      .setValue(this.filters?.category || this.categoryOpt[0].value);
    this.orderService.clearOrderConfig();
    this.isMobileView = this.globalService.isMobile$.getValue();
    this.globalService.showLoader$.next(true);
  }

  startTour() {
    this.introJS.setOptions({
      steps: [
        {
          //step 19
          tooltipClass: "intro-step-add_order",
          title: "PLACE NEW ORDER",
          element: document.getElementById("place_order"),
          intro:
            "Well done! This is where you can place a new order for your customers by entering all the necessary information.",
          position: "right",
        },
      ],
      disableInteraction: true,
      showBullets: false,
      showButtons: true,
      exitOnOverlayClick: false,
      keyboardNavigation: true,
      scrollToElement: true,
      scrollTo: "tooltip",
    });
    this.introJS.start();
    this.introJS.onbeforechange((targetElement) => {
      this.introJS.refresh();
    });

    setTimeout(() => {
      if (document.querySelector(".intro-skip")) {
        let skipButton = document.querySelector(".intro-skip");
        skipButton.addEventListener("click", this.skipTour.bind(this));
      }

      if (document.querySelector(".introjs-skipbutton")) {
        let closeButton = document.querySelector(".introjs-skipbutton");
        closeButton.addEventListener("click", this.closeStep.bind(this));
      }
    }, 100);
  }

  skipTour(): void {
    this.introJS.exit();
    this.restfullServices.removeLocalStorage("isTourRunning");
    window.location.href = "#/pages/food-beverages";
  }

  closeStep(): void {
    window.location.href = "#/pages/food-beverages";
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.globalService.isMobile$.getValue()) {
        const isTourRunning =
          this.restfullServices.getLocalStorage("isTourRunning");
        const isFirstLogin =
          this.restfullServices.getLocalStorage("IsFirstLogin");
        const guidedTourOrdersFlag = this.restfullServices.getLocalStorage(
          "guidedTourOrdersFlag"
        );
        if (
          isTourRunning == "true" &&
          isFirstLogin == "false" &&
          guidedTourOrdersFlag == "false"
        ) {
          this.restfullServices.setLocalStorage("guidedTourOrdersFlag", "true");
          this.startTour();
          this.cdr.detectChanges();
        }
      }
    }, 500);
  }

  navigateToOrders() {
    this.router.navigate(["/pages/food-beverages/orders"], {
      queryParams: {
        orderNumber: this.activatedRoute.snapshot.queryParams.restaurantId,
        id: this.activatedRoute.snapshot.queryParams.restaurantId,
      },
    });
  }
  navigateToOrder() {
    this.router.navigate(["/pages/food-beverages/order-details"], {
      queryParams: {
        orderNumber: this.activatedRoute.snapshot.queryParams.orderNumber,
        restaurantId: this.activatedRoute.snapshot.queryParams.restaurantId,
      },
    });
  }
  ngOnInit(): void {
    this.restaurantId = this.activatedRoute.snapshot.queryParams.restaurantId;
    if (this.activatedRoute.snapshot.queryParams.orderNumber) {
      this.orderNumber = this.activatedRoute.snapshot.queryParams.orderNumber;
      this.getOrder();
    }else{
      Object.assign(this.order,{status:'processing'})
    }

    this.restfullServices
      .getHotelMenu(this.restaurantId)
      .pipe(takeUntil(this["destroy$"]))
      .subscribe((menu) => {
        if (menu) {
          
          // this.order.status='processing'
          this.initialMenuItems = menu;
          this.menuItems = [...this.initialMenuItems];
          // this.globalService.headerData$.next({
          //   headerTitle: menu[0].restaurantName,
          //   showBackButton: true,
          //   navigationPage: "/pages/food-beverages/orders",
          //   queryParams: { id: this.restaurantId },
          // });
          this.menuItems.forEach((item) =>
            this.categoryOpt.push({ label: item.label, value: item.label })
          );
          // this.initialMenuItems.map((val) => {
          //   val.items.map((response) => {
          //     response.description = atob(response.description);
          //   });
          // });
          // this.menuItems.map((val) => {
          //   val.items.map((response) => {
          //     response.description = atob(response.description);
          //   });
          // });
          this.setHeaderData();
        }
      });

    this.searchFilterForm
      .get("search")
      .valueChanges.pipe(takeUntil(this["destroy$"]))
      .subscribe((val) => {
        if (val !== "") {
          this.menuItems = this.initialMenuItems
            .map((menuItem) => {
              const filteredItems = menuItem.items.filter((item) =>
                item.title.toLowerCase().includes(val)
              );
              return filteredItems.length
                ? { ...menuItem, items: filteredItems }
                : null;
            })
            .filter((item) => !!item);
        } else {
          this.menuItems = this.initialMenuItems;
        }
      });
    this.globalService.headerClickAction$
      .pipe(
        filter((item) => item),
        takeUntil(this["destroy$"])
      )
      .subscribe(() => {
        if (this.activatedRoute.snapshot.queryParams.orderNumber) {
          this.orderService.setChangedOrder(
            +this.activatedRoute.snapshot.queryParams.orderNumber,
            this.orderService.getOrderConfig()
          );
          this.openSnackBar("Order edited successfully.");
          this.router.navigate(["/pages/food-beverages/order-details"], {
            queryParams: {
              orderNumber: this.activatedRoute.snapshot.queryParams.orderNumber,
              edited: true,
            },
          });
        } else {
          this.dialog
            .open(ConfirmOrderComponent, {
              panelClass: ["share-link_container", "border-top"],
              width: "440px",
              data: {
                itemsToOrder: this.orderService.getOrderConfig(),
              },
            })
            .afterClosed()
            .pipe(takeUntil(this["destroy$"]))
            .subscribe((resp) => {
              this.globalService.headerData$.next({
                headerTitle: String(this.headerTitle),
                showBackButton: true,
              });
              if (resp) {
                this.confirmOrder();
              }
            });
        }
      });
    this.itemsToOrder = this.orderService.getOrderConfig();
    this.calculatePrice();
    setTimeout(() => {
      this.globalService.showLoader$.next(false);
    }, 1000);
  }

  async getOrder() {
    let payload = {
      restaurantId: this.restaurantId,
      orderNumber: this.orderNumber,
    };
    const resp = await this.restfullServices
      .getOrder(payload)
      .pipe(takeUntil(this["destroy$"]))
      .toPromise();

    this.order = resp[0];
    if (this.order) {
      if(this.order.status =='pending' || this.order.status =='processing') this.order.status='processing'
      this.orderService.setOrderConfig(this.order.orders);
      this.addOrderGroup.get("delivery").setValue(this.order.delivery);
      this.addOrderGroup
        .get("paymentMethod")
        .setValue(this.order.paymentMethod);
      this.addOrderGroup.get("note").setValue(this.order.note);

      this.itemsToOrder = this.order.orders;

      this.calculatePrice();
      // this.globalService.showLoader$.next(false);
      this.addOrderGroup.valueChanges
        .pipe(takeUntil(this["destroy$"]))
        .subscribe((changes) =>
          this.orderService.setChangedOrderConfig(
            Number(this.activatedRoute.snapshot.queryParams.orderNumber),
            changes
          )
        );
    }
  }
  capitalizeFirstLetter(str) {
    if (!str) return "";
    if (str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  ngOnDestroy() {}

  public confirmOrder() {
    if( this.order.status !=='processing') return
    if (!this.addOrderGroup.get("delivery").value) {
      this.showAlert("Please choose delivery type to continue");
      return this.addOrderGroup.get("delivery").markAllAsTouched();
    }
    if (!this.addOrderGroup.get("paymentMethod").value) {
      this.showAlert("Please choose payment method to continue");
      return this.addOrderGroup.get("paymentMethod").markAllAsTouched();
    }
    //call add order api
    var orderData = {
      orderNumber:
        this.activatedRoute.snapshot.queryParams.orderNumber != "" &&
        this.activatedRoute.snapshot.queryParams.orderNumber != undefined
          ? this.activatedRoute.snapshot.queryParams.orderNumber
          : "",
      orderTime: new Date(),
      deliveryType: this.addOrderGroup.get("delivery").value,
      paymentMethod: this.addOrderGroup.get("paymentMethod").value,
      paymentStatus:
        this.addOrderGroup.get("paymentMethod").value == "Cash"
          ? "paid"
          : "unpaid",
      total: this.totalAmount.toString().replace(",", "."),
      note: this.addOrderGroup.get("note").value,
      orderStatus: "processing",
      subTotal: this.subTotal.toString().replace(",", "."),
      discount: this.discount.toString().replace(",", "."),
      tax: "0",
      voucher: "",
    };

    var menuOrderConfig: any[] = JSON.parse(
      sessionStorage.getItem("orderConfig")
    ); //title//desc//price//wait//desc
    menuOrderConfig.forEach((element) => {
      element.order.price = element.order.price.toString();
      element.order.discountedPrice = Number(element.order.discountedPrice);
      return;
    });

    var payload = {
      orderDetails: orderData,
      menuDetails: menuOrderConfig,
      restaurantId: this.activatedRoute.snapshot.queryParams.restaurantId,
    };
    this.restfullServices
      .placeOrder(payload)
      .pipe(takeUntil(this["destroy$"]))
      .subscribe((response) => {
        if (response) {
          this.openSnackBar("Order added successfully.");
          this.router.navigate(["/pages/food-beverages/orders"], {
            queryParams: { id: this.restaurantId },
          });
        }
      });
  }

  public reduceAmount(item: OrderInstanceModel) {
    if(this.order.status !=='processing') return
    if (item.amount > 1) {
      item.amount--;
    } else {
      this.itemsToOrder = this.itemsToOrder.filter(
        (order) => order.order.title !== item.order.title
      );
    }
    this.calculatePrice();
    this.orderService.setOrderConfig(this.itemsToOrder);
  }

  public increaseAmount(item: { order: OrderModel; amount: number }) {
    if( this.order.status !=='processing') return
    if (item.amount < 100) {
      item.amount++;
      this.calculatePrice();
      this.orderService.setOrderConfig(this.itemsToOrder);
    }
  }

  public setCategory(value) {
    this.searchFilterForm.get("category").setValue(value);
    this.filters.category = value;
  }

  public getFormControl(formControlName: string) {
    return this.addOrderGroup.get(formControlName);
  }

  public addItemToOrder(item) {
    if(this.order.status !=='processing') return
    const config = this.orderService.getOrderConfig();
    if (config.find((order) => order.order.title === item.title)) {
      config.find((order) => order.order.title === item.title).amount++;
    } else {
      config.push({ order: item, amount: 1 });
    }
    this.orderService.setOrderConfig(config);
    this.itemsToOrder = config;
    if (this.itemsToOrder.length > 0) {
      this.setHeaderData();
    }
    this.openSnackBar("Item added to cart.");
    this.calculatePrice();
    
  }

  public applyCode() {
    if (this.codeControl.value === "111") {
      this.discount = 2.5;
      this.snackBar.openFromComponent(SnackBarComponent, {
        panelClass: ["custom-snackbar"],
        horizontalPosition: "right",
        verticalPosition: "top",
        data: {
          svgIcon: "check-circle",
          message: "Code accepted!",
        },
      });
    }
    this.codeControl.setValue("");
    this.calculatePrice();
  }

  private openSnackBar(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: ["custom-snackbar"],
      horizontalPosition: "right",
      verticalPosition: "top",
      data: {
        svgIcon: "check-circle",
        message,
      },
    });
  }

  private showAlert(msg: string) {
    this.dialog.open(AlertDialogComponent, {
      panelClass: "custom_dialog_container",
      width: "300px",
      data: {
        title: "Field is required",
        message: msg,
        type: "warning",
      },
    });
  }

  private calculatePrice() {
    this.subTotal = 0;
    this.totalAmount = 0;
    this.itemsToOrder.forEach((item) => {
      if (item.order.discountedPrice)
        this.subTotal +=
          Number(item.amount) * Number(item.order.discountedPrice);
      else
        this.subTotal +=
          Number(item.amount) *
          Number(item.order.price.toString().replace(",", ""));
      this.totalAmount =
        this.subTotal - Number(this.discount.toString().replace(",", "q"));
    });
  }

  private setHeaderData() {
    const orderNumber = this.activatedRoute.snapshot.queryParams.orderNumber;
    const config: any = {
      showBackButton: true,
      headerTitle: this.initialMenuItems[0].restaurantName,
      confirmBack: !!!orderNumber,
      backActionTitle: "Leave this page?",
      backActionMsg: "All items in your cart will be lost.",
      navigationPage: `${
        orderNumber
          ? "/pages/food-beverages/order-details"
          : "/pages/food-beverages/orders"
      }`,
    };
    // if (this.globalService.isMobile) {
    //   config.headerTitle = `${orderNumber ? "Edit" : "Add"} Order`;
    //   config.info3 = `${orderNumber ? "Order no. " + orderNumber : ""}`;
    // } else {
    //   config.info1 = `${orderNumber ? "Edit" : "Add"} Order`;
    //   config.info3 = `${orderNumber ? "Order no. " + orderNumber : ""}`;
    // }
    if (orderNumber) {
      config.queryParams = {
        orderNumber,
        restaurantId: this.restaurantId,
      };
    } else {
      config.queryParams = {
        id: this.restaurantId,
      };
    }
    this.globalService.headerData$.next(config);
  }
  toNumber(input: any): number {
    if (typeof input === "string") {
      return parseFloat(input.replace(/,/g, ""));
    } else if (typeof input === "number") {
      return input;
    } else {
      return NaN;
    }
  }
  isPendingOrProcessing(): boolean {
    return this.order?.status === 'pending' || this.order?.status === 'processing';
  }
}
