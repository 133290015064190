import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalServices } from './global.services';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        public globalService: GlobalServices,
        private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.globalService.getToken()) {
            this.router.navigate(['/login']);
            return false;
        }
        return true;
    }

    // TODO: ask what pages should usual user access
    canActivateChild(route: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): boolean | UrlTree {
        if (!this.globalService.validateAdminRoles(route.routeConfig.path)) {
            this.globalService.showAlert('You are not authorized to view this page.');
            return false;
        }
        return true;
    }
}
