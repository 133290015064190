import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FoodBeveragesComponent } from './food-beverages.component';
import { OrdersComponent } from './orders/orders.component';
import { AddOrderComponent } from './add-order/add-order.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { AddReservationComponent } from './add-reservation/add-reservation.component';

const routes: Routes = [
    {
        path: '',
        component: FoodBeveragesComponent,
    },
    {
        path: 'orders',
        component: OrdersComponent
    },
    {
        path: 'add-order',
        component: AddOrderComponent
    },
    {
        path: 'order-details',
        component: OrderDetailsComponent
    },
    {
        path: 'add-reservation',
        component: AddReservationComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class FoodBeveragesRoutingModule { }
