import { Component, OnInit, Directive } from '@angular/core';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/storage';

import { GlobalServices } from './shared/services/global.services';

const config = {
    apiKey: 'AIzaSyDbEFWu0x8j58P4TsNaka2h6Pou7NiUBaA',
    databaseURL: 'https://sopagochat-default-rtdb.firebaseio.com'
};
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        public globals: GlobalServices
    ) {
        firebase.initializeApp(config);
    }

    ngOnInit() {
        this.globals.checkUserLoggedIn();
        this.globals.isMobile$.next(window.screen.width < 800);
        window.addEventListener('resize', () => this.globals.isMobile$.next(window.screen.width < 800));
    }
}
