<div id="view_request_dialog">
    <div class="position_relative" fxFlex="100" fxLayout="column" fxLayoutAlign="start center">
        <div class="width_100 dialog_header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <div class="header" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
                <div>{{data.title}}</div>
                <div class="header_room">Task Reference No. {{taskRefNumber}}</div>
            </div>
            <div class="filter_section dropdownCustom" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                <app-select class="filter_3"
                            label="Status"
                            [noEmptyValue]="true"
                            [value]="requestStatus"
                            (selectionChange)="requestStatus = $event"
                            [selectOptions]="selectOptions"
                ></app-select>
            </div>
        </div>
        <div class="width_100 dialog_body" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center">
            <div class="room_number">Room No. {{roomNumber}}</div>
            <div *ngIf="requestTotal > 0" class="width_100 dialog_section" fxLayout="column"
                 fxLayoutAlign="start center">
                <div *ngFor="let request of requestData" class="width_100" fxLayoutGap="20px" fxLayout="column"
                     fxLayoutAlign="start center">
                    <div *ngIf="request.houseKeepingRequest && request.houseKeepingRequest.length > 0"
                         class="width_100 section_header" fxLayoutGap="20px" fxLayout="row"
                         fxLayoutAlign="space-between center">
                        <div class="width_100" fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon [svgIcon]="'amenities'"></mat-icon>
                            <div class="section_header_label">{{request.name}}</div>
                        </div>
                        <div class="section_quantity margin_right_15_px">Qty.</div>
                    </div>
                    <div *ngFor="let requestItems of request.houseKeepingRequest" class="width_100 margin_bottom_20"
                         fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center">
                        <div class="width_100 section_body" fxLayoutGap="20px" fxLayout="row"
                             fxLayoutAlign="space-between center">
                            <div class="section_body_label">{{requestItems.requestItem}}</div>
                            <div class="section_body_quantity margin_right_15_px">
                                x{{requestItems.requestQuantity}}</div>
                        </div>
                    </div>
                </div>
                <div class="width_100 section_body extra_border" fxLayoutGap="20px" fxLayout="row"
                     fxLayoutAlign="space-between center">
                    <div class="section_body_label">Total items</div>
                    <div class="section_body_quantity margin_right_15_px">{{requestTotal}}</div>
                </div>
            </div>
            <div *ngIf="requestNote" class="width_100 dialog_section" fxLayoutGap="20px" fxLayout="column"
                 fxLayoutAlign="start center">
                <div class="width_100 section_header" fxLayoutGap="20px" fxLayout="row"
                     fxLayoutAlign="space-between center">
                    <div class="width_100" fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="start center">
                        <div class="section_header_label">NOTE:</div>
                    </div>
                </div>
                <div class="width_100 section_body" fxLayoutGap="20px" fxLayout="row"
                     fxLayoutAlign="space-between center">
                    <div class="section_body_label color_blue">{{requestNote}}</div>
                </div>
            </div>
        </div>
        <div class="width_100 dialog_footer" fxLayout="row" fxLayoutAlign="center center">
            <div [mat-dialog-close]="requestStatus" fxLayout="row" class="request_status_new"
                 fxLayoutAlign="center center">
                <div>Close</div>
            </div>
        </div>
    </div>
</div>
