<div class="confirm-order">
    <div class="confirm-order__header" fxLayout="column" fxLayoutAlign="center flex-start">
        <div class="confirm-order__header-title">
            BASKET ({{counterItems}} {{counterItems !== 1 ? 'items' : 'item'}})
        </div>
        <div class="confirm-order__header-subtitle">
            Order no.
        </div>
        <div class="confirm-order__header-close">
            <mat-icon class="close-icon" [svgIcon]="'close'" (click)="closePopup()"></mat-icon>
        </div>
    </div>
    <div class="confirm-order__main " [class.iphone-container]="isiOS">
        <div class="width_100 confirm-order__main-orders">
            <div class="confirm-order__main-view" *ngIf="orderDetails" (click)="goToMenu()">
                View menu
            </div>
            <div class="confirm-order__main-order" *ngFor="let item of itemsToOrder;let i = index">
                <div class="confirm-order__main-order-info" fxLayoutAlign="space-between center">
                    <div class="confirm-order__main-order-title">
                        {{item.amount}}x
                        <span>
                                {{item.order.title}}
                            </span>
                    </div>
                    <div class="confirm-order__main-order-price">
                        {{item.order.price | number:'1.2-2'}} {{globalService.currency}}
                    </div>
                </div>
                <div class="confirm-order__main-order-additional" fxLayoutAlign="space-between center">
                    <div class="confirm-order__main-order-note">
                        Add note
                    </div>
                    <div class="confirm-order__main-order-amount" fxLayoutAlign="center center">
                        <mat-icon class="confirm-order__main-order-remove" (click)="reduceAmount(item)">
                            remove_circle_outline
                        </mat-icon>
                        <span>{{item.amount}}</span>
                        <mat-icon class="confirm-order__main-order-add" (click)="increaseAmount(item)">
                            add_circle_outline
                        </mat-icon>
                    </div>
                </div>
            </div>
            <div class="confirm-order__main-total">
                <div class="confirm-order__main-total-subtotal" fxLayoutAlign="space-between center">
                    <span>Subtotal</span>
                    {{subTotal | number:'1.2-2'}} {{globalService.currency}}
                </div>
                <div class="confirm-order__main-total-discount" fxLayoutAlign="space-between center">
                    <span>Discount</span>
                    {{discount | number:'1.2-2'}} {{globalService.currency}}
                </div>
                <div class="confirm-order__main-total-amount" fxLayoutAlign="space-between center">
                    <span>Total Amount</span>
                    {{getTotalNumber(totalAmount)}} {{globalService.currency}}
                </div>
                <div class="confirm-order__main-total-tax" fxLayoutAlign="flex-end center">
                    Including tax
                </div>
            </div>
            <div class="confirm-order__main-code" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field class="width_100" appearance="outline" fxLayoutAlign="start center">
                        <input [formControl]="codeControl" matInput placeholder="Enter code">
                    </mat-form-field>
                </div>
                <div mat-ripple
                     class="confirm-order__main-code-confirm"
                     fxLayoutAlign="center center"
                     (click)="applyCode()">
                    Apply Code
                </div>
            </div>
        </div>
    </div>
    <div class="confirm-order__summary" fxLayout="column" fxLayoutAlign="center center">
        <div class="confirm-order__summary-actions" fxLayoutAlign="center center">
            <div mat-ripple class="confirm-order__summary-show" fxLayoutAlign="center center" (click)="submit()">
                Place Order
            </div>
        </div>
    </div>
</div>
