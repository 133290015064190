import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class SvgImportModule {
  private iconsNameArr = [
    "eye-outline",
    "eye-off-outline",
    "user-circle-outline",
    "user-circle",
    "user",
    "burger",
    "bell",
    "arrow-back",
    "settings",
    "delete",
    "arrow-down",
    "arrowDown",
    "action-active",
    "toggle",
    "dashboard",
    "customer-service",
    "broom",
    "service-toolbox",
    "spa",
    "user-group",
    "calendar",
    "reports",
    "logo",
    "amenities",
    "refresh",
    "no-existing-chat",
    "mobile-phone",
    "edit-info",
    "send-message",
    "delete",
    "warning",
    "sopago-logo-coloured",
    "green",
    "block",
    "check-circle",
    "close",
    "chat-typing",
    "open-in-new",
    "time_icon",
    "lock-outline",
    "cart-add",
    "download-outline",
    "qrcode-scan",
    "warning-box",
    "warning_orange",
    "alarm",
    "keyboard-arrow-down",
    "cancel",
    "housekeeping",
    "wake-up",
    "cab",
    "baby-face-outline"
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.iconsNameArr.forEach((icon) =>
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/images/${icon}.svg`
        )
      )
    );
  }
}
