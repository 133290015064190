import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { GlobalServices } from '../shared/services/global.services';
import { RestfullServices } from '../shared/services/restfull.services';
import { MatDialog } from '@angular/material/dialog';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        public globalService: GlobalServices, public dialog: MatDialog, private restApiService: RestfullServices) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 402 || err.status === 403) {
                return throwError(err.status);
            }
            return throwError(this.globalService.errorMsg);
        }))
    }
}
