import { NgModule } from '@angular/core';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { SelectComponent } from './components/select/select.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MaterialModule } from './material.module';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { SortFilterComponent } from './components/sort-filter/sort-filter.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
    declarations: [
        AlertDialogComponent,
        SelectComponent,
        LoaderComponent,
        SnackBarComponent,
        SortFilterComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
    ],
    exports: [
        AlertDialogComponent,
        SelectComponent,
        LoaderComponent,
        SnackBarComponent,
        FlexLayoutModule,
        SortFilterComponent,
        MatAutocompleteModule,
    ],
    providers: [
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } }
    ]
})
export class SharedModule { }
