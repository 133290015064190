<div class="sort-popup">
    <div class="sort-popup__header" fxLayout="row" fxLayoutAlign="flex-start center">
        <div (click)="closePopup()" class="sort-popup__header-back">
            <mat-icon [svgIcon]="'arrow-back'"></mat-icon>
        </div>
        <div class="sort-popup__header-title">
            Sort/Filter
        </div>
    </div>
    <div class="sort-popup__main">
        <form [formGroup]="filterForm" class="width_100">
            <mat-accordion multi>
                <mat-expansion-panel *ngFor="let item of options">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{item.label}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngIf="item.mode === 'radio'">
                        <mat-radio-group fxLayout="column" class="radios" formControlName="datum">
                            <mat-radio-button *ngFor="let radio of item.items"
                                              [value]="radio.label.toLowerCase()"
                                              [checked]="radio.checked"
                                              (change)="radio.checked = $event.value">
                                {{radio.label}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="datepickers" fxLayoutAlign="start center" *ngIf="datepickers">
                            <div>
                                <span>Von</span>
                                <mat-form-field class="filter_2" appearance="outline" fxLayoutAlign="start center">
                                    <mat-datepicker-toggle matPrefix [for]="checkInPicker"></mat-datepicker-toggle>
                                    <input formControlName="checkInDate" matInput [matDatepicker]="checkInPicker"
                                           [class.selected]="checkInState"
                                           placeholder="DD/MMM/JJJJ" disabled>
                                    <mat-datepicker #checkInPicker disabled="false"
                                                    (closed)="checkInState = true"></mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div>
                                <span>Bis</span>
                                <mat-form-field class="filter_2" appearance="outline" fxLayoutAlign="start center">
                                    <mat-datepicker-toggle matPrefix [for]="checkOutPicker"></mat-datepicker-toggle>
                                    <input formControlName="checkOutDate" matInput [matDatepicker]="checkOutPicker"
                                           [class.selected]="checkOutState"
                                           placeholder="DD/MMM/JJJJ" disabled>
                                    <mat-datepicker #checkOutPicker disabled="false"
                                                    (closed)="checkOutState = true"></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>

                    </div>

                    <div *ngIf="item.mode === 'checkbox'">
                        <div class="statuses" *ngFor="let status of item.items">
                            <mat-checkbox (change)="status.checked = $event.checked" [checked]="status.checked">
                                <mat-icon *ngIf="status.icon" [svgIcon]="status.icon"></mat-icon>
                                {{status.label}}
                            </mat-checkbox>
                        </div>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>
        </form>
    </div>
    <div class="sort-popup__summary" fxLayout="column" fxLayoutAlign="center center">
        <div class="sort-popup__summary-amount">
            {{searchAmount}} {{searchAmount > 1 ? 'Results' : 'Result'}}
        </div>
        <div class="sort-popup__summary-actions" fxLayoutAlign="center center">
            <div mat-ripple class="sort-popup__summary-clear" [class.disabled]="!getIsAnyValueForm"
                 (click)="clearFilters()">
                Clear Filter
            </div>
            <div mat-ripple class="sort-popup__summary-show" (click)="showResults()">
                Show Results
            </div>
        </div>
    </div>
</div>
