import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { GlobalOrderModel, OrderInstanceModel, OrderModel } from '../models/shared.model';
import { RestfullServices } from './restfull.services';
import { takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OrderService {

    public orderCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public testOrdersInstance: any[] = [];
    // public testOrdersInstance: GlobalOrderModel[] = [
    //     {
    //         orderNumber: 4343,
    //         delivery: 'Deliver',
    //         payment: 'Unpaid',
    //         total: 12.00,
    //         status: 'pending',
    //         datePlaced: '2023/02/13, 12:20 PM',
    //         note: 'Test',
    //         paymentMethod: 'cash'
    //     },
    //     {
    //         orderNumber: 1021,
    //         delivery: 'Pick-up',
    //         payment: 'Unpaid',
    //         total: 25.50,
    //         status: 'pending',
    //         datePlaced: '2022/08/22, 12:20 PM',
    //         paymentMethod: 'cash'
    //     },
    //     {
    //         orderNumber: 2332,
    //         delivery: 'Deliver',
    //         payment: 'Paid',
    //         total: 10.00,
    //         status: 'processing',
    //         datePlaced: '2022/08/22, 12:20 PM',
    //         paymentMethod: 'direct'
    //     },
    //     {
    //         orderNumber: 1221,
    //         delivery: 'Deliver',
    //         payment: 'Cancelled',
    //         total: 32.75,
    //         status: 'cancelled',
    //         datePlaced: '2022/08/22, 12:20 PM',
    //         paymentMethod: 'direct'
    //     },
    //     {
    //         orderNumber: 1332,
    //         delivery: 'Pick-up',
    //         payment: 'Paid',
    //         total: 5.05,
    //         status: 'delivered',
    //         datePlaced: '2022/08/22, 12:20 PM',
    //         paymentMethod: 'cash'
    //     },
    // ];

    constructor(public restfullServices: RestfullServices) {
        if (this.getOrderConfig().length) {
            this.calculateOrdersAmount();
        }
        // this.restfullServices.getOrderList().subscribe(response => {
        //     if (response) {
        //         this.testOrdersInstance = response;
        //     }})
        // filling orders instance with random menu items
        // this.restfullServices.getHotelMenu().subscribe(menu => {
        //     const merged = [].concat.apply([], menu.map(item => item.items)).slice(0, 5);
        //     merged.forEach((item: OrderModel, index) =>
        //         this.testOrdersInstance[index].orders = [{order: item, amount: 1}]
        //     );
        // });
    }
    // public getMenuItems(): Observable<any> {
    //     return of(this.restfullServices.getHotelMenu().subscribe((response)=>{return response as { label: string, items: OrderModel[] }[]}));
    // }

    // public getMenuItems(): Observable<{ label: string, items: OrderModel[] }[]> {
    //     return of<{ label: string, items: OrderModel[] }[]>([
    //         {
    //             label: 'Pizza',
    //             items: [
    //                 {
    //                     title: 'Chorizo',
    //                     description: 'Chorizo pizza made with love.',
    //                     price: 12,
    //                     menuCategoryId: "2",
    //                     waitingTime: 45,
    //                     img: 'assets/images/png/menu/chorizo.png'
    //                 },
    //                 {
    //                     title: '4 Cheese Macaroni',
    //                     description: 'Pizza with cheese macaroni.',
    //                     price: 7,
    //                     menuCategoryId: "2",
    //                     waitingTime: 30,
    //                     img: 'assets/images/png/menu/cheese.png'
    //                 },
    //                 {
    //                     title: 'Pizza Margarita',
    //                     description: 'An Indian favourite is served in… flaky, puff pastry cases for a bite-sized flavour explosion.',
    //                     price: 15.50,
    //                     menuCategoryId: "2",
    //                     waitingTime: 45,
    //                     img: 'assets/images/png/menu/margarita.png'
    //                 },
    //             ]
    //         },
    //         {
    //             label: 'Burgers',
    //             items: [
    //                 {
    //                     title: 'Double Burger',
    //                     description: 'Double burger with cheese',
    //                     price: 12,
    //                     waitingTime: 45,
    //                     menuCategoryId: "2",
    //                     img: 'assets/images/png/menu/double-burger.png'
    //                 },
    //                 {
    //                     title: 'Hamburger + Fries',
    //                     description: 'Homemade burger',
    //                     price: 7,
    //                     menuCategoryId: "2",
    //                     waitingTime: 30,
    //                     img: 'assets/images/png/menu/hamburger-fries.png'
    //                 },
    //                 {
    //                     title: 'Beef Burger',
    //                     description: 'Juicy & tasty...',
    //                     price: 15.50,
    //                     menuCategoryId: "2",
    //                     waitingTime: 35,
    //                     img: 'assets/images/png/menu/beef-burger.png'
    //                 },
    //             ]
    //         },
    //         {
    //             label: 'Drinks',
    //             items: [
    //                 {
    //                     title: 'Heineken Beer',
    //                     description: 'Pizza with cheese macaroni.',
    //                     price: 5,
    //                     menuCategoryId: "2",
    //                     img: 'assets/images/png/menu/heineken.png',
    //                     waitingTime: 5,
    //                 },
    //                 {
    //                     title: 'Orange Juice',
    //                     description: 'An Indian favourite is served in… flaky, puff pastry cases for a bite-sized flavour explosion.',
    //                     price: 3.5,
    //                     menuCategoryId: "2",
    //                     waitingTime: 5,
    //                     img: 'assets/images/png/menu/orange-juice.png'
    //                 },
    //             ]
    //         }
    //     ]);
    // }

    public getOrdersTableData(): Observable<any[]> {
        return of(this.testOrdersInstance);
    
        // console.log('tabledata',this.testOrdersInstance)
        // this.restfullServices.getOrderList().subscribe(response => {
        //     if (response) {
        //         this.testOrdersInstance = response;
        //         return of(this.testOrdersInstance); 
        //     }
        // }); 
    }

    public setChangedOrder(orderNumber: number, orders: any[]) {
        this.testOrdersInstance.find(item => item.orderNumber === orderNumber).orders = orders;
    }

    public setChangedOrderConfig(
        orderNumber: number,
        config: {
            delivery: 'Deliver' | 'Pick-up' | 'Room-service',
            paymentMethod: 'direct' | 'cash',
            note?: string
        }
    ) {
        this.testOrdersInstance.find(item => item.orderNumber === orderNumber).delivery = config.delivery;
        this.testOrdersInstance.find(item => item.orderNumber === orderNumber).paymentMethod = config.paymentMethod;
        this.testOrdersInstance.find(item => item.orderNumber === orderNumber).note = config.note;
    }

    public setChangedOrderStatus(orderNumber: number, status: string) {
        this.testOrdersInstance.find(item => item.orderNumber === orderNumber).status = status;
    }

    public getOrderConfig(): any[] {
        // console.log(JSON.parse(sessionStorage.getItem('orderConfig')));
        return JSON.parse(sessionStorage.getItem('orderConfig')) || [];
    }

    public setOrderConfig(config: any[]) {
        sessionStorage.setItem('orderConfig', JSON.stringify(config));
        // console.log(sessionStorage.getItem('orderConfig'));
        this.calculateOrdersAmount();
    }

    public clearOrderConfig() {
        sessionStorage.removeItem('orderConfig');
        this.orderCount$.next(0);
    }

    private calculateOrdersAmount() {
        let count = 0;
        this.getOrderConfig().forEach(order => count += order.amount);
        this.orderCount$.next(count);
    }

}
