import { Component, OnInit } from '@angular/core';

import { GlobalServices } from '../../shared/services/global.services';

@Component({
  selector: 'app-my-team',
  templateUrl: './my-team.component.html',
  styleUrls: ['./my-team.component.scss']
})
export class MyTeamComponent implements OnInit {

  constructor(private globalService: GlobalServices) { }

  ngOnInit(): void {
    this.globalService.headerData$.next({
      headerTitle: 'My Team',
      showBackButton: false
    });
  }

}
