import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './shared/services/auth.guard';

import { LoginComponent } from './auth/login/login.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'pages',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'pages',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/pages.module').then( m => m.PagesModule)
        // TODO: ask what pages should usual user access
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {enableTracing: false, relativeLinkResolution: 'legacy',useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
