import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages-routing.module';
import { MaterialModule } from '../shared/material.module';
import { LayoutModule } from '../layout-module/layout-module.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { HousekeepingComponent } from './housekeeping/housekeeping.component';
import { ViewRequestComponent } from './housekeeping/view-request/view-request.component';
import { MyTeamComponent } from './my-team/my-team.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ReportsComponent } from './reports/reports.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { HotelComponent } from './hotel/hotel.component';
import { ChatUserChatSideComponent } from './chat/chats-side/chats-side.component';
import { MessagesSideComponent } from './chat/messages-side/messages-side.component';
import { ChatComponent } from './chat/chat.component';
import { ChatUserInfoComponent } from './chat/chat-user-info/chat-user-info.component';
import { CreateRequestComponent } from './chat/create-request/create-request.component';

import { DynamicPipe } from '../shared/pipes/dinamic.pipe';
import { FilterByArrayPipe } from '../shared/pipes/by.array.filter.pipe';
import { WakeUpCallComponent } from './wake-up-call/wake-up-call.component';

import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import { ViewCleaningRequestComponent } from './housekeeping/view-cleaning-request/view-cleaning-request.component';
import { removeCharPipe } from '../shared/pipes/removeSpecialChar.pipe';
import { RemoveWhiteSpacePipe } from '../shared/pipes/removeWhiteSpace.pipe';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { PresentDateFormatPipe } from '../shared/pipes/DatePipeForm';
//import { EventsService } from './events.service'; // a plugin!

@NgModule({
    declarations: [
        HotelComponent,
        DashboardComponent,
        HousekeepingComponent,
        ViewRequestComponent,
        ViewCleaningRequestComponent,
        CalendarComponent,
        MyTeamComponent,
        MaintenanceComponent,
        ReportsComponent,
        DynamicPipe,
        ChatUserInfoComponent,
        ChatUserChatSideComponent,
        MessagesSideComponent,
        FilterByArrayPipe,
        ChatComponent,
        CreateRequestComponent,
        WakeUpCallComponent,
        removeCharPipe,
        RemoveWhiteSpacePipe,
        PresentDateFormatPipe
    ],
    imports: [
        SharedModule,
        PagesRoutingModule,
        MaterialModule,
        LayoutModule,
        CommonModule,
        FlexLayoutModule,
        MatPaginatorModule,
        MatSortModule
    ],
    exports: [
        MessagesSideComponent,
        removeCharPipe,
        RemoveWhiteSpacePipe,
        MatPaginatorModule,
        MatSortModule,
        PresentDateFormatPipe
    ]
})
export class PagesModule { }
