<div class="add-payment">
    <div class="add-payment__header" fxLayout="column" fxLayoutAlign="center flex-start">
        <div class="add-payment__header-title">
            CHECK-IN GUEST
        </div>
        <div class="add-payment__header-subtitle">
            Booking ID: {{this.bookingNumber}}
        </div>
        <div class="add-payment__header-close">
            <mat-icon class="close-icon" [svgIcon]="'close'" (click)="closePopup()"></mat-icon>
        </div>
    </div>
    <form [formGroup]="paymentForm" *ngIf="paymentForm" class="add-payment__main " [class.iphone-container]="isiOS"
        fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start center">
        <div class="width_100 add-payment__main-orders" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-label>Primary Guest</mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput formControlName="primaryGuest">
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-label>Check-In/Out Dates </mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput formControlName="checkIn">
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-label>Room Type</mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput formControlName="roomType">
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-label>Room No.</mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput formControlName="roomNo">
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-label>No. of Guests</mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput formControlName="guests">
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="add-payment__summary" fxLayout="column" fxLayoutAlign="center center">
        <div class="add-payment__summary-actions" fxLayoutAlign="center center">
            <div mat-ripple class="add-payment__summary-clear" (click)="closePopup()">
                Cancel
            </div>
            <div mat-ripple class="add-payment__summary-show" (click)="checkInGuest()">
                Check-in Guest
            </div>
        </div>
    </div>
</div>