import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpHeaderResponse } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { GlobalServices } from './global.services';

import { environment } from '../../../environments/environment';
import { OrderModel } from '../models/shared.model';


@Injectable({ providedIn: 'root' })
export class RestfullServices {

    public baseUrl: string = environment.baseUrl;
    public hotelID: string = "";

    // public imageUrl: string = this.baseUrl + 'images/';

    // public dummyImageUrl: string = this.baseUrl + 'images/DummyImages/';

    public apiUrl = {
        loginHotelAdmin: 'api/HotelGuest/HotelLogin',
        getGuestList: 'api/HotelGuest/GetHotelGuestList',
        getChatGuestList: 'api/HotelGuest/GetChatGuestList',
        addGuest: 'api/HotelGuestOperations/AddGuest',
        editGuest: 'api/HotelGuestOperations/EditGuest',
        deleteGuest: 'api/HotelGuestOperations/DeleteGuest',
        getGuestDetails: 'api/HotelGuest/GetGuestDetails',
        getHouseKeepingRequest: 'api/HotelHouseKeeping/GetHouseKeepingRequest',
        getHouseKeepingRequestNew: 'api/HotelHouseKeeping/GetHouseKeepingListOfRequestsNew',
        saveHouseKeepingRequest: "api/HouseKeeping/SaveHouseKeepingData",
        updateHouseKeepingRequestStatus: 'api/HotelHouseKeeping/UpdateHouseKeepingRequest',
        sendLink: 'api/HotelGuest/SetHotelSecondaryGuestList',
        getSecondaryGuestDetails: 'api/HotelGuest/GetHotelSecondaryGuestList',
        downloadPdf: 'api/HotelGuest/DownloadPdf',
        downloadIdProof: 'api/HotelGuest/DownloadIdProof',
        getIdProof: 'api/HotelGuest/GetIdProof',
        getHouseKeepingData: 'api/HouseKeeping/HouseKeepingDataHotel',
        setAppActivity: 'api/HotelGuest/SetAppActivity',
        getSpaAndWellness: 'api/HotelSpaAndWellness/GetSpaAndWellnessList',
        getHotelMenu: 'api/HotelFoodAndBeverages/GetHotelMenu',
        placeOrder: 'api/HotelFoodAndBeverages/HotelPlaceOrder',
        getOrderList: 'api/HotelFoodAndBeverages/GetOrderList',
        getOrder: 'api/HotelFoodAndBeverages/GetOrderByOrderNo',
        updateOrderStatus: 'api/HotelFoodAndBeverages/UpdateOrderStatus',
        getInvoiceDetails: 'api/HotelGuest/GetInvoiceDetails',
        addPaymentOnCheckout: 'api/HotelGuest/AddPaymentOnCheckOut',
        checkOutGuest: "api/HotelGuest/CheckOutFromHotel",
        getHotelRooms: "api/HotelGuest/GetHotelRoomList",
        getSpaServiceList: "api/HotelSpaAndWellness/GetSpaServiceList",
        getRegisteredUser: "api/HotelGuest/GetUserRegistrationStatus",
        hotelCheckIn: "api/HotelGuest/HotelCheckIn",
        saveRegistrationFormHotel: "api/HotelGuest/SaveRegistrationFormHotel",
        getGuestListWithId: "api/HotelSpaAndWellness/GetGuestListForSpa",
        bookSpa: "api/HotelSpaAndWellness/BookSpaFromHotel",
        getSpaCalendar: "api/HotelSpaAndWellness/GetSpaBookingDetailsCalendar",
        cancelSpa: "api/HotelSpaAndWellness/CancelSpaFromHotel",
        getSpaDetails: "api/HotelSpaAndWellness/GetSpaDetail",
        updateSpaStatus: "api/HotelSpaAndWellness/UpdateSpaStatus",
        addSpaPayment: "api/HotelSpaAndWellness/AddSpaPayment",
        addOrderPayment:"api/HotelFoodAndBeverages/AddOrderPayment",
        getFBRestaurants:"api/HotelFoodAndBeverages/RestaurantList",
        getFBCount:"api/HotelFoodAndBeverages/TotalFBCount",

        /** Reservation */
        getTableReservationData: "api/HotelFoodAndBeverages/GetTableReservationData",
        reserverTableFromHotel: "api/HotelFoodAndBeverages/ReserverTableFromHotel",
        getReservationDetails: "api/HotelFoodAndBeverages/FetchReservation",

        getWakeUpCall: "api/HotelWakeUpCall/GetWakeUpCallListHotel",
        addWakeUpCall: "api/HotelWakeUpCall/AddWakeUpCall",
        updatewakeUpCallStatus: "api/HotelWakeUpCall/UpdateWakeUpCallStatus",

        //push notification
        transactional: "api/Notification/TransactionalPush",
        //transportaion
        getTranportationdata:"api/HotelTransportation/GetTransportationList",
        getransportationCalendar: "api/HotelTransportation/GetTransportationReservationCalendar",
        bookTransportationServiceURL:"api/HotelTransportation/ReserveTransportationFromHotel",
        cancelTransportationUrl:"api/HotelTransportation/CancelTransportationFromHotel",
        getTransportationDetailsUrl:"api/HotelTransportation/GetTransportationDetail",
        transportationPaymentUrl:"api/HotelTransportation/AddTransportationPayment",
        //kids club
        getKidsClubList:"api/HotelKidsClub/KidsClubRegistrationList",
        getKidsClubCalendar:"api/HotelKidsClub/KidsClubCalendar",
        getKidsClubParentQuestions:"api/HotelKidsClub/KidsClubRegistrationParentQuestions",
        getKidsClubGuestList:'api/HotelKidsClub/HotelGuestList',
        getKidsClubMemberDetails:'api/HotelKidsClub/KidsClubRegistrationDetails',
        kidsClubMemberRegistration:'api/HotelKidsClub/KidsClubRegistration',
        kidsClubMemberAttendenceList:'api/HotelKidsClub/KidsClubGetAttendanceList',
        kidsClubActivities:'api/HotelKidsClub/KidsClubActivities',
        kidsClubMemberSaveAttendence:"api/HotelKidsClub/KidsClubSaveAttendance",
        cancelKidsClubMemberShip:'api/HotelKidsClub/KidsClubCancelMembership',
        kidsClubMemberNotes:'api/HotelKidsClub/KidsClubGetNotesList',
        saveKidsClubMemberNote:"api/HotelKidsClub/KidsClubSaveNotes",
        deleteKidsClubMemberNote:'api/HotelKidsClub/KidsClubRemoveNotes',
        kidsClubCheckInMember:'api/HotelKidsClub/KidsClubSaveAttendance',
        kidsClubCalendar:'api/HotelKidsClub/KidsClubGetAttendanceListCalendar',

        // Hotelstatus
        getHotelStatus: "api/HotelGuest/GetHotelStatus",
    };

    headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
    });

    options = new HttpHeaderResponse({
        headers: this.headers
    });

    payload = {
        encryptionKey: this.globalService.getToken()
    };



    constructor(
        private http: HttpClient,
        public globalService: GlobalServices,
    ) {
        this.hotelID = JSON.parse(localStorage.getItem('loggedInHotelDetails'));
    }

    loginHotelAdmin(emailAddress, password): Observable<any> {
        this.globalService.createToken();
        this.payload.encryptionKey = this.globalService.getToken();
        Object.assign(this.payload, {
            emailAddress,
            password
        });
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.loginHotelAdmin),
            this.payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    getHotelStatus(): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getHotelStatus),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getGuestList(): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getGuestList),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getSecondaryGuestDetails(guestId): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            guestId: guestId
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getSecondaryGuestDetails),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    hotelCheckIn(request): Observable<any> {
        const payload = Object.assign(request, {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
        });
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.hotelCheckIn),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    saveRegistrationFormHotel(request: any): Observable<any> {
        const payload = Object.assign(request, {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
        });
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.saveRegistrationFormHotel),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getRegisteredUser(guestId): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            guestId: guestId
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getRegisteredUser),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getGuestDetails(guestId: number): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            guestId
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getGuestDetails),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    downloadPdf(guestId: number): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            guestId
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.downloadPdf),
            payload,
            this.options
        ).pipe(
            map(responseData => {
                return responseData;
                //return responseData[0];
            }),
            catchError((error, caught) => {
                return this.globalService.errorMsg;
            }),
        )
    }

    downloadIdProof(guestId: number, imageId: number): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            guestId,
            id: imageId
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.downloadIdProof),
            payload,
            this.options
        ).pipe(
            map(responseData => {
                return responseData;
            }),
            catchError((error, caught) => {
                return this.globalService.errorMsg;
            }),
        )
    }
    getIdProof(guestId: number): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            guestId
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getIdProof),
            payload,
            this.options
        ).pipe(
            map(responseData => {
                return responseData;
            }),
            catchError((error, caught) => {
                return this.globalService.errorMsg;
            }),
        )
    }

    addGuest(obj): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            guestObj: obj
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.addGuest),
            payload,
            this.options
        ).pipe(
            // map(response => response),
            // catchError(() => this.globalService.errorMsg),
        );
    }

    sendLink(obj): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            guestId: this.globalService.selectedGuestId,
            encryptionKey: this.globalService.getToken(),
            guestObj: obj
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.sendLink),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    setAppActivity(userId, appActivity): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            guestId: userId,
            encryptionKey: this.globalService.getToken(),
            appActivity: appActivity
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.setAppActivity),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    editGuest(obj): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            guestObj: obj
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.editGuest),
            payload,
            this.options
        ).pipe(
            // map(response => response),
            // catchError(() => this.globalService.errorMsg),
        );
    }

    deleteGuest(id): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            guestId: id
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.deleteGuest),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getChatGuestList(): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getChatGuestList),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getHouseKeepingRequest(): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getHouseKeepingRequest),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getHouseKeepingRequestNew(): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getHouseKeepingRequestNew),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    updateHouseKeepingRequestStatus(requestId: number, requestType: string, requestStatus: number): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            requestId,
            requestType,
            requestStatus
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.updateHouseKeepingRequestStatus),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    saveHouseKeepingRequest(requestObj, guestId): Observable<any> {
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.saveHouseKeepingRequest),
            {
                hotelId: this.globalService.HOTELID,
                encryptionKey: this.globalService.getToken(),
                guestId,
                requestObj
            },
            this.options
        ).pipe(
            map(responseData => responseData),
            catchError(() => this.globalService.errorMsg),
        );
    }

    /** Fetch the houese keeping */
    getHouseKeepingData(): Observable<any> {
        let payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getHouseKeepingData),
            payload,
            this.options
        ).pipe(
            map(responseData => responseData),
            catchError(() => this.globalService.errorMsg),
        );
    }

    /** Fetch Spa & Wellness */
    getSpaAndWellnessData(): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getSpaAndWellness),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getGuestListWithId(): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getGuestListWithId),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    bookSpa(obj): Observable<any> {
        const payload = Object.assign(obj, {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        });
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.bookSpa),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getSpaServiceList(): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getSpaServiceList),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getSpaCalendar(): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getSpaCalendar),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    cancelSpa(obj): Observable<any> {
        const payload = Object.assign(obj, {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        });
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.cancelSpa),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getSpaDetail(obj): Observable<any> {
        const payload = Object.assign(obj, {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        });
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getSpaDetails),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    updateSpaStatus(orderNumber: number, orderStatus: string): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            orderNumber: orderNumber,
            orderStatus: orderStatus
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.updateSpaStatus),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    addSpaPayment(obj): Observable<any> {
        const payload = Object.assign(obj, {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        });
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.addSpaPayment),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    addOrderPayment(obj): Observable<any> {
        const payload = Object.assign(obj, {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        });
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.addOrderPayment),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    placeOrder(obj): Observable<any> {
        const payload = {
            menuDetails: obj.menuDetails,
            orderDetails: obj.orderDetails,
            hotelId: this.globalService.HOTELID,
            restaurantId:obj.restaurantId,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.placeOrder),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    //Api's for transportation
    getTransportationData(): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getTranportationdata),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getTransportationCalendar(): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getransportationCalendar),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    bookTransportationService(payload):Observable<any> {
        Object.assign(payload,{
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        })
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.bookTransportationServiceURL),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    cancelTransportation(id:number,status:string): Observable<any> {
        let payload =  {
            id:id,
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            status:status
        }
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.cancelTransportationUrl),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    getTranspotationDetail(id:number): Observable<any> {
        let payload =  {
            id:id,
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        }
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getTransportationDetailsUrl),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    transportationPayment(obj): Observable<any> {
        const payload = Object.assign(obj, {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        });
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.transportationPaymentUrl),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    //Api's for kids club
    getKidsClubList(): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getKidsClubList),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getParentQuestions(){
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getKidsClubParentQuestions),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getKidsClubGuestList(){
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getKidsClubGuestList),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    getKidsClubMemberDetails(id:any){
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            kidsClubRegistrationId: id
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getKidsClubMemberDetails),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    kidsClubMemberRegistration(data:any){
        const payload = Object.assign(data, {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
        })
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.kidsClubMemberRegistration),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    getKidsClubAttendenceList(id:number){
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            kidsClubRegistrationId: id
        }
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.kidsClubMemberAttendenceList),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    getKidsClubActivities(guestId:any){
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            guestId: guestId
        }
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.kidsClubActivities),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    saveKidsClubMemberAttendence(data:any){
        const payload = Object.assign(data, {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
        })
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.kidsClubMemberSaveAttendence),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    cancelKidsClubMembership(id:number){
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            kidsClubRegistrationId: id
        }
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.cancelKidsClubMemberShip),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    getKidsClubMemberNotes(id:number){
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            kidsClubRegistrationId: id
        }
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.kidsClubMemberNotes),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    saveKidsClubMemberNote(data:any){
        const payload = Object.assign(data, {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
        })
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.saveKidsClubMemberNote),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    deleteKidsClubMemberNote(id:any){
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            id: id
        }
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.deleteKidsClubMemberNote),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    checkInKidsClubMember(data:any){
        const payload = Object.assign(data, {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
        })
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.kidsClubCheckInMember),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    getKidsClubClendar(){
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
        }
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.kidsClubCalendar),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }





    //Wake up call
    getWakeUpCall(): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getWakeUpCall),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    addWakeUpCall(obj): Observable<any> {
        const payload = Object.assign(obj, {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        });
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.addWakeUpCall),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    updatewakeUpCallStatus(obj): Observable<any> {
        const payload = Object.assign(obj, {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        });
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.updatewakeUpCallStatus),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getOrderList(id:number): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            restaurantId:Number(id)
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getOrderList),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    getOrder(payload:any): Observable<any> {
        Object.assign(payload,{
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
        })
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getOrder),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    /*** Reservation List */
    getReservationList(restaurantId:number): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            restaurantId:restaurantId
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getTableReservationData),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    /** add Table Reservation Data */
    addTableReservation(reservationObj): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            id: reservationObj.id,
            guestId: reservationObj.guestId,
            guests: reservationObj.guests,
            time: reservationObj.time,
            date: reservationObj.date,
            note: reservationObj.note,
            tableNumber: reservationObj.tableNumber,
            status: reservationObj.status,
            isReserved: reservationObj.isReserved,
            restaurantId:reservationObj.restaurantId
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.reserverTableFromHotel),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    /** Fetch reservation data using id */
    getReservationById(reservationObj): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            id: reservationObj.id,
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getReservationDetails),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getInvoiceDetails(guestId: any): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            guestId: guestId,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getInvoiceDetails),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    addPaymentOnCheckout(guestId: any): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            guestId: guestId,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.addPaymentOnCheckout),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    /*** Guest check out */
    checkOutGuest(obj): Observable<any> {
        let payload = Object.assign(obj, {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
        });
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.checkOutGuest),
            payload,
            this.options
        )
            .pipe(
                map(responseData => {
                    return responseData;
                }),
                catchError((error, caught) => {
                    return this.globalService.errorMsg;
                }),
            )
    }

    getHotelMenu(restaurantId:number): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            restaurantId:restaurantId
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getHotelMenu),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getHotelRooms(): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getHotelRooms),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    updateOrderStatus(orderNumber: number, orderStatus: string): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken(),
            orderNumber: orderNumber,
            orderStatus: orderStatus
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.updateOrderStatus),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

    getFBRestaurants(): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getFBRestaurants),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }
    getFBCount(): Observable<any> {
        const payload = {
            hotelId: this.globalService.HOTELID,
            encryptionKey: this.globalService.getToken()
        };
        return this.http.post(
            this.baseUrl.concat(this.apiUrl.getFBCount),
            payload,
            this.options
        ).pipe(
            map(response => response),
            catchError(() => this.globalService.errorMsg),
        );
    }

//push notification
trasactionalNotification(obj: any): Observable<any> {
    return this.http.post(
      this.baseUrl.concat(this.apiUrl.transactional),
      {
        url: obj.url,
        title: obj.title,
        actions: obj.actions,
        vibrate: obj.vibrate,
        icon: obj.icon,
        message: obj.message,
        orderNumber:+obj.orderNumber,
        serviceName:obj.serviceName
      },
      this.options
    );
  }


    // for set local storage value
    setLocalStorage(storageKey: any, storageValue: any) {
        localStorage.setItem(storageKey, storageValue);
    }

    //for get local storage value
    getLocalStorage(storageKey: any) {
        return localStorage.getItem(storageKey);
    }

    // for remove local storage value
    removeLocalStorage(storageKey: any) {
        return localStorage.removeItem(storageKey);
    }
}
