<div class="guest_details" fxLayout="column" fxLayoutAlign="start center">
  <div class="guest_details_header" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
    <mat-icon [svgIcon]="'user-circle'"></mat-icon>
    <div class="guest_name">{{selectedGuest?.lastName}}, {{selectedGuest?.firstName}}</div>
  </div>
  <div
      class="guest_details_body width_100"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start center"
      *ngFor="let line of lines">
    <div
        class="guest_details_label"
        fxFlex="50"
        fxLayout="row"
        fxLayoutAlign="start center">
      {{line.name}}
    </div>
    <div class="guest_details_value" fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
      {{(selectedGuest && selectedGuest[line.method]) | dynamicPipe: line.pipes}}
    </div>
  </div>
  <div class="guest_details_footer width_100" fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="center center">
    <div>View more details</div>
    <mat-icon [svgIcon]="'open-in-new'"></mat-icon>
  </div>
</div>
