import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NgZone, RendererFactory2 } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DOCUMENT, DatePipe, DecimalPipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider'

import { AppRoutingModule } from './app-routing.module';
import { SvgImportModule } from './shared/functions/svg-import.module';
import { MaterialModule } from './shared/material.module';
import { LayoutModule } from './layout-module/layout-module.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { AppComponent } from './app.component';
import { ViewRequestComponent } from './pages/housekeeping/view-request/view-request.component';
import { PagesModule } from './pages/pages.module';
import { GuestModule } from './pages/guest/guest.module';
import { FoodBeveragesModule } from './pages/food-beverages/food-beverages.module';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ModalDialogComponent } from './pages/guest/modal-dialog/modal-dialog.component';
import { CheckOutPopupComponent } from './pages/guest/check-out-popup/check-out-popup.component';
import { CheckoutAddPaymentComponent } from './pages/guest/checkout-add-payment/checkout-add-payment.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ViewCleaningRequestComponent } from './pages/housekeeping/view-cleaning-request/view-cleaning-request.component';
import { ErrorInterceptor } from './auth/error.interceptor';
import { CheckInPopupComponent } from './pages/guest/check-in-popup/check-in-popup.component';
import { UserRegistrationComponent } from './pages/guest/user-registration/user-registration.component';
import { AddWakeupCallComponent } from './pages/wake-up-call/add-wakeup-call/add-wakeup-call.component';
import { SpaWellnessModule } from './pages/spa-wellness/spa-wellness.module';
import { SpaAddPaymentComponent } from './pages/spa-wellness/spa-add-payment/spa-add-payment.component';
import { ViewWakeUpCallComponent } from './pages/wake-up-call/view-wake-up-call/view-wake-up-call.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';

const maskConfig: Partial<IConfig> = {
    validation: false,
};

export const PREACT_RENDERER_FACTORY = {
    provide: RendererFactory2,
    //  useClass: RendererFactory2WithPreact,
    deps: [DOCUMENT, NgZone]
};



@NgModule({
    declarations: [
        AppComponent,
        CheckOutPopupComponent,
        CheckoutAddPaymentComponent,
        ModalDialogComponent,
        CheckInPopupComponent,
        UserRegistrationComponent,
        AddWakeupCallComponent,
        SpaAddPaymentComponent,
        ViewWakeUpCallComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        HttpClientModule,
        SvgImportModule,
        MaterialModule,
        LayoutModule,
        SharedModule,
        AuthModule,
        PagesModule,
        GuestModule,
        FoodBeveragesModule,
        SpaWellnessModule,
        MatDividerModule,
        MatAutocompleteModule,
        NgxMaskModule.forRoot(maskConfig),
    ],
    exports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        HttpClientModule,
        MatAutocompleteModule
    ],
    providers: [DatePipe, DecimalPipe,
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
