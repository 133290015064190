<div class="alert_dialog" [ngSwitch]="data.type">
    <div *ngSwitchCase="'message'" fxFlex="100" fxLayout="column" fxLayoutAlign="start center">
        <div class="width_100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <div class="header">{{data.title}}</div>
            <!-- <mat-icon class="clear" (click)="onNoClick()">clear</mat-icon> -->
        </div>
        <div class="text_2">{{data.message}}</div>
        <div fxFlex="100" class="width_100 alert_footer" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="center center">
            <!-- <button class="button_2" mat-raised-button (click)="onNoClick()">Ok</button> -->
            <button class="button_3" mat-raised-button (click)="onNoClick()">Okay</button>
        </div>
    </div>
    <div *ngSwitchCase="'confirmUnsavedChanges'" fxFlex="100" fxLayout="column" fxLayoutAlign="start center">
        <div class="width_100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <div class="header">{{data.title}}</div>
            <mat-icon class="clear" (click)="onNoClick()">clear</mat-icon>
        </div>
        <div class="text_2">{{data.message}}</div>
        <div fxFlex="100" class="width_100 alert_footer" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="end center">
            <button class="button_2 cursor_pointer" mat-raised-button fxLayout="row" (click)="onLeaveClick()" fxLayoutAlign="center center">
                Stay
            </button>
            <button class="button_3 cursor_pointer" mat-raised-button fxLayout="row" [mat-dialog-close]="data.type" fxLayoutAlign="center center">
                Leave
            </button>
        </div>
    </div>
    <div *ngSwitchCase="'confirmUnsavedChangesCustom'" fxFlex="100" fxLayout="column" fxLayoutAlign="start center">
        <div class="width_100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <div class="header">{{data.title}}</div>
            <mat-icon class="clear" (click)="onNoClick()">clear</mat-icon>
        </div>
        <div class="text_2">{{data.message}}</div>
        <div fxFlex="100" class="width_100 alert_footer" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="end center">
            <button class="button_2 cursor_pointer" mat-raised-button fxLayout="row" (click)="onLeaveClick()" fxLayoutAlign="center center">
                Close
            </button>
            <button class="button_3 cursor_pointer" mat-raised-button fxLayout="row" [mat-dialog-close]="data.type" fxLayoutAlign="center center">
                {{data.buttonText?data.buttonText:'Discard'}}
            </button>
        </div>
    </div>
    <div *ngSwitchCase="'confirmDelete'" fxFlex="100" fxLayout="column" fxLayoutAlign="start center">
        <div class="width_100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <mat-icon [svgIcon]="'warning'"></mat-icon>
            <div class="header">{{data.title}}</div>
            <mat-icon class="clear" (click)="onLeaveClick()">clear</mat-icon>
        </div>
        <div class="text_2">{{data.message}}</div>
        <div fxFlex="100" class="width_100 alert_footer" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="end center">
            <button class="button_1 cursor_pointer" mat-raised-button fxLayout="row" (click)="onNoClick()" [mat-dialog-close]="data.type" fxLayoutAlign="center center">
                {{data.buttonText?data.buttonText:'Delete'}}

            </button>
            <button class="button_2 cursor_pointer" mat-raised-button fxLayout="row" (click)="onLeaveClick()" fxLayoutAlign="center center">
                Cancel
            </button>
        </div>
    </div>
    <div *ngSwitchCase="'confirmDisable'" fxFlex="100" fxLayout="column" fxLayoutAlign="start center">
        <div class="width_100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <mat-icon [svgIcon]="'warning_orange'" style="color: #e26800;"></mat-icon>
            <div class="header">{{data.title}}</div>
            <mat-icon class="clear" (click)="onLeaveClick()">clear</mat-icon>
        </div>
        <div class="text_2">{{data.message}}</div>
        <div fxFlex="100" class="width_100 alert_footer" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="end center">
            <button class="button_4 cursor_pointer" mat-raised-button fxLayout="row" (click)="onNoClick()" [mat-dialog-close]="data.type" fxLayoutAlign="center center">
                Disable
            </button>
            <button class="button_2 cursor_pointer" mat-raised-button fxLayout="row" (click)="onLeaveClick()" fxLayoutAlign="center center">
                Cancel
            </button>
        </div>
    </div>
    <div *ngSwitchCase="'completeRegistrationForm'" fxFlex="100" fxLayout="column" fxLayoutAlign="start center">
        <div class="width_100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <div class="header">{{data.title}}</div>
            <mat-icon class="clear" (click)="onLeaveClick()">clear</mat-icon>
        </div>
        <div class="text_2">{{data.message}}</div>
        <div fxFlex="100" class="width_100 alert_footer" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="end center">
            <button class="button_2 cursor_pointer" mat-raised-button fxLayout="row" (click)="onLeaveClick()" [mat-dialog-close]="data.type" fxLayoutAlign="center center">
                Cancel
            </button>
            <button class="button_3 cursor_pointer" mat-raised-button fxLayout="row" (click)="onNoClick()" fxLayoutAlign="center center">
                Continue to Registration Form
            </button>
        </div>
    </div>
    <div *ngSwitchCase="'confirmDiscardChanges'" fxFlex="100" fxLayout="column" fxLayoutAlign="start center">
        <div class="width_100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <div class="header">{{data.title}}</div>
            <mat-icon class="clear" (click)="onNoClick()">clear</mat-icon>
        </div>
        <div class="text_2">{{data.message}}</div>
        <div fxFlex="100" class="width_100 alert_footer" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="end center">
            <button class="button_2" mat-raised-button (click)="onNoClick()">Cancel</button>
            <button class="button_3" mat-raised-button [mat-dialog-close]="data.type">Discard</button>    
        </div>
    </div>
    <div *ngSwitchCase="'warning'" fxFlex="100" fxLayout="column" fxLayoutAlign="start center">
        <div class="width_100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <mat-icon [svgIcon]="'warning-box'"></mat-icon>
            <div class="header">{{data.title}}</div>
        </div>
        <div class="text_3">{{data.message}}</div>
        <div fxFlex="100" class="width_100 alert_footer no-border" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="center center">
            <button class="button_2 cursor_pointer" mat-raised-button fxLayout="row" (click)="onLeaveClick()" fxLayoutAlign="center center">
                OK
            </button>
        </div>
    </div>
</div>
