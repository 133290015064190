<div class="add-order" fxLayout="column" fxFlex="100" fxLayoutAlign="start start" id="place_order1">
    <div style="margin-bottom: 20px;">
        <span class="breadcrumb" (click)="navigateToOrders()">All Orders</span><span (click)="navigateToOrder()" *ngIf="orderNumber" class="breadcrumb"> / {{orderNumber}}</span> <span class="breadcrumbdark"> / {{orderNumber?'Edit Order':'Add Order'}} </span>
    </div>
    <div *ngIf="orderNumber" class="order-details__header width_100" fxLayoutAlign="space-between center">      
        <div class="order-details__header-status"
            [class.order-details__header-status-unpaid]="order?.payment === 'unpaid'">
            {{capitalizeFirstLetter(order?.payment)}}
        </div>
    </div>
    <form [formGroup]="addOrderGroup" *ngIf="addOrderGroup" class="container" fxLayout="column" fxLayoutGap="20px"
        fxLayoutAlign="start center">
        <div class="container_title width_100 extra_margin_top" fxLayout="row" fxLayoutAlign="start center">
            CUSTOMER INFORMATION
        </div>
        <div class="container_section width_100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center">

            <div class="container_body width_100" fxLayout="column" fxLayoutGap="10px">
                <div fxLayout="{{globalService.isMobile$.getValue() ? 'column' : 'row'}}" fxLayoutGap="30px">
                    <div class="filter_Box addGuestBox" [ngClass]="isMobileView ? 'width_100' : 'width_30'">
                        <div fxLayoutGap="5px" class=" filter_section dropdownCustom" fxLayout="column"
                            fxLayoutAlign="start start">
                            <!-- <div fxLayout="row" fxFlex="{{globalService.isMobile$.getValue() ? 100 : 25}}"
                        fxLayoutAlign="space-between center"> -->
                            <div fxLayoutGap="5px" class="width_100" fxLayout="column" fxLayoutAlign="start start">
                                <app-select label="Delivery Type" [noEmptyValue]="true"
                                    [customControl]="getFormControl('delivery')"
                                    [value]="getFormControl('delivery').value"
                                    (selectionChange)="getFormControl('delivery').setValue($event)"
                                    [selectOptions]="deliveryOpt"></app-select>
                            </div>
                        </div>
                    </div>

                    <div class="filter_Box addGuestBox" [ngClass]="isMobileView ? 'width_100' : 'width_30'">
                        <div fxLayoutGap="5px" class=" filter_section dropdownCustom" fxLayout="column"
                            fxLayoutAlign="start start">
                            <!-- <div fxLayout="row" fxFlex="{{globalService.isMobile$.getValue() ? 100 : 25}}" 
                            fxLayoutAlign="space-between center">-->
                            <div fxLayoutGap="5px" class="width_100" fxLayout="column" fxLayoutAlign="start start">
                                <app-select label="Payment" [noEmptyValue]="true"
                                    [customControl]="getFormControl('paymentMethod')"
                                    [value]="getFormControl('paymentMethod').value"
                                    (selectionChange)="getFormControl('paymentMethod').setValue($event)"
                                    [selectOptions]="paymentOpt"></app-select>
                            </div>
                        </div>
                    </div>

                </div>

                <div fxLayout="row" class="width_100" fxLayoutAlign="space-between center">
                    <div fxLayoutGap="5px" class="width_100 remarks" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>Note/Remarks</mat-label>
                        <mat-form-field class="width_100" appearance="outline">
                            <textarea matInput formControlName="note"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div fxLayoutAlign="space-between center" class="width_100">
        <div class="menu width_100" fxFlex="{{globalService.isMobile$.getValue() ? 100 : 65}}" fxLayout="column"
            fxLayoutAlign="start center">
            <div class="menu__title width_100">
                <hr class="width_100">
                <span>MENU</span>
                <hr class="width_100">
            </div>

            <form [formGroup]="searchFilterForm" class="menu__search width_100" fxLayout="row"
                fxLayoutAlign="space-between center">
                <div fxFlex="{{globalService.isMobile$.getValue() ? 100 : 50}}" fxLayout="row"
                    fxLayoutAlign="start center">
                    <mat-form-field class="width_100" appearance="outline" fxLayoutAlign="start center">
                        <mat-icon matPrefix>search</mat-icon>
                        <input formControlName="search" matInput placeholder="Search menu">
                    </mat-form-field>
                </div>
                <div class="filter_Box addGuestBox" [ngClass]="isMobileView ? 'width_100' : 'width_30'">
                    <div fxLayoutGap="5px" class=" filter_section dropdownCustom" fxLayout="column"
                        fxLayoutAlign="start start">
                        <app-select placeholder="Category" [noEmptyValue]="true"
                            [customControl]="searchFilterForm.get('category')"
                            [value]="searchFilterForm.get('category').value" (selectionChange)="setCategory($event)"
                            [selectOptions]="categoryOpt">
                        </app-select>
                    </div>
                </div>
            </form>

            <div class="menu__container width_100" fxLayout="column">
                <div class="menu__container-section width_100" *ngFor="let section of menuItems">
                    <div *ngIf="searchFilterForm.get('category').value === 'all'
                            || searchFilterForm.get('category').value === section?.label
                            || !searchFilterForm.get('category').value">
                        <div class="menu__container-section-label" *ngIf="section?.items.length !=0">
                            {{section?.label}}
                        </div>
                        <div class="menu__container-section-tile width_100" *ngFor="let tile of section?.items"
                            fxLayoutAlign="{{globalService.isMobile$.getValue() ? 'space-between' : 'start'}} center">
                            <div class="menu__container-section-image" fxLayoutAlign="center center">
                                <img class="menuImg" src="{{tile.img}}">
                                <div class="menu__container-section-image-time" *ngIf="tile?.waitingTime"
                                    fxLayoutAlign="center center">
                                    <mat-icon [svgIcon]="'time_icon'"></mat-icon>
                                    {{tile.waitingTime}} mins.
                                </div>
                            </div>

                            <div class="menu__container-section-content width_65">
                                <div class="menu__container-section-title">
                                    {{tile.title}}
                                </div>
                                <div class="menu__container-section-description" [innerHTML]="tile.description">
                                </div>
                                <div class="menu__container-section-price">
                                    {{toNumber(tile?.price) | number:'1.2-2'}} {{globalService.currency}}
                                </div>
                            </div>
                            <div class="menu__container-section-cart" fxLayoutAlign="start center"
                                (click)="addItemToOrder(tile)">
                                <div class="menu__container-section-cart-price">
                                   <span [ngClass]="{'discountPrice': tile.discountedPrice}">{{toNumber(tile?.price) | number:'1.2-2' }}</span>  {{tile.discountedPrice?(toNumber(tile.discountedPrice) | number:'1.2-2'):''}} {{globalService.currency}}
                                </div>
                                <svg [ngStyle]="{'fill': order.status =='processing' ? '#228BE6' : '#ADB5BD'}" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 22 22" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                    <path id="cart-add" d="M12.413,9.881H14.6V6.738h3.274v-2.1H14.6V1.5H12.413V4.643H9.139v2.1h3.274M8.048,19.31a2.1,2.1,0,1,0,0,4.19,2.1,2.1,0,1,0,0-4.19m10.913,0a2.1,2.1,0,1,0,2.183,2.1,2.14,2.14,0,0,0-2.183-2.1M8.233,15.9l.033-.126.982-1.708h8.13a2.188,2.188,0,0,0,1.91-1.079L23.5,5.649,21.6,4.643H21.59l-1.2,2.1-3.012,5.238H9.717l-.142-.283L7.131,6.738l-1.037-2.1-1.026-2.1H1.5v2.1H3.683l3.929,7.951L6.138,15.161a1.963,1.963,0,0,0-.273,1.006,2.14,2.14,0,0,0,2.183,2.1h13.1v-2.1H8.506A.272.272,0,0,1,8.233,15.9Z" transform="translate(-1.5 -1.5)" ></path>
                                  </svg>
                                <!-- <mat-icon class="cartIcon" [svgIcon]="'cart-add'" [ngStyle]="{'color': order.status =='processing' ? '#228BE6' : '#ADB5BD'}" ></mat-icon> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--DESKTOP-->
        <div class="cart width_100" fxFlex="30" fxLayout="column" fxLayoutGap="20px"
            *ngIf="!globalService.isMobile$.getValue()">
            <div class="cart__container">
                <div class="cart__container-title width_100 extra_margin_top" fxLayoutAlign="start center">
                    CART
                </div>
                <div class="cart__container-content" fxLayout="column" fxLayoutAlign="center center"
                    *ngIf="!orderService.getOrderConfig().length">
                    <div class="cart__container-content-empty" fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon>shopping_cart</mat-icon>
                        <div>Cart is currently empty</div>
                    </div>
                </div>

                <div class="cart__container-content" *ngIf="itemsToOrder.length">
                    <div class="cart__container-content-orders">
                        <div class="cart__container-content-order" *ngFor="let item of itemsToOrder;let i = index">
                            <div class="cart__container-content-order-info" fxLayoutAlign="space-between center">
                                <div class="cart__container-content-order-title">
                                    {{item.amount}}x
                                    <span>
                                        {{item.order.title}}
                                    </span>
                                </div>
                                <div class="cart__container-content-order-price">
                                    {{toNumber(item?.order?.discountedPrice || item?.order?.price) | number:'1.2-2'}} {{globalService.currency}}
                                </div>
                            </div>
                            <div class="cart__container-content-order-additional" fxLayoutAlign="space-between center">
                                <div class="cart__container-content-order-note cursor_pointer" [ngStyle]="{'color': order.status =='processing' ? '#228BE6' : '#ADB5BD'}">
                                    Add note 
                                </div>
                                <div class="cart__container-content-order-amount" fxLayoutAlign="center center" >
                                    <mat-icon [ngStyle]="{'color': order.status =='processing' ? '#228BE6' : '#ADB5BD'}"  class="cart__container-content-order-remove cursor_pointer"
                                        (click)="reduceAmount(item)">
                                        remove_circle_outline
                                    </mat-icon>
                                    <span [ngStyle]="{'color': order.status =='processing' ? '#228BE6' : '#ADB5BD'}">{{item.amount}}</span>
                                    <mat-icon [ngStyle]="{'color': order.status =='processing' ? '#228BE6' : '#ADB5BD'}" class="cart__container-content-order-add cursor_pointer"
                                        (click)="increaseAmount(item)">
                                        add_circle_outline
                                    </mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cart__container-content-total">
                        <div class="cart__container-content-total-subtotal" fxLayoutAlign="space-between center">
                            <span>Subtotal</span>
                            {{subTotal | number:'1.2-2'}} {{globalService.currency}}
                        </div>
                        <div class="cart__container-content-total-discount" fxLayoutAlign="space-between center">
                            <span>Discount</span>
                            {{discount | number:'1.2-2'}} {{globalService.currency}}
                        </div>
                        <div class="cart__container-content-total-amount" fxLayoutAlign="space-between center">
                            <span>Total Amount</span>
                            {{totalAmount | number:'1.2-2'}} {{globalService.currency}}
                        </div>
                        <div class="cart__container-content-total-tax" fxLayoutAlign="flex-end center">
                            Including tax
                        </div>
                    </div>
                    <div class="cart__container-content-code" fxLayoutAlign="space-between center">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field class="width_100" appearance="outline" fxLayoutAlign="start center">
                                <input [formControl]="codeControl" matInput placeholder="Enter code">
                            </mat-form-field>
                        </div>
                        <div mat-ripple class="cart__container-content-code-confirm" fxLayoutAlign="center center"
                            (click)="applyCode()">
                            Apply Code
                        </div>
                    </div>
                </div>
            </div>
            <div mat-ripple class="cart__container-place"
                [class.cart__container-place-active]="order?.status =='processing' && orderService.getOrderConfig().length" (click)="confirmOrder()"
                fxLayoutAlign="center center">
                Place Order 
            </div>
        </div>
    </div>
    <!-- <ng-template #elseBlock>
        <div class="menu__container width_100" fxLayout="column">No items to display!</div>
    </ng-template> -->
</div>