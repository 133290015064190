<div id="create_request_dialog">
  <div class="position_relative" fxFlex="100" fxLayout="column" fxLayoutAlign="start center">
    <div class="width_100 dialog_header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
      <div class="header" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
        <div>{{data.title}}</div>
      </div>
    </div>
    <div class="width_100 dialog_body" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center">
      <div class="width_100" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="50" class="filter_section" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start start">
          <app-select class="filter_2 dropdownCustom" placeholder="Status" [label]="'Responsible Department'" [noEmptyValue]="true"
            [value]="requestObj.requestType" (selectionChange)="requestObj.requestType = $event"
            [selectOptions]="[{label: 'Housekeeping', value: 1}, {label: 'Cleaning', value: 2}]"></app-select>
        </div>
        <div fxFlex="50" class="filter_section dropdownCustom" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start start">
          <app-select class="filter_2" placeholder="Status" [label]="'Responsible Person'" [noEmptyValue]="true"
            [value]="personName" (selectionChange)="personName= $event"
            [selectOptions]="[{label: 'Lopez, Amanda', value: 'Lopez, Amanda'}]"></app-select>
        </div>
      </div>
      <div *ngIf="requestObj.requestType == '1'" class="width_100" fxLayoutGap="20px" fxLayout="row"
        fxLayoutAlign="start center">
        <div fxFlex="50" class="filter_section" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start start">
          <mat-label>Guest's Booking ID</mat-label>
          <mat-form-field class="filter_3 width_100 pointer_none" appearance="outline" fxLayoutAlign="start center">
            <input matInput placeholder="Search message" [(ngModel)]="data.bookingId">
          </mat-form-field>
        </div>
        <div fxFlex="50" class="filter_section" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start start">
          <mat-label>Guest's Room No.</mat-label>
          <mat-form-field class="filter_3 width_100" appearance="outline" fxLayoutAlign="start center">
            <input matInput placeholder="" [(ngModel)]="data.roomNumber">
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="requestObj.requestType == '1'" class="width_100" fxLayoutGap="20px" fxLayout="row"
        fxLayoutAlign="start center">
        <div fxFlex="50" class="filter_section dropdownCustom" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start start">
          <app-select class="filter_2" placeholder="Request Category" [label]="'Request Category'" [noEmptyValue]="true"
            [value]="requestObj.requestCategory" (selectionChange)="categoryChange($event)"
            [selectOptions]="[{label: 'Bath Amenities', value: 1},{label: 'Bed Amenities', value: 2}]"></app-select>
        </div>
        <div fxFlex="24" class="filter_section dropdownCustom" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start start">
          <app-select class="filter_2" placeholder="Request Type" [label]="'Request Type'" [noEmptyValue]="true"
            [value]="requestObj.requestAmenity" (selectionChange)="requestObj.requestAmenity = $event" [class.disable]="this.categoryAmenityList.length==0"
            [selectOptions]="this.categoryAmenityList"></app-select>
        </div>
        <div fxFlex="24" class="filter_section" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start start">
          <mat-label>Request Quantity</mat-label>
          <mat-form-field class="filter_3 width_100" appearance="outline" fxLayoutAlign="start center">
            <input matInput placeholder="Request Quantity" [(ngModel)]="requestObj.requestQuantity">
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="requestObj.requestType == '1'" class="width_100" fxLayoutGap="20px" fxLayout="row"
        fxLayoutAlign="start center">
        <div fxFlex="100" class="filter_section" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start start">
          <mat-label>Note</mat-label>
          <textarea matInput class="filter_3 width_100 custom_text_area" [value]="requestObj.addedNote"
            (input)="requestObj.addedNote = $event.target.value"></textarea>
        </div>
      </div>
      <div *ngIf="requestObj.requestType == '1'" class="width_100" fxLayoutGap="20px" fxLayout="row"
        fxLayoutAlign="start center">
        <div fxFlex="50" class="filter_section datepicker" fxLayout="column" fxLayoutGap="5px"
          fxLayoutAlign="start start">
          <mat-label>Start Date</mat-label>
          <mat-form-field class="filter_3 width_100" appearance="outline" fxLayoutAlign="start center">
            <mat-datepicker-toggle matPrefix [for]="checkInPicker"></mat-datepicker-toggle>
            <input [(ngModel)]="requestObj.startDate" matInput [class.selected]="checkInState"
              [matDatepicker]="checkInPicker" disabled placeholder="Select Start Date">
            <mat-datepicker #checkInPicker disabled="false" (closed)="checkInState = true"></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="filter_section datepicker" fxLayout="column" fxLayoutGap="5px"
          fxLayoutAlign="start start">
          <mat-label>Due Date</mat-label>
          <mat-form-field class="filter_3 width_100" appearance="outline" fxLayoutAlign="start center">
            <mat-datepicker-toggle matPrefix [for]="checkOutPicker"></mat-datepicker-toggle>
            <input [(ngModel)]="requestObj.endDate" [class.selected]="checkOutState" matInput
              [matDatepicker]="checkOutPicker" disabled placeholder="Select Due Date">
            <mat-datepicker #checkOutPicker disabled="false" (closed)="checkOutState = true"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="requestObj.requestType == '2'" class="width_100" fxLayoutGap="20px" fxLayout="row"
        fxLayoutAlign="start center">
        <div fxFlex="50" class="filter_section datepicker" fxLayout="column" fxLayoutGap="5px"
          fxLayoutAlign="start start">
          <mat-label>Cleaning Date</mat-label>
          <mat-form-field class="filter_3 width_100" appearance="outline" fxLayoutAlign="start center">
            <mat-datepicker-toggle matPrefix [for]="cleaningPicker"></mat-datepicker-toggle>
            <input [(ngModel)]="requestObj.startDate" [class.selected]="cleaningState" matInput
              [matDatepicker]="cleaningPicker" disabled placeholder="Select Date">
            <mat-datepicker #cleaningPicker disabled="false" (closed)="cleaningState = true"></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="filter_section dropdownCustom" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start start">
          <app-select class="filter_2" placeholder="Status" [label]="'Cleaning Time'" [noEmptyValue]="true"
            [value]="requestObj.cleaningTime" (selectionChange)="requestObj.cleaningTime = $event"
            [selectOptions]="houseKeepingTime"></app-select>
        </div>
      </div>
    </div>
    <div style="flex-grow: 2" class="width_100" fxLayoutAlign="start end">
      <div class="width_100 dialog_footer" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="end center">
        <div [mat-dialog-close]="" fxLayout="row" class="request_status_new" fxLayoutAlign="center center">
          <div>Cancel</div>
        </div>
        <div [mat-dialog-close]="requestObj" fxLayout="row" class="request_status_new active_button"
          fxLayoutAlign="center center">
          <div>Create</div>
        </div>
      </div>
    </div>
  </div>
</div>