<div id="view_request_dialog">
    <div class="position_relative" fxFlex="100" fxLayout="column" fxLayoutAlign="start center">
        <div class="width_100 dialog_header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <div class="header" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
                <div>NEW CLEANING REQUEST!</div>
                <div class="header_room">Room No. {{roomNumber}}</div>
            </div>
            <div class="filter_section dropdownCustom" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                <app-select class="filter_3" label="Status" [noEmptyValue]="true" [value]="requestStatus"
                    (selectionChange)="requestStatus = $event" [selectOptions]="selectOptions"></app-select>
            </div>
        </div>
        <div class="width_100 dialog_body" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center">
            <div class="width_100 dialog_section" fxLayout="column" fxLayoutAlign="start center">
                <div class="width_100 section_header" style="margin-bottom: 20px;" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="width_80" fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-icon [svgIcon]="'housekeeping'"></mat-icon>
                        <div class="section_header_label">Request Type</div>
                    </div>
                    <div class="section_quantity margin_right_15_px">Date & Time</div>
                </div>
                <div class="width_100 margin_bottom_20" fxLayoutGap="20px" fxLayout="column"
                    fxLayoutAlign="start center">
                    <div class="width_100 section_body" fxLayoutGap="20px" fxLayout="row"
                        fxLayoutAlign="space-between center">
                        <div class="section_body_label">Clean My Room</div>
                        <div class="section_body_quantity margin_right_15_px">
                            {{cleaningDateTime}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="width_100 dialog_footer" fxLayout="row" fxLayoutAlign="center center">
            <div [mat-dialog-close]="requestStatus" fxLayout="row" class="request_status_new"
                fxLayoutAlign="center center">
                <div>Close</div>
            </div>
        </div>
    </div>
</div>