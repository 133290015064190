import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalServices } from '../../shared/services/global.services';
import { RestfullServices } from 'src/app/shared/services/restfull.services';
import * as introJs from 'intro.js';

@Component({
    selector: 'app-food-beverages',
    templateUrl: './food-beverages.component.html',
    styleUrls: ['./food-beverages.component.scss']
})
export class FoodBeveragesComponent implements OnInit, AfterViewInit {
    introJS = introJs.default();
    restaurantId: number;
    public departmentTiles = [
        // {
        //     id: 0,
        //     label: 'Bar',
        //     route: 'bar',
        //     active: false,
        //     img: 'assets/images/png/bar-department.png'
        // },
        // {
        //     id:'0',
        //     label: 'Snack Bar',
        //     route: 'snack',
        //     active: true,
        //     img: 'assets/images/png/snack-department.png'
        // },
        // {
        //     id: '0',
        //     label: 'Room Service',
        //     route: 'service',
        //     active: false,
        //     img: 'assets/images/png/service-department.png'
        // }
    ];

    private imgDesktopArr = [
        'assets/images/png/bar-department-2x.png',
        'assets/images/png/snack-department-2x.png',
        'assets/images/png/service-department-2x.png'
    ];

    constructor(private globalService: GlobalServices, private restfullServices: RestfullServices, private cdr: ChangeDetectorRef,
        private route: Router) {
        if (!this.globalService.isMobile$.getValue()) {
            this.departmentTiles = this.departmentTiles.map((tile, index) => ({
                ...tile,
                img: this.imgDesktopArr[index]
            }));
        }
    }



    ngOnInit(): void {
        this.globalService.headerData$.next({
            headerTitle: 'Food & Beverages',
            showBackButton: false
        });

        if (this.globalService.isReceptionRole) {
            this.departmentTiles[1].active = false;
            this.departmentTiles[2].active = true;
        }
        this.getFBRestaurants();

    }

    getFBRestaurants() {
        this.globalService.showLoader$.next(true);
        this.restfullServices.getFBRestaurants().subscribe(response => {
            console.log(response);
            if (response) {
                response.restaurantList.forEach(element => {
                    this.departmentTiles.push({ id: element.id, label: element.name, route: 'snack', active: true, img: element.bannerUrl })
                });
                this.globalService.showLoader$.next(false);
            }
        });
    }
    startTour() {
        this.introJS.setOptions({
            steps: [
                {
                    //step 11
                    tooltipClass: "intro-step-11",
                    title: "FOOD & BEVERAGES",
                    element: document.getElementById('step-5_Food & Beverages'),
                    intro: "You can view all orders and requests made by guests through the guest app, or manually place new orders for your customers, and accept payments.",
                    position: 'right'
                },
                {
                    //step 12
                    tooltipClass: "intro-step-12",
                    title: "ACCESS YOUR DEPARTMENT",
                    element: document.getElementById('open_snack'),
                    intro: "Click on your respective department to access your workspace. Here, you can efficiently manage orders and reservations tailored to your department’s needs.",
                    position: 'bottom'
                }
            ],
            disableInteraction: true,
            showBullets: false,
            showButtons: true,
            exitOnOverlayClick: false,
            keyboardNavigation: true,
            scrollToElement: true,
            scrollTo: 'tooltip',
        });
        this.introJS.start();
        this.introJS.onbeforechange((targetElement) => {
            this.introJS.refresh();
        });

        setTimeout(() => {
            if (document.querySelector(".intro-skip")) {
                let skipButton = document.querySelector(".intro-skip");
                skipButton.addEventListener('click', this.skipTour.bind(this));
            }

            if (document.querySelector(".introjs-skipbutton")) {
                let closeButton = document.querySelector(".introjs-skipbutton");
                closeButton.addEventListener('click', this.closeStep.bind(this));
            }
        }, 100);
    }

    skipTour(): void {
        this.introJS.exit();
        this.restfullServices.removeLocalStorage('isTourRunning');
        window.location.href = '#/pages/food-beverages';
    }

    closeStep(): void {
        window.location.href = '#/pages/food-beverages';
    }

    public navigateToOrders(id: number, mode: string) {
        this.route.navigate(['/pages/food-beverages/orders'], {
            queryParams: { mode: mode, id: id }
        });
        String(this.restfullServices.removeLocalStorage('tabindex'));
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (!this.globalService.isMobile$.getValue()) {
                const isTourRunning = this.restfullServices.getLocalStorage('isTourRunning');
                const isFirstLogin = this.restfullServices.getLocalStorage('IsFirstLogin');
                const guidedTourFBFlag = this.restfullServices.getLocalStorage('guidedTourF&BFlag');
                if (isTourRunning == 'true' && isFirstLogin == 'false' && guidedTourFBFlag == "false") {
                    this.restfullServices.setLocalStorage('guidedTourF&BFlag', 'true');
                    this.startTour();
                    this.cdr.detectChanges();
                }
            }
        }, 500);
    }
}
